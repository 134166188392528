.config_logo img, .config_logo .Polaris-Thumbnail--sizeLarge{
  width: 11rem;
}

.config_logo .Sourcing__Loading{
  position: initial;
  opacity: 0.9;
}

.config_logo .Sourcing_Request__Loading_Image{
  left: 40%;
  top: 40%;
}

.Config_Logo__Image {
  position: absolute;
  right: 10%;
  top: 10%;
}

.Config_Logo__Image .Polaris-Button{
  min-width: 2.5rem;
  min-height: 2.5rem;
  padding: 0.7rem;
}

.Config_Logo__Image .Polaris-Icon{
  height: 1.5rem;
  width: 1.5rem;
}

.Config_Logo__Image .image_remove__button{
  width: unset;
  height: unset;
}

.out_checkbox {
  padding-top: 15px;
}


.imagePreview img {
  visibility: visible !important;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

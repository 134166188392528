.referral_box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.referral_box .referral_box_item {
    width: calc(100% / 3 - 20px);
    margin-right: 30px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 16px 16px 16px;
    border: 1px solid #f6f6f7;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    background: #fff;
}
.referral_box_item:nth-child(3n) {
    margin-right: 0;
}
.referral_box .referral_box_item strong {
    margin-top: 10px;
    font-size: 20px;
}
.referral_table .Polaris-IndexTable__TableHeading:nth-child(1),.referral_table .Polaris-IndexTable__TableHeading:nth-child(2)  {
    text-align: left !important;
}
.referral_table .Polaris-IndexTable__TableHeading {
    font-size: 14px;
}
@media screen and (max-width: 499px) {
    .referral_box_item {
        width: 200px !important;
        margin-right: 10px !important;
        margin-bottom: 10px;
    }
    .referral_box_content {
        overflow-x: auto;
        overflow-y: hidden;
    }
    .referral_box {
        width: 600px;
        flex-wrap: nowrap;
    }
}
.StackMarginTop .Polaris-FormLayout__Item{
    margin-top: 0.6rem;
}  
.Polaris-Popover__Content{
    /* max-width: 100%; */
    max-height: 100%;
    /* width:120px */
}
.product-tooltip{
    background-color: rgba(0, 0, 0,.7);
    border-radius: 5px;
    padding: 5px;
    color: #fff;
    max-width: 300px;
}
.product_tip_day{
    display: block;
}
.product_tip_num{
    display: block;
}
.product_tip_num > span{
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: 5px;
    background-color: #23c2db;
    border-radius: 50%;
}
.product_chart .recharts-default-legend .recharts-legend-item {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.details_right .recharts-legend-item{
    display: inline-flex !important;
    align-items: center;
}
.layout_reports{
    display: flex;
    flex-wrap: wrap;
}
._reports{
    width: calc(50% - 30px);
    background-color: #fff;
    padding: 40px 40px 40px 0;
    margin-bottom: 30px;
    margin-top: 30px;
    max-height: 600px;
    position: relative;
    border-radius: 10px;
}
._view{
    position: absolute;
    right: 30px;
    top: 30px;
    color: #3B86DF;
    cursor: pointer;
}
._reports:nth-child(2n-2){
    margin-left: 60px;
}
._reports > .title , ._reports_details .title {
    font-weight: 700;
    font-size: 20px;
    padding-left: 20px;
}
._reports .total{
    position: relative;
    font-weight: 700;
    font-size: 28px;
    padding-left: 80px;
    margin: 30px 0;
}
.product_chart   .recharts-legend-wrapper{
    bottom: -10px !important;
}
._reports_details{
    display: flex;
    width: 100%;
    align-items: center;
    padding: 30px 20px;
}
/* ._report_content .Polaris-Page{
    padding: 0px 96px !important;
} */

.details_left{
    width: 75%;
    position: relative;
}

._reports_details .details_right{
    width: 20%;
}

.details_left .product_chart{
    border: 1px solid #DFD6D6;
    padding: 30px 10px 30px 0;
}
.details_left  .product_chart .recharts-default-legend .recharts-legend-item{
    text-align: left;
    line-height: 30px;
    justify-content: flex-start;
}
.details_left  .recharts-legend-wrapper{
    top: 0;
    left: 40px !important;
    height: 30px !important;
}
.details_right .recharts-wrapper{
    border: 1px solid #DFD6D6;
}
._reports_details .title {
    margin-bottom: 20px;
 }
.layout_reports_details .Polaris-Layout__Section .Polaris-DataTable__Cell--numeric{
    text-align: center !important;
    
 }
.layout_reports_details .Polaris-Layout__Section{
     max-width: 100% !important;
     margin-left: 0 !important;
 }
 ._reports_details_sellings .Polaris-Layout__Section .Polaris-DataTable__Cell--numeric{
    text-align: center !important;
 }
 ._reports_details_sellings .selling_title{
    text-align: center;
    display: flex;
    align-items: center;
 }
 ._reports_details_sellings thead .Polaris-DataTable__Cell {
     width: 25%;
     font-weight: 600;
 }
 ._reports_details_sellings .Polaris-DataTable__Cell--firstColumn{
    width: 15% !important;
    padding-left: 50px;
 }
 ._reports_details_sellings .Polaris-Layout__Section .Polaris-DataTable__Cell--numeric div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
 }
 ._reports_details_sellings  .Polaris-Layout__Section{
    max-width: 100%;
    margin-left: 0 !important;
 }
 ._reports_details_sellings .selling_index{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100px;
 }
 ._reports_details_sellings .selling_index span{
    width: 30px;
    height: 30px;
    background: #303030;
    text-align: center;
    line-height: 30px;
    color: #fff;
    border-radius: 50%;
 }
 ._reports_details_sellings .selling_title{
     justify-content: flex-start !important;
 }
 
 ._reports_details_sellings .selling_title img{
     width: 65px;
 }
 ._reports_details_sellings .selling_title  p{
    overflow: hidden;				
	text-overflow: ellipsis;		
	display: -webkit-box;			
	-webkit-line-clamp: 2;	
    text-align: left;
    margin-left: 20px;
    white-space: break-spaces;		
 }

 ._not .Polaris-EmptyState__Image{
    width: 100%;
    transform: scale(0.5);
 }
._reports .total .row_line{
    position: absolute;
    right: 185px;
    top: 15px;
    width: 20px;
    height: 2px;
    background: #8D8989;
 }
 ._reports .total .row_cost{
    position: absolute;
    right: 185px;
    top: 0px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
 }

 @media screen and (max-width:600px) {
    /* ._report_content  .Polaris-Page{
        padding: 0 15px !important;
     } */
     .layout_reports{
        flex-direction: column;
        overflow: hidden;
     }
     ._reports{
        width: 100% !important;
        /* overflow-y: hidden; */
        overflow-x: auto;
        margin-left: 0 !important;
     }
     ._view{
        right: -90% !important;
        top: 40px;
     }
     ._reports .total .row_cost{
         right: -185px;
     }
     
 }
 @media screen and (min-width:600px) and (max-width:1200px) {
    /* ._report_content  .Polaris-Page{
       padding: 0 15px !important;
    } */
    .layout_reports{
        flex-direction: column;
        overflow: hidden;
    }
    ._reports{
       width: 99% !important;
       /* overflow-y: hidden; */
       overflow-x: auto;
       margin-left: 0 !important;
    }
    ._view{
       right: 30px !important;
       top: 40px;
    }
}
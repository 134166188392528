.subscription_page .Polaris-Page{
  max-width: 119.8rem;
}
.subscription_page .Polaris-Layout__Section {
  margin: 0;
}
.subscription_page .Polaris-Layout {
  margin: 0;
}
.subscription_page .Polaris-Card {
  /* border-radius: 0; */
  box-shadow: 0rem 0.125rem 0.25rem rgba(31,33,36,.1), 0rem 0.0625rem 0.375rem rgba(31,33,36,.05);
}
.subscript_section_content {
  display: flex;
  /* align-items: baseline; */
  flex-direction: row-reverse;
  margin-top: 28px;
  /* margin-top: 48px; */
  /* box-shadow: 0px 1px 2px 0px #00000026; */
  border-radius: 10px;
  box-shadow: 0rem 0.125rem 0.25rem rgba(31,33,36,.1),0rem 0.0625rem 0.375rem rgba(31,33,36,.05);
}
.subscript_section {
  width: calc(100% / 4);
  background: #fff;
  padding: 32px 0 0 0;
  /* border-radius: 10px; */
  /* box-shadow: 0 0 1px #00000040; */
  border-right: 1px solid #b7b7b740;
  position: relative;
  min-height: 637px;
}
/* .subscript_section_contents {
  margin-top: 72px;
} */
.subscript_section_none .sub_top_time {
  display: none;
}
.subscript_sections {
  padding-top: 8px;
}
.subscript_sections .subscript_section_button button:hover {
  background-color: #303030;
  color: #fff;
}
.subscript_sections .shou_all_option {
  color: #303030;
}
.subscript_sections .subscript_popular_type {
  display: none;
}
.subscript_sections .sub_top_time {
  top: -52px;
}
.subscript_section:hover {
  /* box-shadow: 0 0 0 2px #006e52; */
}
.subscript_popular {
  position: absolute;
  left: 20px;
  top: -40px;
  transform: translate(0, 25px);
  height: 24px;
  min-width: 168px;
  padding: 0 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  background-color: #f5520c;
  color: #fff;
  font-size: 14px;
  border-radius: 15px;
}
.subscript_section:nth-last-of-type(1){
  /* border: 0; */
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.subscript_section:nth-of-type(1){
  /* border: 0; */
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-right: 0;
}
.roate svg{
  fill: #303030;
  transform: rotate(180deg);
}
.subscript_section_contents .roate svg{
  fill: #303030;
}
.sub_top_time {
  /* position: absolute;
  left: 0;
  top: -90px; */
  width: 100%;
  background-color: #FFEECD;
  border-radius: 8px;
  margin-bottom: 16px;
  /* border-top-left-radius: 8px; */
}
.subscript_section:nth-of-type(1){
  margin-right: 0;
  /* max-height: 488px; */
}
.subscript_section_item {
  position: relative;
  padding: 0 12px;
}
.subscript_section_item h3 {
  font-weight: 700;
  font-size: 22px;
  /* text-align: center; */
  margin-bottom: 16px;
}
.subscript_section_item h3 img {
  vertical-align: sub;
  height: 27px;
}

.subscript_section_dsc {
  font-size: 14px;
  height: 40px;
  margin: 16px 0 32px;
  text-align: left;
  color: #6D7175;
}
.subscript_section_price {
  /* text-align: center; */
  display: flex;
  align-items: baseline;
  /* justify-content: center; */
  height: 40px;
  padding-bottom: 8px;
 
}
.subscript_section_price_it_img svg {
  width: 16px;
}
.subscript_section_price div {
  display: inline-flex;
  flex-direction: column;
  font-size: 14px;
  /* margin-left: 12px; */
}
.throw_line .subscript_section_discount_price {
  margin-right: 8px;
} 
.subscript_section_discount_price {
  font-weight: 700;
  color: #f5520c;
  /* position: absolute;
  top: -20px;
  left: 0; */
  font-size: 12px;
}
.subscript_section_discount_price_level {
  color: #882E07 !important;
  margin-right: 8px;
}
.subscript_section_discount_price_level svg ,.subscript_section_discount_price_level svg path{
  fill: #882E07 !important;
}
.subscript_section_date {
  /* margin-bottom: 12px;
  margin-top: 16px; */
  margin: 0;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
}
.subscript_section_new_price {
  font-size: 32px;
  font-weight: 700;
  /* margin: 0 8px; */
  /* flex: 1; */
}
.subscript_section_old_price {
  font-size: 14px;
  text-decoration: line-through;
  color: rgba(109, 113, 117, 1);
  text-align: left;
  position: relative;
  margin-left: 8px;
}
/* .subscript_section_old_price::after {
  content: '';
  width: 90%;
  height: 2px;
  position: absolute;
  left: 4px;
  top: 12px;
  background-color: #000;
  transform: rotate(343deg);
} */
.throw_line {
  display: flex;
  position: relative;
  /* margin-left: 12px; */
}
.throw_line::after {
  /* content: '';
  width: 20px;
  height: 2px;
  position: absolute;
  left: -14px;
  top: 7px;
  background-color: #000;
  transform: rotate(295deg); */
}
.subscript_section_button {
  /* margin-top: 24px; */
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
}

.subscript_section_button button{
  width: 100%;
  height: 32px;
  /* background-color: #e5e5e5; */
  /* color: #202333; */
  box-shadow: 0rem 0.125rem 0.25rem rgba(31,33,36,.1), 0rem 0.0625rem 0.375rem rgba(31,33,36,.05);
}
.subscript_section_buttontwos {
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
}
.subscript_section_buttons button{
  /* background: #303030; */
  /* box-shadow: none; */
  /* color: #fff; */
}
.shou_all_option_black {
  color: #303030 !important;
}
.shou_all_option_black .roate svg {
  fill: #303030;
}
.subscript_section_buttontwos button{
  width: 100%;
  /* height: 45px; */
  background-color: #fff;
  color: #202333;
  box-shadow: 0rem 0.125rem 0.25rem rgba(31,33,36,.1), 0rem 0.0625rem 0.375rem rgba(31,33,36,.05);
}
.subscript_section_buttontwo button{
  background: #303030;
  /* box-shadow: none; */
  color: #fff;
}
.subscript_section_buttontwo button:hover , .subscript_section_buttontwos button:hover{
  background: #303030;
  /* box-shadow: none; */
  color: #fff;
}
.subscript_section_button button span{
  /* font-size: 16px; */
}
.subscript_section ul {
  padding-left: 0;
  text-align: center;
  max-height: 288px;
  overflow: hidden;
  /* will-change: max-height;
  transition-property: max-height;
  transition-duration: 100ms; */
  transition: max-height 0.5s ease-in-out;
}

.subscript_section ul li {
  display: flex;
  align-items: center;
  line-height: 32px;
}
.subscript_section ul li:hover .subscript_section_price_it_ps{
  text-decoration: underline;
  cursor: pointer;
}

.subscript_section_price_it_p {
  margin: 0 0 0 5px;
  font-size: 12px;
  line-height: 1.3;
  text-align: left;
}
.subscript_section_it_tip {
  cursor: pointer;
  position: relative;
  width: 14px;
  height: 14px;
  display: inline-block;
  margin-left: 4px;
}

.subscript_section_it_tip img {
  width: 100%;
  height: 100%;
}
.subscript_section_it_tip:hover .subscript_section_it_tip_txt{
  display: block;
}
.subscript_section_it_tip_txt {
  display: none;
  padding: 12px 16px;
  position: absolute;
  left: -200px;
  top: 30px;
  z-index: 90;
  text-align: left;
  width: 200px;
  margin: 0 auto;
  max-height: 300px;
  border-radius: 5px;
  line-height: 1.2;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 60%);
  word-break:break-word;
}
.subscript_faq {
  padding-bottom: 24px;
}
.subscript_faq h3 {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 32px;
}
.subscript_faq_content {
  display: flex;
}
.subscript_faq_list {
  width: calc(100% / 2 - 12px);
  margin-right: 24px;
}
.subscript_faq_list:nth-child(2){
  margin-right: 0;
}
.subscript_faq_list .Polaris-Box{
  background-color: #f6f6f7;
}
.subscript_faq_item_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.subscript_faq_item_title p {
  font-weight: 500;
  font-size: 15px;
}
.subscript_faq_item_title img {
  width: 22px;
  cursor: pointer;
}
.subscript_faq_item_desc {
  padding: 8px 0;
  font-size: 15px;
}
.subscript_faq .Polaris-Card + .Polaris-Card {
  margin-top: 2.4rem;
}
.subscribe_tab {
 background: #0FB28A;
  border-radius: 5px;
  margin: 16px 22px 16px 20px;
  padding: 7px 6px;
}
.subscribe_tab img {
  /* vertical-align: bottom; */
  height: 24px;
  /* margin-bottom: 5px; */
}
.subscribe_tab span {
  color: #fff;
  font-weight: 700;
  margin: 0 3px;
}
.subscribe_tab a {
  /* color: #FD613E; */
  border-bottom: 0.5px solid transparent;
}
.plan_upgrade_head {
  /* display: flex; */
  text-align: center;
  font-size: 28px;
  line-height: 1.2;
}
.plan_upgrade_head p {
  font-weight: 700;
  /* margin-left: 6px; */
  margin-top: 10px;
}
.plan_upgrade_head strong {
  color: #303030;
}
.plan_upgrade_head p {
  font-size: 28px;
}
.plan_upgrade_head_type strong {
  font-size: 36px;
}
.plan_grade_content {
  padding: 20px 50px;
}
.plan_upgrade_conetent {
  display: flex;
}
.plan_upgrade_conetent ul li{
  list-style: unset;
  font-size: 15px;
  height: 30px;
}
.plan_upgrade_conetent_button {
  text-align: center;
  margin: 36px 0;
}
.plan_upgrade_conetent_button button {
  background-color: #303030;
  color: #fff;
  width: 285px;
  height: 50px;
  padding: 8px 40px;
  border-color: transparent;
}
.plan_upgrade_conetent_button button:hover {
  background-color: #303030;
}
.subscript_section_button_type button:hover {
  background-color: #303030;
  color: #fff;
}
.plan_upgrade_conetent_des {
  text-align:center;
  color: #303030;
  margin-top: 16px;
  font-size: 16px;
}
.shopify_products_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.seo_ant_top_btn {
  display: flex;
  justify-content: flex-end;
}
.seo_ant_top_btn button , .seo_ant_top_btn a{
  display: flex;
  /* width: 30%; */
  border-color: transparent;
}
.seo_ant_top_btn .btnContent {
  display: flex;
  align-items: center;
}

.seo_ant_top_btn .prefixIcon {
  display: flex;
  align-items: center;
}
.seo_ant_top_btn .itemText {
  font-weight: 500;
  font-size: 15px;
  flex: 1;
  padding: 0 16px;
}
.seo_ant_top_btn .Polaris-Button__Text {
  width: 100%;
}
.sub_paid_time_detail_price {
  text-align: center;
  font-size: 28px;
  text-decoration: line-through;
  color: #BABEC7;
  margin-bottom: 30px;
}
.shou_all_option {
  text-align: center;
  font-size: 16px;
  color: #303030;
  margin-bottom: 16px;
  cursor: pointer;
  padding-bottom: 16px;
}
.line_shou {
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 16px;
}
.shou_all_options {
  height: 30px;
}
.shou_all_options .additional_gift{
  color: rgba(35, 125, 245, 1);
}
.shou_all_option_line .additional_gift{
  border-bottom: 0;
}
.shou_all_option_line .additional_gift{
  color: #8C9196;
  text-decoration: line-through;
}
.additional_gift {
  color: #f5520c;
  border-bottom: 1px solid;
  margin-left: 5px;
}
.subscript_section_card_free .subscript_section {
  margin-top: 0;
  padding-top: 0;
}

.premium_item {
  display: flex;
  align-items: flex-end;
  padding:  16px;
}
.premium_item h3 {
  font-weight: 700;
  font-size: 22px;
}
.premium_item_name {
  margin-right: 36px;
}
.premium_item_name h3 img {
  vertical-align: sub;
  width: 27px;
}
.premium_item_name .subscript_section_dsc {
  margin-bottom: 0;
  margin-top: 0;
  height: auto;
}
.premium_item .subscript_section_button {
  min-width: 200px;
  margin-bottom: 0;
  margin-left: 24px;
}
.premium_item .subscript_section_price {
  padding-bottom: 0;
  height: auto;
}
.premium_item_box {
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  margin: 16px;
  padding: 16px;
}
.premium_item_box .subscript_section_item_left {
  margin: 0 !important;
  width: 35% !important;
}
.agent_item_service {
  font-size: 16px;
  font-weight: 500;
}
.agent_item {
  display: flex;
}
.agent_left {
  width: 96px;
  height: 96px;
  border-radius: 8px;
  line-height: 1;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 60%);
}
.agent_right {
  margin-left: 10px;
  flex: 1;
  text-align: left;
}
.agent_left img {
  max-width: 100%;
  height: 100%;
  border-radius: 8px;
}
.agent_right_rating {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
/* .agent_right_rating > div {
  min-width: 107px;
} */
.agent_right_rating span {
  margin-left: 5px;
  font-size: 14px;
}
.agent_item_service {
}
.agent_right_name {
  font-weight: 700;
  font-size: 18px;
}
.agent_right_year {
  margin: 5px 0;
}
.agent_right_year span {
  font-size: 14px;
}
.agent_right_tag span {
  background-color: #F1F1F1;
  border-radius: 10px;
  padding: 2px 5px;
  margin-right: 4px;
  font-size: 10px;
}
.agent_list .swiper-pagination-bullet {
  width: 20px !important;
  height: 3px !important;
  border-radius: 0 !important;
}
.agent_list .swiper-pagination-bullet-active {
  width: 50px !important;
  background-color: #303030;
}
.agent_list .swiper {
  height: 200px;
}
@media screen and (max-width:520px) {
  .agent_content_list {
    flex-direction: column;
  }
  .agent_modal .agent_content_item {
    width: 100% !important;
    margin-right: 0;
  }
  .agent_content_person {
    min-width: 45px;
  }
  .agent_modal .common_modal_box{
    margin: 0  !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .agent_content_input_box {
    flex-direction: column;
    align-items: flex-start;
  }
  .agent_modal .common_modal_content {
    max-height: 320px !important;
  }
  .agent_content_input input {
    height: 32px !important;
    border-radius: 8px !important;
    width: 100% !important;
  }
  .agent_content_input div {
    width: 100%;
  }
  .agent_modal .common_modal_mask {
    align-items: flex-end;
  }
  .agent_content_input {
    width: 100% !important;
    flex-direction: column;
    margin-bottom: 16px;
    align-items: flex-start !important;
  }
  .agent_modal .common_modal_button_box {
    flex-direction: column;
  }
  .agent_modal .common_modal_button_box button:nth-of-type(1) {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .agent_modal .common_modal_button {
    height: 100px;
  }
  .agent_modal .common_modal_button .common_modal_button_box {
    height: 100%;
  }
  /* .throw_line .subscript_section_date span{
    zoom: 0.7 !important;
  }
  .subscript_section_discount_price {
    zoom: 0.8 !important;
  } */
}
@media screen and (min-width:768px) {
  /* .subscript_sections:nth-last-of-type(1){
    border-top-left-radius: 0;
  } */
  
  .subscript_section_card_free .subscript_section_content {
    flex-direction: row;
    margin-top: 0;
  }
 
  .subscript_section_card_free .subscript_section{
    width: 100%;
    min-height: auto;
  }
  .subscript_section ul li img {
    vertical-align: middle;
  }
  .subscript_section ul li svg {
    vertical-align: sub;
  }
}
@media screen  and (max-width:1024px) {
  .agent_modal .common_modal_content {
    max-height: 420px;
  }
  .premium_item_box .subscript_section_item_left {
    width: 100% !important;
  }
  .subscript_section_card_free .subscript_section_item {
    flex-direction: column;
  }
}
@media screen and (min-width:960px) {
  .subscript_section_card_free .subscript_section_item {
    display: flex;
    align-items: center;
  }
  .subscript_section_card_free .subscript_section_item_left {
    margin: 0 24px;
    width: 10%;
  }
  .subscript_section_card_free .subscript_section_item_right {
    flex: 1;
  }
  .subscript_section_card_free .subscript_section_item_right ul{
    display: flex;
    margin-left: 24px;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .subscript_section_card_free .subscript_section_item_right ul li {
    width: calc(100% / 2);
  }
}
@media screen and (min-width:1025px) and (max-width:1365px) {
  .select_language .language {
    white-space: initial;
  }
  .subscript_section_content {
    flex-wrap: wrap;
    /* flex-direction: column-reverse; */
    /* align-items: baseline; */
    /* margin-top: 0; */
    box-shadow: none;
  }
  .subscript_section {
    width: calc(100% / 4);
    margin-right: 0;
    margin-bottom: 24px;
  }
  .subscript_sections:nth-child(3) {
    /* margin-bottom: 72px; */
    /* margin-top: 60px; */
  }
  .subscript_section:nth-last-of-type(1) {
    /* margin-top: 36px; */
    border-right: 1px solid #e7e7e7;
  }
  .subscript_section_item {
    padding: 0 10px;
  }
  .subscript_section ul li svg {
    width: 16px;
  }
  .subscript_section_price_it_p {
    zoom: 0.7;
    font-size: 14px;
  }
  .subscription_page .Polaris-Card {
    box-shadow: none;
  }
  .subscript_section .subscript_section_new_price {
    font-size: 26px;
  }
  .subscript_section .subscript_section_old_price {
    font-size: 14px;
    margin-left: 5px;
  }
  .subscript_section .subscript_section_date {
    font-size: 12px;
  }
  .throw_line .subscript_section_date span{
    zoom: 0.9 !important;
  }
  .subscript_section .subscript_section_dsc {
    font-size: 12px;
    zoom: 0.8;
  }
  .subscript_section_price div .subscript_section_discount_price {
    font-size: 12px;
  }
  .shou_all_option {
    font-size: 14px;
  }
  .shou_all_option img {
    width: 19px;
  }
}
@media screen and (min-width:768px) and (max-width:1024px) {
  .select_language .language {
    white-space: initial;
  }
  .subscript_section_content {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 36px;
    box-shadow: none;
  }
  .subscript_section_card .Polaris-Card {
    box-shadow: none;
  }
  .subscript_section {
    width: 100%;
    margin-right: 0;
    margin-bottom: 24px;
    border-radius: 10px;
    box-shadow: 0rem 0.125rem 0.25rem rgba(31,33,36,.1), 0rem 0.0625rem 0.375rem rgba(31,33,36,.05);
  }
  .real_customers_conetnt .section5_box  {
    flex-direction: column;
  }
  .real_customers_conetnt .section5_box > div ,.real_customers_conetnt .section5_box > div:nth-child(2){
    width: 100% !important;
    margin-bottom: 16px !important;
  }
  .sub_content_head_title h1 {
    font-size: 26px !important;
  }
  .banner_sub h3 {
    font-size: 24px !important;
  }
  .subscript_section:nth-last-of-type(1) {
    margin-top: 36px;
    margin-bottom: 36px;
    /* border-top-right-radius:0 ; */
  }
  .subscript_section:nth-last-of-type(2) {
    /* border-top-left-radius:0 ;
    border-top-right-radius:0 ; */
  }
  .subscript_section_price_it_p {
    font-size: 16px;
  }
  .subscript_faq_content {
    flex-direction: column;
  }
  .subscript_faq_list {
    width: 100%;
    margin-right: 0;
    margin-top: 16px;
  }
}
.sub_paid_time_detail {
  display: flex;
  justify-content: center;
}
.sub_paid_time_detail_content {
  display: flex;
  align-items: center;
  /* padding: 3px 0; */
}
.sub_paid_time_detail_list {
  /* background-color: #000; */
  padding: 10px 14px;
  border-radius: 5px;
}
.sub_paid_time_detail_list_modal {
  background-color: #000;
  padding: 10px 14px;
  border-radius: 5px;
}

.sub_paid_time_detail_lists {
  width: 124px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 5px 0;
  border-radius: 5px;
  /* background-color: transparent; */
}

.sub_paid_time_detail_item {
  display: inline-block;
    /* background-color: #fff; */
    width: 34px;
    font-size: 16px;
    text-align: center;
    padding: 2px 3px;
    border-radius: 3px;
}
.sub_paid_time_detail_list strong {
  color: #882E07;
  font-size: 20px;
}
.sub_paid_time_detail_lists .sub_paid_time_detail_item {
    display: inline-block;
    /* width: calc(100% / 3); */
    color: #882E07;
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    /* background-color: transparent; */
}
.sub_paid_time_detail_item_modal {
  display: inline-block;
    background-color: #fff;
    width: 34px;
    font-size: 16px;
    text-align: center;
    padding: 2px 3px;
    border-radius: 3px;
}
.sub_paid_time_detail_content div svg , .sub_paid_time_detail_content div img {
  vertical-align: bottom;
}
.subscript_popular_type {
  background-color: #000;
}
.subscript_popular_type img {
  margin-right: 16px;
}
.subscript_section_button_type button {
  background: #303030;
  color: #fff;
}
.plan_upgrade_conetent_detail {
  font-size: 24px;
  line-height: 1.2;
  margin-top: 28px;
  margin-bottom: 24px;
  text-align: center;
}
.plan_upgrade_conetent_detail strong {
  color: #f5520c;
  font-size: 56px;
}
.white_dsc {
  margin-top: 16px;
}
.white_dsc p{
  font-size: 24px;
  line-height: 1.5;
}
.white_dsc strong {
  color: #303030;
}
.white_dsc_modal_button button{
  width: 320px;
}
.white_dsc_modal_button button span{
 font-weight: 400;
}

.sub_content_head {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.sub_content_head_title {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  position: relative;
}
.sub_banner_one {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
}
.sub_banner_two {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  display: none;
}
.sub_content_head_title h1 {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  margin-left: 16px;
  z-index: 10;
  margin-top:16px;
  padding-bottom: 25px;
  padding-top: 10px;
}
.sub_content_head_title_fourth {
  height: 88px;
  justify-content: center;
  padding-left: 24px;
}
.sub_content_head_title_fourth .fourth_coupon_content {
  color: #fff;
  z-index: 10;
}
.sub_content_head_title_fourth .fourth_coupon_content p{
  font-size: 20px;
  font-weight: 700;
}
.sub_content_head_title_fourth .fourth_coupon_content strong{
  background: -webkit-linear-gradient(94.64deg, #F5F5F5 11.12%, #F5520D 91.67%); /* Chrome, Safari */
  background: linear-gradient(94.64deg, #F5F5F5 11.12%, #F5520D 91.67%); /* ��׼�﷨ */
  background-clip: text;
  -webkit-text-fill-color: transparent; /* Chrome, Safari */
  color: transparent;
  font-size: 24px;
  margin: 0 5px;
}
.sub_content_head_title_fourth .fourth_coupon_content_time span {
  font-size: 12px;
}
.sub_content_head_title_fourth .fourth_coupon_content_time {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  z-index: 10;
  margin-top: 10px;
}
.sub_content_head_title_fourth .fourth_coupon_content_time img {
  width: 16px;
  margin-right: 5px;
}

.subscript_section_card .Polaris-Card {
  background: transparent;
}
.real_customers_conetnt .section5_box{
  display: flex;
  padding: 8px 0;
  margin: 0 5px;
}
.real_customers_conetnt .section5_box > div{
  background-color: #f6f6f7;
  padding: 20px 30px;
  border-radius: 10px;
  width: 32%;
  display: flex;
  flex-direction: column;
  box-shadow: 0rem 0.125rem 0.25rem rgba(31,33,36,.1), 0rem 0.0625rem 0.375rem rgba(31,33,36,.05);
}
.real_customers_conetnt .section5_box > div:nth-child(2){
  background-color: #f6f6f7;
  padding: 20px 30px;
  margin: 0 24px;
}
.real_customers_conetnt .section5_box .comment_list{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 16px;
}
.real_customers_conetnt .section5_box .comment_list > div{
  padding-right: 15px;
}
.real_customers_conetnt .section5_box .comment_list img{
  border-radius: 50%;
  width: 45px;
  height: 45px;
}
.real_customers_conetnt .section5_box .comment_list_dsc{
  flex: 1;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
}
.real_customers_conetnt .section5_box .comment_list_star{
  display: flex;
  margin: 5px 0;
}
.real_customers_conetnt {
  margin-top: 24px;
  padding-bottom: 48px;
}
.real_customers_conetnt .section5_box .comment_list_star img{
  line-height: 24px;
  height: 22px;
  margin-top: 10px;
}
.real_customers_conetnt .carousel img {
  width: auto;
}
.real_customers_conetnt .carousel ._comment_symbal {
  display: block;
  margin-bottom: 8px;
}
.real_customers_conetnt .carousel.carousel-slider {
  overflow: initial;
}
.real_customers_conetnt .carousel.carousel-slider .control-dots {
  bottom: -50px;
}
.banner_sub {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
}
.banner_sub img {
  vertical-align: bottom;
}
.banner_sub h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin-left: 8px;
}
.banner_sub h3 strong {
  color: #303030;
}
.additional_benefits_modal {
  margin: 20px 30px;
}
.additional_benefits_modal_head {
  display: flex;
  align-items: center;
  justify-content: center;
}
.additional_benefits_modal_head p {
  font-size: 40px;
  font-weight: 700;
  margin-left: 16px;
}
.additional_benefits_modal_head p strong {
  color: #303030;
  margin-right: 5px;
}
.additional_benefits_modal_dsc {
  font-size: 20px;
  margin: 10px 16px 16px;
  line-height: 1.2;
}
.additional_benefits_modal_title {
  font-size: 16px;
  font-weight: 700;
}
.additional_benefits_modal_ccontent_dsc {
  font-size: 14px;
  margin: 4px 0;
  min-height:32px;
}
.additional_benefits_modal_price {
  font-size: 22px;
  font-weight: 600;
}
.additional_benefits_modal_new_price {
  color: rgba(35, 125, 245, 1);
}
.additional_benefits_modal_old_price {
  text-decoration: line-through;
  margin-left: 16px;
  color: #c3c3c3;
  font-weight: 400;
}
.additional_benefits_modal_content {
  display: flex;
  flex-wrap: wrap;
}
.additional_benefits_modal_content .additional_benefits_modal_content_item{
  display: flex;
  box-shadow: 0 0 1px #707070;
  padding: 10px 18px;
  border-radius: 5px;
  margin-bottom: 10px;
  width: calc(100% / 2 - 10px);
  margin-right: 20px;
}
.additional_benefits_modal_content .additional_benefits_modal_content_item:nth-child(2n) {
  margin-right: 0;
}
.additional_benefits_modal_content_left {
  width: 60px;
  margin-right: 16px;
}
.additional_benefits_modal_content_left img {
  width: 60px;
}

.feed_option_modal {
  padding: 16px 50px;
}
.feed_option_emajo {
  text-align: center;
}
.feed_option_select {
  margin: 16px 0;
}
.feed_option_button {
  text-align: center;
}
.feed_option_button button:nth-last-of-type(1){
  margin-left: 16px;
  background-color: #303030;
}
.feed_other textarea{
  width: 100%;
  outline: 0;
  border-color: #F5F5F5;
  text-indent: 8px;
  resize: none;
}
.feed_other {
  position: relative;
}
.feed_num {
  position: absolute;
  z-index: 100;
  right: 4px;
  bottom: 4px;
}
.feed_other_err textarea{
  border-color: #303030;
}
.feed_option_option ul li {
  line-height: 40px;
}
.additon_option_button {
  margin-top: 24px;
}
.additon_option_button button {
  height: 44px;
  padding: 8px 24px;
  width: 150px;
}
.real_customers_conetnt .carousel .control-dots .dot {
  background-color: #D9D9D9;
}
.agent_content {
  padding: 16px;
}
.agent_content_list {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0 24px;
}
.agent_content_item {
  width: calc(100% / 2 - 8px);
  margin-right: 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 16px;
  position: relative;
  padding: 16px;
  border: 1px solid transparent;
  box-shadow: 0rem 0.125rem 0.25rem rgba(31,33,36,.1), 0rem 0.0625rem 0.375rem rgba(31,33,36,.05);
}
.agent_content_item:nth-child(2n) {
  margin-right: 0;
}
.agent_content_item.checked {
  border-color: #000;
}
.agent_content_person {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.agent_content_item_select {
  position: absolute;
  right: 15px;
  top: 15px;
}
.agent_content_box {
  padding:0 10px;
  flex: 1;
}
.agent_content_box_rating {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
/* .agent_content_box_rating > div {
  min-width: 107px;
} */
.agent_content_box_rating span {
  margin-right: 5px;
}
.agent_content_box strong {
  margin-right: 5px;
}
.agent_content_box_name {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}
.agent_title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
}
.agent_content_box_tag {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
}
.agent_content_box_tag span {
  background-color: #F1F1F1;
  border-radius: 10px;
  padding: 2px 5px;
  margin-right: 4px;
  margin-bottom: 4px;
  font-size: 12px;
}
.agent_content_person {
  width: 96px;
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  box-shadow: 0rem 0.125rem 0.25rem rgba(31,33,36,.1), 0rem 0.0625rem 0.375rem rgba(31,33,36,.05);
  border-radius: 5px;
}
.agent_content_person img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
}
.agent_content_input_box {
  display: flex;
  padding-bottom: 10px;
}
.agent_content_input {
  width:50%;
  display: flex;
  align-items: center;
}
.agent_content_input div {
  position: relative;
  flex: 1;

}
.agent_content_input:nth-child(1) {
  margin-right: 16px;
}
.agent_content_input div p{
  position: absolute;
  left: 0;
  color: #f00;
}
.agent_content_input span {
  margin-right: 10px;
  font-size: 16px;
}
.agent_content_input span strong {
  color: #f5520c;
}
.agent_content_input input {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  outline: 0;
  height: 40px;
  text-indent: 10px;
  /* min-width: 220px; */
  width: 100%;
}
.black_premium_discount {
  background-image:linear-gradient(to right , #FF4BB7,#FF7113);
  /* padding: 14px 28px 14px 28px; */
  border-radius: 9.33px;
  margin:0 16px;
  display: flex;
  /* flex-wrap: wrap; */
}
.black_premium_discount .black_premium_discount_title strong{
  font-size: 23.33px;
  font-weight: 650;
  line-height: 28px;
  margin-right: 10px;
  color: #fff;
}
.black_premium_discount .black_premium_discount_title span{
  font-size: 14px;
  font-weight: 450;
  line-height: 18.67px;
  color: rgb(255, 203, 225);
}
.black_premium_discount_l {
  padding: 10px 16px;
}
.black_premium_discount_dsc {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}
.black_premium_discount_r {
  line-height: 1;
  flex: 1;
}
.black_premium_discount_r img {
  width: 100%;
  height: 100%;
}
.subscript_section_button_disabled button {
  background-color: rgb(242, 242, 242);
  pointer-events: auto;
  opacity: 0.7;
  transition: none;
  box-shadow: none;
  border-color: var(--p-color-border-disabled);
  background: var(--p-color-bg-fill-disabled);
  color: var(--p-color-text-disabled);
}
.subscript_section_button_disabled .Polaris-Button--primary::before {
  box-shadow: 0rem 0.125rem 0.25rem rgba(31,33,36,.1), 0rem 0.0625rem 0.375rem rgba(31,33,36,.05);
  background-color:rgb(242, 242, 242);
  transition: none;
  box-shadow: none;
  border-color: var(--p-color-border-disabled);
  background: var(--p-color-bg-fill-disabled);
  color: var(--p-color-text-disabled);
}
.subscript_section_button_disabled .Polaris-Button--primary:hover::before {
  box-shadow: 0rem 0.125rem 0.25rem rgba(31,33,36,.1), 0rem 0.0625rem 0.375rem rgba(31,33,36,.05);
  background-color:rgb(242, 242, 242);
  transition: none;
  box-shadow: none;
  border-color: var(--p-color-border-disabled);
  background: var(--p-color-bg-fill-disabled);
  color: var(--p-color-text-disabled);
}
.subscript_section_button_disabled button:hover {
  box-shadow: 0rem 0.125rem 0.25rem rgba(31,33,36,.1), 0rem 0.0625rem 0.375rem rgba(31,33,36,.05);
  background-color:rgb(242, 242, 242);
  transition: none;
  box-shadow: none;
  border-color: var(--p-color-border-disabled);
  background: var(--p-color-bg-fill-disabled);
  color: var(--p-color-text-disabled);
}

@media screen and (min-width:1365px) and (max-width:1440px) {
  /* .subscript_section_contents {
    margin-top: 72px;
  } */
}
@media screen and (max-width:900px) {
  .premium_item {
    flex-direction: column;
    align-items: flex-start;
   }
   .premium_item  .subscript_section_price {
    margin: 16px 0;
   }
   .premium_item  .subscript_section_button {
    margin-left: 0;
   }
   .premium_item_box .subscript_section_item_left {
    width: 100% !important;
   }
   
  .sub_content_head_title_fourth .fourth_coupon_content p span{
    display: inline-block;
    margin-bottom: 5px;
  }
  .sub_content_head_title_fourth {
    padding: 10px !important;
  }
  .sub_content_head_title_fourth .fourth_coupon_content_time {
    margin-top: 0 !important;
  }
  .additional_benefits_modal_content .additional_benefits_modal_content_item {
    width: 100%;
    margin-right: 0;
  }
  .premium_item .subscript_section_button {
    width: 100% !important;
  }
  .sub_content_head_title_fourth {
    padding-left: 10px;
  }
  .white_dsc p {
    font-size: 18px;
  }
  .plan_grade_content {
    padding: 20px 12px;
  }
  .subscript_section:nth-of-type(1) {
    min-height: auto;
  }
  .subscript_section_content {
    border-radius: 0;
    box-shadow: none;
  }
  .additional_benefits_modal {
    margin: 0;
  }
  .additional_benefits_modal_head p {
    font-size: 30px;
    line-height: 1.2;
  }
  .feed_option_modal {
    padding: 0;
  }
  .subscript_sections:nth-child(3) {
    margin-bottom: 72px;
    margin-top: 60px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .subscript_sections:nth-child(2) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .rotating-text p {
    width: 100% !important;
  }
  .shopify_products_head {
    flex-direction: column;
  }
  .plan_upgrade_head_type strong {
    font-size: 32px;
  }
  .plan_upgrade_head p {
    font-size: 24px;
  }
  .plan_upgrade_conetent_detail strong {
    font-size: 32px;
  }
  .seo_ant_top_btn .itemText {
    font-size: 12px;
  }
  .seo_ant_top_btn {
    margin-top: 10px;
  }
  
  .subscript_section_content {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: center;
    margin: 0 10px;
  }
  
  .subscript_section {
    width: 100%;
    margin-right: 0;
    margin-bottom: 24px;
    border-radius: 10px;
    border-right: 0;
    box-shadow: 0rem 0.125rem 0.25rem rgba(31,33,36,.1), 0rem 0.0625rem 0.375rem rgba(31,33,36,.05);
  }
  .real_customers_conetnt .section5_box > div:nth-child(2) {
    margin: 0;
  }
  .subscription_page .Polaris-Card {
    border-radius: 10px;
    box-shadow: none;
  }
  .real_customers_conetnt .section5_box  {
    flex-direction: column;
  }
  .real_customers_conetnt .section5_box > div ,.real_customers_conetnt .section5_box > div:nth-child(2){
    width: 100% !important;
    margin-bottom: 16px !important;
  }
  .sub_content_head_title h1 {
    font-size: 18px !important;
  }
  .banner_sub h3 {
    font-size: 18px !important;
  }
  
  .subscript_section_price_it_p {
    zoom: 0.9;
  }
  .subscript_faq h3 {
    padding-left: 16px;
  }
  .subscript_faq_content {
    flex-direction: column;
  }
  .subscript_faq_list {
    width: 100%;
    margin-right: 0;
    margin-top: 16px;
  }
}
@media screen and (max-width:600px) {
  .mg_content {
    margin: 0 10px;
  }
  .agent_content_input {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .agent_content_input div {
    width: 100% !important;
  }
  .agent_content_input input {
    width: 100% !important;
  }
  .agent_content_input:nth-child(1) {
    margin-right: 10px;
  }
  .agent_modal .common_modal_mask {
    align-items: flex-end;
  }
  .subscript_faq_item_title p {
    zoom: 0.85;
  }
  .rotating-text p .word {
    font-size: 14px;
  }
  
  .subscript_popular {
    position: absolute;
    left: 20px;
    top: -40px;
  }
  .plan_upgrade_head {
    font-size: 20px;
  }
  .plan_upgrade_conetent ul li {
    font-size: 12px;
    line-height: 1.5;
    height: auto;
  }
  .select_store .store, .select_language .language {
    white-space: initial;
  }
  .make_item_text .Polaris-TopBar-Menu__Activator {
    margin-right: auto;
  }
  .make_item_text .Polaris-TopBar__Contents {
    width: 85%;
  }
  .sub_content_head_title {
    overflow: hidden;
    border-radius: 15px;
    margin: 0 16px;
  }
  .sub_banner_one {
    height: 100%;
    display: none;
  }
  .sub_banner_two {
    height: 100%;
    display: block;
  }
}
@media screen and (max-width:599px) {
  .agent_right_rating_span {
    position: relative !important;
    left: 0 !important;
    top: 1px !important;
  }
  .agent_content_list {
    flex-direction: column;
  }
  .agent_content_item {
    width: 100%;
  }
  .agent_content_person {
    width: 60px;
    height: 60px;
  }
  
  .agent_right_rating > div ,.agent_content_box_rating > div{
    width: auto !important;
  }
  .agent_content_input span {
    font-size: 14px;
  }
  .premium_item_box  .agent_left {
    width: 60px;
    height: 60px;
   }
   .subscript_section_dsc {
    font-size: 13px;
   }
   .agent_right_year span {
    font-size: 12px;
   }
   .premium_item .subscript_section_button {
    width: 100%;
   }
}
.rotating-text {
  display: flex;
  /* justify-content: center; */
  z-index: 10;
  margin-left: 16px;
  margin-top: 10px;
}
.rotating-text p {
  /* display: inline-flex; */
  margin: 0;
  width: 320px;
  min-height: 32px;
  max-height: 72px;
  vertical-align: top;
  overflow: hidden;
  position: relative;
}
.rotating-text p .word {
  position: absolute;
  color: #fff;
  /* opacity: 0; */
  margin-left: 5px;
  /* text-align: center; */
  width: 100%;
  top: 100%;
  white-space: nowrap;
}
.rotating-text p .word .letter {
  transform-origin: center center 25px;
}
.rotating-text p .word .letter.out {
  top: 100%;
  transition: 0.32s cubic-bezier(0.6, 0, 0.7, 0.2);
}
.rotating-text p .word .letter.in {
  transition: 0.38s ease;
}
.rotating-text p .word .letter.behind {
  top: -100%;
}
.hrefLink:hover {
    color: #303030;
}
.top_span_hover:hover {
  color: #303030;
}





._hUo3 .CLJ7G {
  display: none
}

html[class*=Polaris-Summer-Editions-2023] ._hUo3 .CLJ7G {
  display: inline
}

._hUo3 .hKH6r,html[class*=Polaris-Summer-Editions-2023] ._hUo3 .Pklnb {
  display: none
}

._hUo3:hover .hKH6r {
  /* display:block; */
}

._hUo3:hover .WzwlI {
  display: none
}
.JkbtP {
  --task-manual-completion-bg: #008060;
  --task-manual-completion-checked-color: #fff;
}
.JkbtP {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0
}

.JkbtP:focus {
  outline: none
}

.JkbtP {
  display: flex;
  position: relative
}

.JkbtP:after {
  border-radius: calc(var(--p-border-radius-100) + .0625rem);
  bottom: -.0625rem;
  box-shadow: 0 0 0 -.0625rem var(--p-color-border-focus);
  content: "";
  display: block;
  left: -.0625rem;
  pointer-events: none;
  position: absolute;
  right: -.0625rem;
  top: -.0625rem;
  transition: box-shadow var(--p-motion-duration-100) var(--p-motion-ease);
  z-index: 1
}

.JkbtP:focus-visible:after {
  box-shadow: 0 0 0 .125rem var(--p-color-border-focus);
  outline: var(--p-border-width-025) solid #0000
}

.JkbtP {
  --task-manual-completion-bg: #008060;
  --task-manual-completion-checked-color: #fff
}

.recommended_category_Tab{
    padding: 0px;
}

.recommended_category_Tab, .recommended_category_Tab .left, .recommended_category_Tab .right, .recommended_category_Tab .left .icon{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.recommended_category_Tab .left .icon .Polaris-Icon{
    height: 30px;
    width: 30px;
}

.recommended_category_Tab .left .title{
    font-size: 20px;
    font-weight: 500;
    padding-left: 10px;
}

.recommended_category_Tab .right .span{
    margin-right: 10px;
}
.holiday_select_item .Polaris-Select{
    min-width: 100px;
}
@media screen and (max-width:600px) {
    .recommended_category_Tab {
        display: block;
    }
    .recommended_category_Tab .left {
        justify-content: flex-start;
        margin-bottom: 10px;
    }
   
}
.Inventory_Product__Title.shopping_cart span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.imagePreview img {
    visibility: visible !important;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* div[data-portal-id=modal-Polarisportal14] .Polaris-Modal-Dialog__Container{
    z-index: 999;
} */
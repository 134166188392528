@media screen and (max-width:600px) {
  #__helpdesk-channel .helpdesk-channel-btn-show, #__helpdesk-channel .helpdesk-channel-btn-hidden {
    z-index: 101 !important;
    bottom: 80px !important;
    right: 16px !important;
  }
}
.Polaris-Navigation__Text {
  font-size: 14px;
  font-weight: 400;
}
.Polaris-Navigation__ItemInnerWrapper--selected .Polaris-Navigation__Text {
  color: #008060;
  font-weight: 400;
}
.success{
  background-color:#50B83C;
}
.Polaris-Page {
  margin-top: 24px;
}
.Polaris-TopBar-UserMenu__Details {
  padding: 0;
}
.Polaris-Frame__Content {
  background-color: #f6f6f7;
}
.error{
  background-color: #F49342;
}

.seoscore{
  padding: 0.5rem 0.7rem;
  color: #fff;
  border-radius:0.35rem; 
  display: block;
  width:70%;
}

.Link_To_AliExpress img{
  margin-right: 8px;
}

.AliExpress_Auth_Text{
  margin: auto;
}

.Polaris-TopBar-Menu__ActivatorWrapper .Polaris-Avatar--styleThree{
  background: #006fbb;
}

.my_products,.my_orders,.my_notifications,.my_analysis{
  margin-bottom: -1rem;
}

.Link_To_AliExpress.tips:hover:after{
  border-radius: 3px;
  padding: .4rem .8rem;
  background-color: #202123;
  color: #fff;
  content: attr(data-title);
  font-size: 1.4rem;
  position: absolute;
  left: 10px;
  top: 40px;
  word-break: break-word;
  max-width: 20rem;
  min-width: 200px;
  z-index: 999;
}

.DropShipMan .Polaris-TopBar__SecondaryMenu{
  /* min-width: 280px; */
  display: flex;
  justify-content: space-evenly;
  /* width: 58%; */
}

.select_store, .select_language{
  display: flex;
  align-items: center;
}

.select_store .store, .select_language .language{
  font-weight: 600;
  margin-left: 5px;
  margin-right: 3px;
  white-space: nowrap;
}

.select_store .Polaris-Icon, .select_language .Polaris-Icon{
  height: 1.8rem;
  width: 1.8rem;
}


.success{
  background-color:#50B83C;
}

.error{
  background-color: #F49342;
}

.seoscore{
  padding: 0.5rem 0.7rem;
  color: #fff;
  border-radius:0.35rem; 
  display: block;
  width:70%;
}

.SEOMaster .Polaris-TopBar__SecondaryMenu{
  min-width: 180px;
}

.Link_To_AliExpress img{
  margin-right: 8px;
}

.AliExpress_Auth_Text{
  margin: auto;
}

.Polaris-TopBar-Menu__ActivatorWrapper .Polaris-Avatar--styleThree{
  background: #006fbb;
}

.my_products,.my_orders,.my_notifications,.my_analysis{
  margin-bottom: -1rem;
}

.CartBart_Choose_Icon{
  padding: 1.1rem;
  border: #d8cdcd solid 1px;
  border-radius: 3px;
  margin: 5px 4px;
  cursor: pointer;
}

.CartBart_Choose_Icon:hover{
  background-color: #e3edf7;
}

.CartBart_Choose_Icon.selected{
  border: #6371c7 solid 2px;
}

.has_clean{
  opacity: 0.3;
  background-color: lightgray;
}
.make_item_text .Polaris-Navigation__Item {
  font-weight: 400;
}
.make_item_text .Polaris-Navigation__Icon svg {
  fill: rgb(58 56 56);
}
.make_item_text .Polaris-Navigation__Item--selected .Polaris-Navigation__Icon svg ,.make_item_text .Polaris-Navigation--subNavigationActive .Polaris-Navigation__Icon svg {
  fill: rgba(0, 128, 96, 1);
}
.make_item_text .Polaris-TopBar-Menu__Activator{
  margin-right: 10px;
} 
/* .make_item_text .Polaris-TopBar-Menu__Activator:nth-last-child(1){
  margin-right: 0px;
}
/* 
.Polaris-Navigation__Item--selected{
  color: #F90;
}

.Polaris-Navigation__Item--selected .Polaris-Navigation__Icon svg, .Polaris-Navigation__Item--selected:hover .Polaris-Navigation__Icon svg, .Polaris-Navigation--subNavigationActive .Polaris-Navigation__Icon svg, .Polaris-Navigation--subNavigationActive:hover .Polaris-Navigation__Icon svg, .Polaris-Navigation--subNavigationActive:focus .Polaris-Navigation__Icon svg{
  fill: #F90;
  background-color: #f2f2f2;
}

.Polaris-Navigation{
  background: #fff;
}

.Polaris-Button--primary.Polaris-Button--disabled{
  background:#ff4747;
  border-color:#fd5454;
}

.Polaris-Button--primary {
  background: linear-gradient(to bottom, #ff4747, #ff4747) !important;
  border-color: #ff4747;
  box-shadow: inset 0 1px 0 0 #ff4747, 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 0 0 transparent;
  color: white;
}

.Polaris-Button--primary:hover {
  background: linear-gradient(to bottom, #fd5959, #ff6363);
  border-color: #ff5d5d;
  color: white;
  text-decoration: none;
}

.Nav_Left_Menu .active a, .Nav_Left_Menu>.active>a:hover, .Nav_Left_Menu>.active>a:focus{
  background-color: #d6d6d6 !important;
}

.Polaris-Navigation__Item:hover, .Polaris-Navigation__Item:focus{
  color: #F90;
}

.Polaris-Navigation__Item:hover .Polaris-Navigation__Icon svg{
  fill: #F90;
}

.Polaris-Navigation__Item:focus .Polaris-Navigation__Icon svg{
  fill: #F90;
}

.Polaris-Tabs__Tab.Polaris-Tabs__Tab--selected .Polaris-Tabs__Title {
  border-bottom: 0.3rem solid #F90;
}

.Polaris-Tabs__Tab:focus{
  box-shadow:inset 0 0 2px 0 rgba(243, 0, 0, 0.8), 0 0 2px 0 rgba(196, 92, 92, 0.8);
}


.Polaris-Tabs__Tab:focus .Polaris-Tabs__Title{ 
  border-bottom: 0.3rem solid rgb(255, 196, 106);
}

.Polaris-Button--primary:focus {
  border-color: #ff5d5d;
  box-shadow: inset 0 1px 0 0 rgba(196, 92, 92, 0.8), 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 0 1px rgba(243, 0, 0, 0.8);
}
 */


/* .Polaris-Button--primary:focus {
  background-color: #50B83C;
  border-color: #50B83C;
  outline: none;
} */


/* .Polaris-Button--primary:focus-visible {
  background-color: #50B83C;
  border-color: #50B83C;
  outline: none;
} */

/* .Polaris-Button--primary:hover {
  background-color: #50B83C;
  border-color: #50B83C;
  outline: none;
} */

/* .Polaris-Button--primary:visited {
  background-color: #50B83C;
  border-color: #50B83C;
  outline: none;
} */

.Polaris-ResourceItem__Container {
  position: relative;
  z-index: 2;
  padding: 1.2rem 1.6rem;
  min-height: 4.4rem;
  display: flex;
  align-items: flex-start;
}
.Polaris-ResourceItem__Media {
  flex: 0 0 auto;
  margin-right: 1rem;
  color: inherit;
  text-decoration: none;
}

.Polaris-OptionList-Option .Polaris-Link{
  width: 100%;
}

/* language css */
#Polarispopover1{
  width: 120px;
}

/* .DropShipMan .Polaris-TopBar__LogoLink{
  pointer-events: none;
} */

/* 切换店铺加载样式 */
.loading-version {
  position: fixed;
  z-index: 2147483645;
  left: 0%;
  width: 100%;
  top: 54px;
  background-color: rgb(0 0 0 / 50%);
  opacity: 1;
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.dsm_info_tip {
  /* height: 50px; */
  position: fixed;
  right: 26px;
  bottom: 40%;
  /* width: 50px; */
  z-index: 100;
}
.extra_service {
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: #303030;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
}
.extra_service .Polaris-Icon {
  margin: 0 0 0 6px;
}
.extra_service svg {
  fill: #303030;
}
.secondaryAdvertisementMarkup_pagefly {
  background: rgb(235, 235, 235);
  border-radius: 5px;
  width: 250px;
}
.secondaryAdvertisementMarkup_pagefly a {
  display: inline-flex;
  align-items: center;
  height: 100%;
}
.secondaryAdvertisementMarkup_pagefly a span {
  /* height: 30px;
  line-height: 30px; */
  color: #515BF6;
}
.secondaryAdvertisementMarkup_pagefly a span img {
  vertical-align: sub;
}
.secondaryAdvertisementMarkup_pagefly a .page_fly_mid {
  margin: 0 5px;
  padding-right: 5px;
  border-right: 0.5px solid #E0DADA;
  
}
@media screen and (max-width:1024px) {
  .secondaryAdvertisementMarkup {
    display: none;
  }
  .secondaryAdvertisementMarkup_pagefly {
    display: none;
  }
}
@media screen and (max-width:600px){
  .Polaris-TopBar__SecondaryMenu > div:nth-child(1), .Polaris-TopBar__SecondaryMenu > div:nth-child(2) {
    display: none;
  }
  .dsm_info_tip {
    /* height: 50px; */
    right: 22px;
    bottom: 40%;
  }
}

.privacypolicy p{
  font-size: 1.5rem;
}

.privacypolicy h2{
  font-size: 1.8rem;
}

.privacypolicypage .Polaris-Page{
  max-width: 90rem;
}

.web_notice {
  position: fixed;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background: rgb(181 181 181 / 50%);
  z-index: 99999;
}

.web_notice .mom {
  width: 700px;
  line-height: 100px;
  text-align: center;
  font-size: 30px;
  border-width: 5px;
  border-style: dashed;
  border-color: #ffffff;
  color: rgb(255, 0, 55);
}

.web_notice .content {
  font-size: 18px;
  line-height: 1.5;
  color: #000000;
  text-align: left;
  margin: 50px 0 50px 0;
}

@media (max-width: 699px) {
  .web_notice .mom {
    width: 340px !important;
  }
}

.order_tracking_content {
    padding:40px 20px;
    min-height: 300px;
}
.order_tracking_list {
    display: flex;
}
.order_tracking_box{
    width: 80px;
    text-align: center;
}
.order_tracking_icon {
    text-align: center;
}
.order_tracking_icon img {
    width: 22px;
}
.order_tracking_item {
    width: calc( 100% / 6 );
    position: relative;
}
.order_tracking_title{
    white-space: nowrap;
}
.order_tracking_day{
    margin-top: 10px;
    color: #C7C2C2;
    font-size: 12px;
}
.order_tracking_line{
    position: absolute;
    top: 11px;
    left: 50px;
    height: 1px;
    width: 88%;
    background-color: #C7C2C2;
}
.order_tracking_item:last-child .order_tracking_line{
    width: 0;
}
.order_tracking_process_text{
    position: absolute;
    top: -18px;
    left: 75px;
    font-size: 12px;
    width: 100%;
    color: #C7C2C2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
}
.order_tracking_process_detail{
    margin-top: 24px;
    margin-left: 20px;
}
.order_tracking_process_detail h3{
    font-size: 16px;
    font-weight: 500;
}
.order_tracking_process_detail_day{
    margin: 8px 0;
}
.order_tracking_process_detail_list{
    background: #f5f5f5;
    padding: 16px;
    border-radius: 5px;
    width: 90%;
}
.order_tracking_process_detail_item{
    display: flex;
    padding-bottom: 5px;
}
.order_tracking_process_detail_dsc{
    margin-left:24px;
    margin-right:10px;
    display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
    text-overflow: ellipsis;
   overflow: hidden;
}
.order_tracking_content_empty .emptyWithImg .Polaris-EmptyState__Image{
    margin-left: 0 !important;
}
.order_tracking_process_detail_time{
    white-space: pre;
}
@media screen and (max-width:768px) {
    .order_tracking_list{
        flex-direction: column;
    }
    .order_tracking_item{
        width: 100%;
        margin-bottom: 16px;
    }
    .order_tracking_day{
        margin-top: 0;
    }
    .order_tracking_process_detail_list{
        width: 100%;
        font-size: 12px;
    }
    .order_tracking_process_detail_time{
        width: 60px;
        white-space: nowrap;
    }
}
.tag_content .Polaris-Tag {
    margin-right: 8px;
}
.ai-points-less-modal {
    font-size: 13px;
    font-weight: 450;
    line-height: 20px;
    margin-top: 8px;
    color: rgba(48, 48, 48, 1);
}
.product_cell_03_price span {
    display: inline-block !important;
    word-wrap: break-word !important;
    white-space: wrap !important;
}
.ai-points-less-modal li{
    list-style: disc;
    padding-left: 8px;
}
.product_modal_image {
    width: 50%;
}
.product_modal_image img {
    width: 100%;
    border-radius: 8px;
}
.product_modal_details_content {
    flex: 1;
}
.product_modal_details {
    min-height: 332px;
    max-height: 332px;
}
.product_modal_image {
    max-height: 332px;
}
.product_modal_image img {
    height: 100%;
}
.product_modal_details_content .Polaris-Scrollable {
    outline: none !important;
}
.product_modal_details_content .product_detail_variant_title {
    padding-bottom: 5px;
}
.product_detail_variant_title h4 {
    font-size: 14px;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
}
/* 设置表格整体样式 */

.Prodcut_Shipping_Table_detail table {
    width: 100%;
    border-collapse: collapse; /* 合并边框 */
    
    /* margin: 20px 0; */
  }
  
  /* 表头样式 */
  .Prodcut_Shipping_Table_detail  th {
    height: 44px; /* 高度 */
    background-color: #fff; /* 背景色 */
    color: rgba(48, 48, 48, 1); /* 文字颜色 */
    border-bottom: 2px solid #ddd; /* 底部划线 */
    text-align: center;
  }
  
  /* 表格单元格样式 */
  .Prodcut_Shipping_Table_detail td {
    height: 40px; /* 高度 */
    padding: 12px;
    border-bottom: 1px solid #ddd; /* 底部划线 */
    color: #333; /* 文字颜色 */
  }
  
  /* 鼠标悬停行效果 */
  .Prodcut_Shipping_Table_detail tr:hover {
    background-color: #f5f5f5;
  }
  
  /* 隔行变色 */
  .Prodcut_Shipping_Table_detail tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  .Prodcut_Shipping_Table_detail tbody tr:nth-child(1) {

  }
  /* 自定义顶部边框（可选） */
  .Prodcut_Shipping_Table_detail thead {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: rgb(245, 245, 245);
    border-bottom: 1px solid #ddd; /* 底部划线 */
  }
  .Prodcut_Shipping_Table_detail thead th {
    border-bottom: 2px solid #ddd; /* 底部划线 */
  }
@media screen and (max-width: 768px) {
    .product_modal_details {
        max-height: 100vh;
    }
    .product_modal_image {
        max-height: 100% !important;
    }
    .product_modal_details > div {
        flex-wrap: wrap;
        --pc-inline-stack-wrap:'wrap'
    }
    .product_modal_details .product_modal_image {
        width: 100%;
    }
    .product_modal_details_content {
        width: 100%;
    }
}
.col_box {
  display: flex;
  justify-content: space-between;
}
.col_box_left_top {
  display: flex;
  align-items: center;
}
.col_box_right {
  width: 136px;
}
.col_box_right img {
  width: 100%;
}
.col_box_left_top_icon {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #303030;
  border-radius: 50%;
  margin-right: 8px;
}
.col_box_left_top_icon svg {
  fill: #fff;
}
.col_box_left_top_level {
  margin-left: 8px;
  font-size: 12px;
  display: flex;
  align-items: center;
  font-weight: 600;
  background-color: #ffe600;
  border-radius: 10px;
  padding: 1px 8px;
}
.col_box_left_top_level img {
  width: 20px;
}
.col_box_left h6 {
  margin: 8px 0;
}
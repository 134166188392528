.orderTarckSearchArea {
  display: flex;
  justify-content: flex-start;
  padding: 16px;
}
.emptyOrderTrack .Polaris-Card {
  margin-top: 16px;
  min-height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orderTarckSearchArea .startDateInput,
.orderTarckSearchArea .endDateInput {
  width: 120px !important;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  height: 32px;
}
.order_tracking_time {
  display: flex;
  align-items: center;
}

.orderTarckSearchArea .startDateInput {
  border-left: 1px solid #ccc;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.orderTarckSearchArea .endDateInput {
  border-right: 1px solid #ccc;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.orderTarckSearchArea .timearrow{
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  height: 32px;
}
.orderTarckSearchArea .timearrow img{
  height: 32px;
}

.orderTarckSearchArea .orderNumInput {
  width: 220px !important;
}

.orderTarckSearchArea .orderNumInput,
.orderTarckSearchArea .searchBtn {
  margin:0 15px;
}

/* 
.react-datepicker__input-container {
  height: 36px;
} */

.orderTarckSourceDataArea {
  margin-top: 35px;
}
.trackTitle{
  display: flex;
  justify-content: center;
}
.trackoperation{
  width: 130px;
  justify-content: flex-end;
  padding-right: 17px
}
.trackoperation,
.messageoperation {
  cursor: pointer;
}

.emptyOrderTrack .Polaris-DisplayText{
  width: 330px;
}

.messageoperation {
  position: relative;
}

.messageoperation span {
  position: absolute;
  top: 0;
  left: 32px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #F5520C;
}

.pagecontainer {
  margin-top: 2.5rem;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;

}

.collapsibleContainer {
  border-top: 1px solid #efefef;
}

.collapsibleContent {
  padding: 15px;
  background-color: #fff;
  /* background: #ccc; */
}

.collapsibleContent>div {
  display: flex;
  align-items: center;
  background: #efefef;
  padding: 15px;
  border-radius: 10px;
}

.collapsibleContent>div img {
  width: 80px;
  height: 80px;
}

.collapsibleContent>div>div p{
  color: rgb(105, 104, 104);
  margin: 8px;
}

.collapsibleContent>div .cost {
  margin-left: 130px;
}

.collapsibleContent>div .cost>span{
  font-weight: 500;
}

.messageModalContainer:not(:first-child) {
  margin-top: 28px;
}

.messageModalTitle {
  display: flex;
}

.messageModalTitle span {
  margin-left: 25px;
  color: #9DA2AB;
}

.messageModalContent {
  margin-top: 20px;
  padding: 20px;
  background-color: #F6F6F7;
  word-break: break-word;
}


.remarkarea .Polaris-Icon__Svg {
  fill: rgb(129, 141, 154);
}
.tracking_row {
  display: grid;
  /* 12列，每列 1:1 */
  grid-template-columns: repeat(8, 1fr);
  gap: 20px;
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 10px;
}

.tracking_title{
  font-weight: 500;
  overflow-y: hidden;
  overflow-x: auto;
}

.tracking_row-col-4 {
  grid-column: span 4;
}

.tracking_row-col-3 {
  grid-column: span 3;
}

.tracking_row-col-2 {
  grid-column: span 2;
}
.tracking_row-col-1 {
  grid-column: span 1;
  display: flex;
  align-items: center;
}
@media screen and (max-width:600px) {
  .orderTarckSearchArea {
    flex-direction: column;
  }
  .orderTarckSearchArea .orderNumInput, .orderTarckSearchArea .searchBtn {
    margin: 0;
    width: 100% !important;
    margin-bottom: 16px;
    margin-top: 16px;
  }
  /* .tracking_row-col-1 {
    white-space: nowrap;
  } */
}
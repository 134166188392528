.empty_search_result{
    margin-top: 3rem;
}

.calculation_freight_spinner {
    margin: 3.5rem 0;
    text-align: center;
}
.select-box {
    display: flex;
    flex-wrap: wrap;
}
.select-box > div {
    width: 295px;
    margin-right: 16px;
}
.select-box > div:nth-last-of-type(1) {
    margin-right: 0;
}
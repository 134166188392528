.dashboard_page_box {
    margin-bottom: 24px;
}
.dashboard_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 24px;
}
.dashboard_title h1 {
    font-size: 20px;
    font-weight: 650;
    line-height: 24px;
    text-align: left;
    max-width: 600px;
    word-break: keep-all;
}
.dashboard_title .dashboard_title_mobile_span_line:hover {
    text-decoration: underline;
}
.dashboard_title_button {
    display: flex;
    align-items: center;
}
.dashboard_title_button p:nth-child(1) {
    margin-right: 8px;
}
.dashboard_title_button p {
    padding: 6px 12px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: rgba(227, 227, 227, 1);
    font-size: 12px;
    font-weight: 550;
    cursor: pointer;
}
      
.dashboard_banner {
    margin-top: 16px;
}
.dashboard_banner .Polaris-Banner--textInfoOnBgFill {
    display: none;
}
.dashboard_banner:hover .Polaris-Banner--textInfoOnBgFill{
    display: block;
}

.proper_list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.proper_list_item {
    width: calc(100% / 3 - 8px);
}
.proper_list_item:last-child {
    margin-right: 0;
}
.proper_list_item .Polaris-Box{
    width: 100%;
    display: flex;
}
.proper_list_item_l {
    line-height: 1;
    margin-right: 16px;
    position: relative;
}
.manager_box_content_l_text p{
    display: flex;
    align-items: center;
}
.manager_box_content_l_icon {
    position: absolute;
    right: 16px;
    top: 10px;
}
.proper_list_item_r h3 {
    font-size: 20px;
    font-weight: 650;
    line-height: 24px;
    text-align: left;
    margin-top: 15px;
    cursor: pointer;
}
.proper_list_item_r p {
    width: max-content;
    border-bottom: 2px dotted rgba(204, 204, 204, 1);
}
.proper_list_item_r p span {
    font-weight: 550;
    font-size: 13px;
}
.proper_list_item_r .code_line{
    padding: 6px 7px 6px 7px;
    border-radius: 4px;
    background-color: rgba(243, 243, 243, 1);
    color: rgba(0, 91, 211, 1);
}
.manager_box_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 32px;
}
.manager_box_title h3 {
    font-size: 14px;
    font-weight: 650;
    line-height: 20px;
    text-align: left;
}
.manager_box_title div button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    padding: var(--p-space-100);
    -webkit-backdrop-filter: blur(var(--p-space-200));
    backdrop-filter: blur(var(--p-space-200));
    border-radius: var(--p-border-radius-200);
    background-color: #fff9;
    cursor: pointer;
    color: var(--p-color-icon-secondary);
    transition-property: background-color, opacity, color;
    transition-duration: var(--p-motion-duration-200);
    transition-timing-function: var(--p-motion-ease);
}
.manager_box_content_l_title {
    font-size: 20px;
    font-weight: 650;
    line-height: 24px;
    text-align: left;
}
.manager_box_content_l_subtitle {
    font-size: 13px;
    font-weight: 450;
    line-height: 20px;
    text-align: left;
    margin: 10px 0 16px;
}
.manager_box_title div {
    display: none;
}
.dashboard_sale:hover .manager_box_title div {
    display: flex;
}
.manager_box_title div span {
    cursor: pointer;
}
.manager_box_content {
    display: flex;
    margin-top: 16px;
    min-height: 176px;
}
.manager_box_content_l {
    width: 428px;
    padding: 16px;
    position: relative;
    border: 1px solid rgb(211 209 209);
    border-radius: 8px;
    background-color: rgb(248, 248, 248);
}
.manager_box_content_l_agent {
    background-image: linear-gradient(to right, rgba(100, 100, 100, 1), rgba(0, 0, 0, 1));
}
.manager_box_content_r {
    flex: 1;
    position: relative;
    margin-left: 16px;
    line-height: 1;
}
.manager_box_content_r_text {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 282px;
    font-size: 12px;
}
.manager_box_content_r_text p {
    font-size: 12px;
    font-weight: 550;
    line-height: 16px;
    color: rgba(118, 118, 118, 1);
    text-align: left;
    margin-top: 22px;
}
.manager_box_content_r_person {
    position: absolute;
    bottom: -16px;
    line-height: 1;
    right: 0;
    z-index: 0;
    width: 150px;
    height: 220px;
}
.manager_box_content_r_agent .manager_box_content_r_person {
    width: 180px;
    height: 255px;
}
.manager_box_content_r_person img{
    width: 100%;
    height: 100%;
}
.manager_box_content_l_icon {
    width: 100px;
}
.manager_box_content_l_icon img {
    width: 100%;
}

.manager_box_content_l_star_box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: #fff;
}
.manager_box_content_l_agent .manager_box_content_l_title {
    color: rgba(255, 228, 207, 1);
}
.manager_box_content_l_agent .manager_box_content_l_subtitle {
    color: rgba(255, 255, 255, 1);
}
.manager_agent_content_box_tag {
    display: flex;
    flex-wrap: wrap;
}
.manager_agent_content_box_tag span {
    padding: 2px 8px;
    border-radius: 8px;
    background-color: rgba(255, 214, 164, 1);
    margin-right: 5px;
    color: rgba(94, 66, 0, 1);
    font-size: 12px;
    font-weight: 550;
    line-height: 16px;
}
.manager_box_content_l_agent .manager_box_content_l_text {
    color: #fff;
    line-height: 1;
}
.manager_box_content_l_agent .manager_box_content_l_text svg {
    fill: #fff;
}

.new_person_box_right_button {
    display: none;
   
}
.new_person_box_right_button button ,.fulfilled_orders_content_line_arrowup button , .elite_partner button{
    appearance: none;
    border: none;
    padding: var(--p-space-100);
    backdrop-filter: blur(var(--p-space-200));
    border-radius: var(--p-border-radius-200);
    background-color: #fff9;
    cursor: pointer;
    color: var(--p-color-icon-secondary);
    transition-property: background-color, opacity, color;
    transition-duration: var(--p-motion-duration-200);
    transition-timing-function: var(--p-motion-ease);
}
.dashboard_new_person:hover .new_person_box_right_button {
    display: flex;
}

.new_person_box_text_n_step {
    line-height: 1;
    background-color: rgba(224, 240, 255, 1);
    max-width: max-content;
    padding: 4px 8px;
    border-radius: 8px;
    margin: 10px 0;
}
.get_start_item_text_btn button:nth-child(1) {
    margin-right: 10px;
}
.new_person_box_text_n_step img {
    vertical-align: sub;
}
.get_start_item_p_text {
    display: flex;
    align-items: center;
}
.get_start_item_p_text span {
    padding: 2px 8px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 550;
    margin-left: 10px;
    border: 1px solid rgba(138, 138, 138, 1);
    background-color: #fff;
}
.get_start_item_p_text span img {
    vertical-align: sub;
    margin-right: 4px;
}
.get_start_item_n {
    display: flex;
    padding-bottom: 0 !important;
}
.get_start_item_n_box {
    flex: 1;
}
.get_start_item_n_box_img {
    /* flex: 1; */
    line-height: 1;
    max-height: 200px;
    max-width: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.manager_box_content_l_text_icon svg {
    fill: rgba(74, 74, 74, 1);
}
.get_start_list_n .get_start_item:nth-child(1) .get_start_item_text_title > span {
    height: 20px !important;
}
.get_start_list_n .get_start_item:nth-child(1) .get_start_item_n_box_img {
    max-width:160px ;
}
.get_start_list_n .get_start_item:nth-child(5) .get_start_item_text_title > span {
    height: 20px !important;
}
.get_start_list_n .get_start_item:nth-child(5) .get_start_item_n_box_img {
    max-width:160px ;
}
.get_start_item_n_box_img img {
    width: 100%;
    /* height: 100%; */
}
.get_start_item_n_box .get_start_item_text_title > span {
    height: 40px !important;
    max-width:100% !important;
}
.get_start_item_n_box .get_start_item_text_btn {
    margin: 10px 0 0 24px;
    display: flex;
    align-items: center;
}
.get_start_item_n_box .get_start_item_text_title {
    padding: 10px 16px;
}
.get_start_item_n_box .get_start_item_text {
    min-height: auto;
    max-height: 100%;
}
.new_plan_content {
    margin: 16px;
    box-shadow:0px 0px 1px 1px rgba(0, 0, 0, 0.13) inset;
    border-radius: 8px;
    /* max-height: 100vh;
    overflow-y: auto; */
}
.new_plan_content_list {
    display: flex;
}
.new_plan_content_list_item {
    padding: 40px 16px 5px 16px;
    position: relative;
}
.new_plan_content_list_item .new_plan_content_list_item_reecomend {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 4px 16px 4px 16px;
    color: #fff;
    background-color: rgba(245, 82, 12, 1);
}
.new_plan_content_list_item:nth-child(2) {
    border-left: 1px solid rgba(211, 211, 211, 1);
    border-right: 1px solid rgba(211, 211, 211, 1);
}
.new_plan_content_list_item_day {
    font-size: 16px;
    font-weight: 650;
    line-height: 24px;
    text-align: left;
}
.new_plan_content_list_item_price {
    display: flex;
    align-items: center;
}
.new_plan_content_list_item_price strong{
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    text-align: left;
    margin-right: 8px;
}
.new_plan_content_list_item_price p span{
    font-size: 20px;
    font-weight: 650;
    line-height: 24.2px;
    text-align: left;
    margin-right: 4px;
}
.new_plan_content_list_item_price p img {
    vertical-align: sub;
}
.new_plan_content_list_item_dsc {
    margin: 10px 0 16px;
    
}
.new_plan_content_list_item_button  {
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(211, 211, 211, 1);
}
.new_plan_content_list_item_button button {
    padding: 12px 20px;
}
.new_plan_content_list_item_tip {
    font-size: 13px;
    font-weight: 450;
    line-height: 20px;
    text-align: left;
    color: rgba(118, 118, 118, 1);
    padding: 24px 0 10px;
}
.new_plan_content_list_item_feature {
    font-size: 13px;
    font-weight: 650;
    line-height: 20px;
    text-align: left;
   color: rgba(48, 48, 48, 1);
}
.new_plan_content_list_item_ul ul{
    padding-left: 16px;
}
.new_plan_content_list_item_ul ul li {
    list-style: disc;
    font-size: 13px;
    font-weight: 450;
    text-align: left;
}
.new_plan_content_bottom {
    border-top: 1px solid rgba(211, 211, 211, 1);
    padding: 16px;
    text-align: center;
    font-size: 12px;
    font-weight: 450;
    line-height: 16px;
    color: rgba(118, 118, 118, 1);
}
.fulfilled_orders_content_line_num {
    font-size: 20px;
    font-weight: 650;
    line-height: 24px;
    color: rgba(48, 48, 48, 1);
}
.tooltip_new_orders {
    font-size: 13px;
    font-weight: 550;
    line-height: 20px;
    color: rgba(48, 48, 48, 1);
}
.achive_plan_content {
    padding: 16px;
}
.achive_plan_title {
    font-size: 14px;
    font-weight: 650;
    line-height: 20px;
    margin-bottom: 8px;
    text-align: left;
}
.achive_plan_list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.achive_plan_list_item {
    width: calc(100% / 2 - 6px);
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.13) inset;
    margin-bottom: 16px;
}
.achive_plan_list_item_title {
    font-size: 13px;
    font-weight: 650;
    line-height: 20px;
    text-align: left;
}
.achive_plan_list_item_dsc {
    font-size: 14px;
    font-weight: 450;
    line-height: 20px;
    text-align: left;
    margin: 16px 0;
}
.achive_plan_list_item_button {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.fulfilled_orders_content_line_title {
    padding: 8px 16px;
    border-radius: 8px;
    margin-right: 10px;
}
/* .fulfilled_orders_content_line_title:nth-child(1) {
    background-color: rgba(247, 247, 247, 1);
} */
.fulfilled_orders_content_line_title:hover {
    background-color: rgba(247, 247, 247, 1);
}
.fulfilled_orders_content_select button {
    min-width: 110px;
    justify-content: flex-start;
}
.ability_box_content_item {
    display: flex;
    align-items: center;
    position: relative;
}
.ability_box_content_item_close {
    position: absolute;
    right: 0;
    top: 0;
    display: none;
}
.ability_box_content_item_close button{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    padding: var(--p-space-100);
    -webkit-backdrop-filter: blur(var(--p-space-200));
    backdrop-filter: blur(var(--p-space-200));
    border-radius: var(--p-border-radius-200);
    background-color: #fff9;
    cursor: pointer;
    color: var(--p-color-icon-secondary);
    transition-property: background-color, opacity, color;
    transition-duration: var(--p-motion-duration-200);
    transition-timing-function: var(--p-motion-ease);
}
.ability_box_content_item:hover .ability_box_content_item_close {
    display: block;
}
.ability_box_content_item_l {
    flex: 1;
}
.ability_box_content_item_l_title {
    font-size: 14px;
    font-weight: 650;
    line-height: 20px;
    text-align: left;
}
.ability_box_content_item_l_dsc {
    font-size: 13px;
    font-weight: 450;
    line-height: 20px;
    max-width: 582px;
    margin: 16px 0;
}

.quick_start_content_box {
    margin-bottom: 16px;
}
.quick_start_content_box_title {
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 10px;
    overflow: hidden;
}
.quick_start_content_box_title:hover {
    background-color: #f6f6f6;
}
.quick_start_content_box_points {
    margin-left: 48px;
    margin-top: 10px;
}
.quick_start_content_box_points span {
    padding: 2px 8px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 550;
    border: 1px solid rgba(138, 138, 138, 1);
    background-color: #fff;
}
.quick_start_content_box_points span img {
    vertical-align: sub;
    margin-right: 4px;
}
.quick_start_content_right {
    margin-left: 48px;
}
.quick_start_content_box_text {
    margin: 16px 0;
}
.quick_start_content_box_button {
    display: flex;
    align-items: center;
}
.quick_start_content_box_button button:nth-child(1) {
    margin-right: 10px;
}
.quick_start_content_box_img {
    line-height: 1;
    margin: 10px 0;
    max-width: 220px;
}
.quick_start_content_box_img img {
    width: 100%;
}
.next_step_title {
    font-size: 20px;
    font-weight: 650;
    line-height: 24px;
    text-align: left;
}
.next_step_box {
    border-top: 1px solid rgba(211, 211, 211, 1);
    padding-top: 16px;
}
.next_step_button {
    width: 100%;
    margin-top: 16px;
}
.next_step_button button {
    width: 100%;
    height: 32px;
}
.fulfilled_orders_content_line_mobile {
    display: flex;
}
.fulfilled_orders_content_line_arrowup {
    flex: 1;
    text-align: right;
}
.ability_box_content_mobile {
    display: none;
}
.ability_box_content_mobile .ability_box_content_item_l_title  {
    line-height: 32px;
    flex: 1;
}
.ability_box_content_item_close_button button{
    appearance: none;
    border: none;
    padding: var(--p-space-100);
    backdrop-filter: blur(var(--p-space-200));
    border-radius: var(--p-border-radius-200);
    background-color: #fff9;
    cursor: pointer;
    color: var(--p-color-icon-secondary);
    transition-property: background-color, opacity, color;
    transition-duration: var(--p-motion-duration-200);
    transition-timing-function: var(--p-motion-ease);
}
@media screen and (max-width:600px) {
    .new_ui_title_h1 {
        margin-bottom: 8px;
    }
    .ability_box_content_mobile {
        display: block;
    }
    .ability_box_content_pc {
        display: none;
    }
    .ability_box_content_item_r {
        display: none;
    }
    .dashboard_page_box {
        width: 100%;
    }
    .new_plan_content {
        max-height: 100%;
        margin: 24px 16px;
        overflow: hidden;
    }
    .new_plan_content_list {
        flex-wrap: wrap;
    }
    .new_plan_content_list_item {
        width: 100%;
        border-bottom: 1px solid rgba(211, 211, 211, 1);
    }
    .achive_plan_list_item {
        width: 100%;
    }
    .proper_list_item {
        width: 100%;
        margin-bottom: 16px;
    }
    .manager_box_content_r {
        display: none;
    }
    .manager_box_content_l {
        width: 100%;
    }
    .manager_box_content_l_text p {
        flex-wrap: wrap;
    }
    .new_person_box_right_button {
        display: flex;
    }
    .manager_box_title div {
        display: flex;
    }
    .dashboard_title_button {
        flex-wrap: wrap;
    }
    .dashboard_title_button p {
        margin-top: 10px;
    }
    .dashboard_title {
        padding: 0 10px;
    }
    .new_person_box_text_n_step_mobile_close {
        display: none;
    }
    .fulfilled_orders_content_line_mobile .fulfilled_orders_content_line_item {
        scrollbar-color: rgb(181.181,181) rgba(0,0,0,.1); /* 滑块为橙色，轨道为灰色 */
        scrollbar-width: thin; 
        overflow-x: auto; 
        justify-content: flex-start;
        border-right: 1px solid #eee;
    }
    .fulfilled_orders_content_line_mobile .fulfilled_orders_content_line_title {
        width: calc(700px / 4);
    }
    
    .get_start_item_n_box_img {
        display: none;
    }
    .get_start_item_p_text span {
        white-space: nowrap;
    }
    .ability_box_content_item_close {
        display: block;
    }
    .get_start_item_p {
        align-items: flex-start !important;
    }
}

@media screen and (max-width:420px) {
    .dashboard_title_mobile h1 {
        font-size: 14px;
    }
    .dashboard_title_mobile .dashboard_title_mobile_span {
        display: none;
    }
    .new_person_box_text_n_step strong {
        zoom: 0.9;
    }
    .ability_box_content_mobile .ability_box_content_item_l_title {
        line-height: 1.2;
    }
    .ability_box_content_item_l_title {
        padding-top: 5px;
    }
    .ability_box_content_item_close_button {
        align-items: flex-start;
    }
    .manager_box_title h3 {
        margin-right: 10px;
    }
}
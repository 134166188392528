.Inventory_Product{
    padding: 10px 0;
}

.Inventory_Product .Add_Variant__Title{
    min-width: 200px;
    max-width: 15vw;
    word-break: break-all;
    white-space: normal;
    font-weight: 500;
    color: #202223;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.Inventory_Product .Add_Variant_Sku{
    padding-top: 5px;
    font-size: 14px;
    color: #202223;
}

.Cart_Box .Set_Quantity .Polaris-TextField{
    max-width: 160px;
    min-width: 150px;
}

.Cart_Modal_Content{
    padding: 0 1rem 2rem 0;
}

.Cart_Box.Add_Quantity {
    min-width: 600px;
}

.Cart_Box .padding-right-10{
    padding-right: 10px;
}

.Cart_Box.Add_Quantity th{
    padding-right: 15px;
}

.Cart_Modal_Content .cart__subtotal_header{
    position: sticky;
    z-index: 50;
    top: 0;
    padding: 2rem 3rem 1rem 0;
    background-color: white;
    text-align: right;
}






*{
    box-sizing:border-box;
}
body{
    overflow-x: hidden;
}

.Product_Search{
    width: 55%;
    float: left;
}

.ImportType_Search{
    width: 40%;
    float: right;
}

.Float_Clear{
    clear: both;
}

@media screen and (max-width: 501px){
    .Product_Search{
        width: 100%;
        float: unset;
    }
    .ImportType_Search{
        width: 100%;
        float: unset;
        padding-top: 15px;
    }
}

@media screen and (max-width: 768px){
    .Product__Status_Pushed .Product_Title,.Product__Status_Unpushed .Product_Title{
        padding: 10px 15px !important;
    }
    .Product__Status_Pushed .Product_Action, .Product__Status_Unpushed .Product_Action{
        padding: 10px 15px 25px 25px !important;
    }
}

@media screen and (min-width: 768px){
    .Product_Search{
        width: 55%;
    }
    .ImportType_Search{
        width: 35%;
    }
}

@media screen and (min-width: 1025px){
    .Product_Search{
        width: 55%;
    }
    .ImportType_Search{
        width: 30%;
    }
}
  
  @media screen and (min-width: 1201px){
    .Product_Search{
        width: 55%;
    }
    .ImportType_Search{
        width: 20%;
    }
}

.Product__Status_Pushed,.Product__Status_Unpushed,.Product__Status_NoAvailable, .Product__Status_Searching{
    margin-top: 20px;
    border: 2px solid transparent;
}

.Product__Status_Pushed .Polaris-Card, .Product__Status_Unpushed .Polaris-Card,.Product__Status_Searching .Polaris-Card  {
    padding: 5px;
}

.Product__Status_Pushed.Is_Checked, .Product__Status_Unpushed.Is_Checked {
    border: 2px solid #3f4eae;
    border-radius: 3px;
}

.Product__Status_Pushed{
    position: relative;
}

.Product__Status_Pushed .active::before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    border-top-left-radius: 3px;
    border: 1px solid #ccc;
    border-bottom: 25px solid transparent;
    border-left: 25px solid;
    border-right: 25px solid transparent;
    border-top: 25px solid;
    z-index: 0;
    color: #3f4eae;
}

.Product__Status_Pushed .active::after{
    position: absolute;
    font-weight: 400;
    font-size: 12px;
    top: -1px;
    left: -4px;
    color: #fff;
    transform: rotate(-41deg);
    z-index: 3;
}

.Product__Status_Pushed .active::after{
    content: 'imported';
}

.Product__Status_Pushed .Polaris-Stack,.Product__Status_Unpushed .Polaris-Stack, .Product__Status_Searching .Polaris-Stack{
    align-items: center;
}
.Product__Status_Pushed .Checkbox {
    padding: 0 16px 0 0;
    z-index: 10;
}
 .Product__Status_Unpushed .Checkbox,.Product__Status_Searching .Checkbox{
    padding: 0 12px 0 22px;
    z-index: 10;
}

.Product__Status_Pushed .Product_Img, .Product__Status_Unpushed .Product_Img,.Product__Status_Searching .Product_Img{
    padding: 5px 0;
}

.Product__Status_Pushed .Product_Title, .Product__Status_Unpushed .Product_Title, .Product__Status_Searching .Product_Title{
    padding: 10px 15px;
    font-size: 14px;
    width: 375px;
    max-width: 440px;
    word-break: break-all;
}

.Product_Title .Polaris-Button__Text{
    color: #212b36;
    font-weight: 600;
}

.Product_Title.Is_Show:hover {
    text-decoration: underline;
}

.Product_Title span{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

@media screen and (max-width: 1818px){
    .Product_Title{
        max-width: 350px !important;
    }
}

@media screen and (max-width: 1750px){
    .Product_Title{
        max-width: 350px !important;
    }
    .Product_Status_Badge{
        min-width: 80px !important;
    }
    .Product_Cost_Price{
        min-width: 180px !important;
    }
    .Product_Action.Button{
        padding: 10px 10px 10px 8px !important;
    }
}

@media screen and (max-width: 1601px){
    .Product_Title{
        max-width: 300px !important;
    }
    .Product_Status_Badge{
        padding: 0 !important;
        min-width: unset !important;
    }
    
    .Product_Action.Button{
        padding: 10px 5px !important;
    }
}

@media screen and (max-width: 1520px){
    .Product_Title{
        max-width: 350px !important;
    }
    .Product_Status_Badge{
        padding: 0 30px !important;
        min-width: unset !important;
    }
    .Product_Cost_Price{
        min-width: 200px !important;
    }
    .Product_Action.Responsive_Button{
        padding-left: 50px !important;
    }
}

@media screen and (max-width: 1441px){
    .Product_Title{
        max-width: 260px !important;
    }
    .Product_Status_Badge{
        padding: 0 5px !important;
        min-width: 50px !important;
    }
    .Product_Cost_Price{
        min-width: 180px !important;
    }
    .Product_Action.Button{
        padding: 5px 10px 5px 8px !important;
        display: block !important;
    }
    .Prodcut_More_Action .Polaris-Button{
        padding: 0.7rem 2rem !important;
    }
}

@media screen and (max-width: 1368px){
    .Product_Title{
        max-width: 242px !important;
    }
    .Product_Status_Badge{
        padding: 0 !important;
        min-width: unset !important;
    }
    .Product_Action.Button{
        padding: 5px !important;
    }
}

@media screen and (max-width: 1356px){
    .Product_Action.Button{
        display: inline-block !important;
    }
    .Product_Action.Responsive_Button{
        padding-left: 50px !important;
    }

    .Prodcut_More_Action .Polaris-Button{
        padding: 0.7rem 1rem !important;
    }
}

@media screen and (max-width: 1281px){
    .Product_Status_Badge{
        padding: 0 10px !important;
        min-width: unset !important;
    }
    .Product_Action.Button{
        padding: 5px 10px !important;
    }
    .Prodcut_More_Action .Polaris-Button{
        padding: 0.7rem 2rem !important;
    }

    .Product_Title{
        max-width: 265px !important;
    }
    .Product_Action.Responsive_Button{
        padding-left: 50px !important;
    }
}

@media screen and (max-width: 1239px){
    .Product_Cost_Price{
        min-width: 160px !important;
    }

    .Product_Status_Badge{
        padding: 0 2px!important;
        min-width: unset !important;
    }
}

@media screen and (max-width: 1210px){
    .Product_Action.Button{
        padding: 5px 15px 10px 8px !important;
        display: inline-block !important;
    }
    .Prodcut_More_Action .Polaris-Button{
        padding: unset;
    }
    .Product_Action.Responsive_Button{
        padding-left: 50px !important;
    }
}

@media screen and (max-width: 1188px){
    .Product_Title{
        max-width: 400px !important;
    }
    .Product_Cost_Price{
        min-width: 200px !important;
    }
    .Product_Status_Badge{
        padding-left: 50px!important;
        min-width: unset !important;
    }
}

@media screen and (max-width: 1067px){
    .Product_Title{
        max-width: 300px !important;
    }
    .Product_Cost_Price{
        min-width: 200px !important;
    }
    .Product_Status_Badge{
        padding-left: 50px!important;
        min-width: unset !important;
    }
}

@media screen and (max-width: 996px){
    .Product_Title{
        max-width: 300px !important;
    }
    .Product_Cost_Price{
        min-width: unset !important;
    }
}

@media screen and (max-width: 956px){
    .Product_Title{
        max-width: 450px !important;
    }
    .Product_Cost_Price{
        padding-left: 50px !important;
    }
}

@media screen and (max-width: 956px){
    .Product_Title{
        max-width: 350px !important;
    }
}

@media screen and (max-width: 810px){
    .Product_Title{
        max-width: 300px !important;
    }
    .Product_Status_Badge {
        padding-left: 0 !important;
        min-width: unset !important;
    }
}

@media screen and (max-width: 768px){
    .Product_Title{
        max-width: 310px !important;
    }
    .Product_Status_Badge{
        padding-left: 50px !important;
    }
    .Product_Cost_Price {
        padding-left: 10px !important;
    }
}

@media screen and (max-width: 710px){
    .Product_Title{
        max-width: 400px !important;
    }
    .Product_Status_Badge{
        padding-left: 10px !important;
    }
    .Product_Cost_Price {
        padding-left: 50px !important;
    }
}

@media screen and (max-width: 615px){
    .Product_Title{
        max-width: 330px !important;
    }
}
@media screen and (max-width: 551px){
    .Product_Title{
        max-width: 300px !important;
    }
    .Product_Status_Badge{
        padding-left: 0px !important;
    }
}

@media screen and (max-width: 501px){
    .Product_Title{
        max-width: 252px !important;
    }
    .Product_Cost_Price {
        min-width: 500px !important;
    }
    .Product_Status_Badge{
        padding-left: 50px !important;
    }
}

@media screen and (max-width: 376px){
    .Product_Title{
        max-width: 200px !important;
    }
    .Product_Action.Button {
        padding: 5px 5px 10px 22px !important;
    }
}

@media screen and (max-width: 321px){
    .Product_Title{
        max-width: 160px !important;
    }
    .Product_Status_Badge{
        padding: 0 10px !important;
    }
    .Product_Action.Button{
        padding: 8px 5px !important;
    }
}

.Product_Status_Badge{
    /* padding: 0 35px; */
    margin: auto 10px;
    min-width: 80px;
}

.Product_Status_Badge.attention .Polaris-Badge{
    background-color: #ffea8a;
    color: #595130;
}

.Product_Cost_Price{
    padding: 0 10px;
    margin: auto 10px;
    min-width: 60px;
}

.Product_Status_Badge.critical .Polaris-Badge{
    background-color: #fead9a;
}

.Product__Status_Pushed .Product_Action, .Product__Status_Unpushed .Product_Action, .Product__Status_Searching .Product_Action{
    padding: 10px 15px 10px 8px;
    display: inline-block;
}

.Product__Status_Pushed .Product_Action.Delete, .Product__Status_Unpushed .Product_Action.Delete{
    padding-right: 30px;
}

.Product_Action .Polaris-Button.Polaris-Button--disabled{
    border: none;
    cursor: not-allowed;
}

.Product_Action .Polaris-Button.Polaris-Button--primary .Polaris-ExceptionList__Description{
    color: #fff;
}
.Product_Action .Polaris-Button.Polaris-Button--primary svg{
    fill: #fff;
}
.Product_Action .Polaris-Button.Polaris-Button--primary .Polaris-ExceptionList__Icon, .Product_Action .Polaris-Button.Polaris-Button--disabled .Polaris-ExceptionList__Icon{
    margin-top: -2px;
}
.Product_Action .Polaris-Button{
    padding: 0.7rem 1rem;
}

.Product_Action img{
    vertical-align: bottom;
}

.emptyWithImg{
    position: relative;
    text-align: center;
}

.emptyWithImg .Polaris-EmptyState__Image{
    width: 120%;
    height: 200px;
    margin-left: -50%;
}
.emptyWithImg p{
    margin-bottom: 20px;
}
.emptyWithImg .Polaris-  {
    margin-left: 15%;
    text-align: left;
}

@media (max-width: 1080px){
    .emptyWithImg .Polaris-EmptyState__Image {
        margin-left: 0;
        width: 100%;
        margin-top: 1rem;
    }
    .emptyWithImg .Polaris-  {
        margin-left: 10%;
    }
}

.Product__Status_NoAvailable h2.Polaris-Heading{
    font-size: 15px;
}

.Product__Status_NoAvailable span.Polaris-Button__Text{
    height: 20px;
}

.Product__Status_Pushed__Warning{
    padding: 10px 20px;
}
.Product__Status_Pushed__Warning .Polaris-ButtonGroup {
    justify-content: flex-start !important;
}
.Product__Status_Unpushed__Warning{
    padding: 10px 0;
}

.Product__Loading{
    position: absolute;
    background-color: #fff;
    opacity: 0.5;
    top: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    z-index: 99;
}

.Product__Loading_Image{
    position: absolute;
    left: 50%;
    top: 5%;
}

.Product_Heading{
    display: flex;
    width: 100%;
    font-weight: 600;
    font-size: 1.4rem;
    margin: auto;
    padding: 1.4rem 0 0;
    transition: opacity .2s cubic-bezier(.64,0,.35,1);
}
.Product_Heading .Head_Checkbox{
    width: 4%;
    padding-left: 8px;
}
.Product_Heading .Head_Product{
    width: 38%;
    padding-left: 5%;
}
.Product_Heading .Head_Product_Price{
    width: 15%;
}
.Product_Heading .Head_Import_Status{
    width: 15%;
}
.Product_Heading .Head_Product_Status{
    width: 16%;
}
.Product_Heading .Head_Action{
    width: 22%;
    text-align: center;
}
@media screen and (max-width: 1024px){
    .Product_Heading .Head_Product{
        width: 30%;
        padding-left: 5%;
    }
    .Product_Heading .Head_Import_Status,.Product_Heading .Head_Product_Status{
        width: 20%;
    }
}

.Product_Override_Banner{
    padding: 1rem 2rem;
}
.Product_Override_Banner .Polaris-Banner--withinContentContainer{
    padding: 0.8rem 1rem;
}

.Product_Override_Banner .Polaris-Stack .Polaris-Stack__Item{
    background-color: #e2e2e2;
    flex: initial;
}

.Override_Banner_Title{
    font-weight: 500;
    font-size: 1.4rem;
    margin-bottom: 5px;
}

.Override_Product_Box{
    display: flex;
    padding: 8px 0;
    margin: 5px 0 2px 0;
    background-color: #e2e2e2;
    border-radius: 3px;
}

.Override_Image_Title{
    display: flex;
}
.Override_Product_Image{
    margin: auto 15px;
}
.Override_Product_Title{
    margin: auto 15px;
}

.Override_Product_Title a{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.Override_Product_Inventory{
    margin: auto 10px auto 0;
}

@media (max-width: 766px){
    .Override_Product_Box{
        display: inline;
    }
    .Override_Image_Title{
        padding: 8px 0;
    }
    .Override_Product_Inventory{
        padding: 5px 15px 10px;
    }
}

.Pagination_Result__Footer {
    padding-top: 1.6rem;
    background: #f4f6f8;
    color: #637381;
    text-align: center;
}

.order_override_table_row, .order_override_item_row,.order_override_new_item{
    display: flex;
}

.order_override_table_title{
    width: 100%;
    margin-bottom: 8px;
    font-weight: 600;
}

.order_override_old{
    width: 100%;
    margin-bottom: 8px;
    padding: 5px;
    background-color: #f6f6f9;
}

.order_override_item_row img{
    overflow: hidden;
    min-width: 40px;
    height: 40px;
    width: 40px;
    border-radius: 4px;
}

.order_override_new{
    width: 100%;
    margin: auto;
    margin-left: 10px;
    margin-top: 8px;
}

/* POD导流弹窗 */
.pod_modal_box{
    display: flex;
    margin: 2rem 1rem;
}
.pod_modal_left{
    flex: 1;
}

.pod_modal_title {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.4;
}

.pod_modal_left ul{
    padding: 0 20px;
}

.pod_modal_left ul li {
    list-style: disc;
    font-size: 15px;
    line-height: 32px;
}

.pod_modal_left .Polaris-Button{
    padding: 10px 30px;
    background-color: #f5520c;
    color: #fff;
    font-size: 16px;
}

.pod_modal_left .Polaris-Button:hover{
    background-color: #d14600;
}

.pod_modal_left{
    flex: 1;
}

.pod_modal_right{
    width: 40%;
}

.pod_modal_right img{
    width: 100%;
}

@media (max-width: 610px){
    .pod_modal_box{
        flex-direction: column;
    }

    .pod_modal_right{
        margin-top: 10px;
        width: 100%;
        text-align: center;
    }

    .pod_modal_right img{
        width: 80%;
    }
}

/* Vstar 引流banner */
.dropshipman_aliexpress_products .Polaris-Page-Header__PrimaryActionWrapper .Polaris-Button{
    padding: 0.7rem 1rem;
    border-radius: 0.8rem
}

.vstar_banner .vstar_content{
    display: flex;
    align-items: center;
}

.vstar_banner .vstar_content .vstar_title{
    margin-left: 5px;
    color: #000;
    font-weight: 600;
    font-size: 1.5rem;
}

.vstar_banner .vstar_content .Polaris-Icon{
    margin: -4px 0 0 0;
}

.vstar_banner .vstar_content .Polaris-Icon svg{
    width: 26px;
    height: 26px;
}

.vstar_banner .vstar_content .Polaris-Icon__Svg{
    max-width: unset;
    max-height: unset;
}

.vstar_banner img{
    width: 110px;
}

.bulk_edit_collections .Polaris-ChoiceList__Title,
.tag_title {
    font-size: 16px;
    font-weight: 500;
    padding: 0 0 10px 20px;
}

.bulk_edit_collections .Polaris-ChoiceList__Choices {
    width: 100%;
    display: flex;
    justify-tracks: center;
    flex-wrap: wrap;
    padding-left: 20px;
}

.bulk_edit_collections .Polaris-ChoiceList__Choices li {
    flex: 0 0 33.333333%;
}

.tag_content,
.tag_content_once {
    padding: 10px 0 0 20px;
}

.tag_content .Polaris-Stack__Item:last-child .Polaris-Tag {
    width: 120px;
    padding: 0 40px;
    justify-content: center;
    background-color: #FFF;
    border: 1px solid rgba(92, 95, 98, .5);
    color: rgba(92, 95, 98, .5);
}

.tag_content .Polaris-Stack__Item:last-child .Polaris-Tag:hover {
    border: 1px solid rgba(92, 95, 98, 1);
    color: rgba(92, 95, 98, 1);
}

.tag_content .Polaris-Stack > .Polaris-Stack__Item:not(:last-child),
.tag_content_once .Polaris-Stack > .Polaris-Stack__Item:not(:last-child) {
    max-width: calc(100% - 1.6rem);
}

.tag_content .Polaris-TextField input {
    width: 120px;
    padding: 6px 5px;
    height: 28px;
    min-height: 28px;
}

.modalHasIcon {
    display: flex;
    align-items: center;
    gap: 10px;
}

.modalHasIcon .Polaris-Icon {
    margin: 0 0;
}
.Page__Content {
  margin-top: 20px;
}
.Settings_Content {
  padding-top: 20px;
}

.Notification_Table table{
  width: 100%;
  border: none !important;
}

.Notification_Table table thead th{
  text-align: left;
  padding: 5px 0;
  border-bottom: 1px solid #ccc;
}


.Notification_Table table thead th:first-child{
  width: 50%;
}

.Notification_Table table thead th:last-child{
  text-align: center;
}

.Notification_Table .Polaris-Checkbox{
  cursor: pointer;
}

.Notify__Title{
  margin-bottom: 10px;
}

.Notify_Customers .Polaris-Choice{
  cursor: pointer;
}

.Notify_Customers .Polaris-Choice__Label{
  font-size: 13px;
  font-weight: 400;
}

.Notify__Title .Polaris-Icon__Svg {
  fill: rgb(129, 141, 154);
}

.Notification_Time{
  padding-top: 20px;
  border-top: 1px solid #ccc;
}

.Notification_Time_Sub{
  padding: 10px 0 5px;
}

.Notification_Time_Week{
  margin-right: 15px;
}

.Notification_Time_Send{
  margin-top: 10px;
}
.notify_settings_step_title {
  font-size: 14px;
  font-weight: 600;
  margin-top: 24px;
}
.notify_settings_step_section {
  margin-left: 16px;
}
.notify_settings_step_section .Polaris-Link--monochrome {
  color: #4383F8;
}
.notify_settings_step_track {
  font-size: 14px;
}
.notify_settings_step_section_email {
  margin-left: 16px;
  font-size: 14px;
}
/* 隐藏 成功或者失败提示的关闭按钮(x) */
/* .Polaris-Frame-Toast__CloseButton{
  display: none;
} */
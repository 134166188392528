.pie_page .Polaris-Page {
    margin-top: 5px;
}
.land_content {
    margin-bottom: 48px;
}
.land_top,.land_mid1,.land_mid2,.land_bottom{
    padding: 24px;
}
.land_top {
    background-color: rgb(254, 230, 222);
}
.land_top_t {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.land_top_t div {
    min-width: 100px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
}
.land_top_t_l {
    width: 168px;
}
.land_top_t_l img {
    max-width: 100%;
}
.land_top_m_l {
    width: 50%;
    padding-right: 24px;
}

.land_top_m {
    /* color: #fff; */
    display: flex;
    align-items: center;
}
.land_top_m_l_star {
    color: #757575;
}
.land_top_m_l_star span svg {
    vertical-align: text-top;
    margin-right: 5px;
}
.land_top_m_l_star .white {
    color: #2D2D2D;
    font-weight: 600;
}
.land_top_m_l_yellow p{
    color: #F85403;
    font-size: 16px;
    margin: 4px 0;
    font-weight: 600;
}
.land_top_m_l_title h1{
    line-height: 1.3;
    /* padding-right: 95px; */
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 24px;
}
.land_top_m_l_p {
    color:#797979;
}
.land_top_m_r {
    flex: 1;
}
.land_top_m_r img {
    max-width: 100%;
}
.land_top_b p{
    text-align: center;
    margin-top: 20px;
    color: #424242;
    font-weight: 600;
}
.land_top_b_title strong {
    color: #FF470D;
}
.land_top_b_partner {
    margin-top: 32px;
    margin-bottom: 48px;
}
.land_top_b_partner div img{
    height: 20px;
}
.land_top_b_partner div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.land_mid2 {
    background-color: #fff;
}

.land_mid1_t strong {
   background-image: linear-gradient(100.8deg, #FDA85A 20.79%,#FDA85A 32.61%, #FDA85A 45.86%, #F9774D 89.35%);
  -webkit-background-clip: text; /* Safari 兼容 */
  background-clip: text;
  color: transparent;
}
.land_content h2 {
    font-size: 20px;
    color: #202333;
    font-weight: 700;
    padding:0 20px;
    line-height: 1.3;
    text-align: center;
}
.land_mid1_b {
    margin-top: 24px;
    display: flex;
    align-items: center;
}
.land_mid1_b_l {
    width: 398px;
    padding-right: 24px;
}
.land_mid1_b_l img {
    width: 100%;
}
.land_mid1_b_r {
    flex: 1;
}

.land_mid1_b_list_item {
    cursor: pointer;
    margin-bottom: 10px;
}
.land_top_m_l_button.app_button {
    background-color: transparent;
    color: #0C112F;
    border: 1px solid #0C112F;
    border-radius: 8px;
    padding: 6px 16px;
    height: 45px;
    font-weight: 600;
}

.land_mid1_b_list_item:nth-last-of-type(1) {
    margin-bottom: 0;
}
.land_mid1_b_list_item_li {
    padding: 11px 12px;
    border: 1px solid #E2E2E2;
    border-radius: 12px;
}
.land_mid1_b_list_item_li.land_mid1_b_list_item_lis {
    border: 1px solid transparent;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, #fff, #fff),
    linear-gradient(to right,#FAD1AB, #FAEEE5 );
}
.land_mid1_b_list_item_li .land_mid1_b_list_item_title{
    color: #7F7F8D;
    font-size: 16px;
    font-weight: 700;
}
.land_mid1_b_list_item_lis .land_mid1_b_list_item_title {
    color: #202333;
    margin-bottom: 8px;
}
.land_mid1_b_list_item_li p{
    font-weight: 500;
    font-size: 13px;
    color:#89888D;
}
.land_mid1_b_l {}
.land_mid2_t h2 {
    padding: 0;
}
.land_mid2_b {
    display: flex;
    margin-top: 48px;
}
.land_mid2_b_item {
    padding: 48px 16px;
    width: calc(100% / 3 - 16px);
    text-align: center;
    margin-right: 30px;
    border-radius: 5px;
}
.land_mid2_b_item_icon {
    margin: 0 auto;
    width: 60px;
    height: 60px;
}
.land_mid2_b_item_icon img {
    max-width: 100%;
}
.land_mid2_b_item:nth-last-of-type(1) {
    margin-right: 0;
}
.land_mid2_b_item_title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 32px;
    margin-bottom: 8px;
}
.land_bottom_h2 h2 {
    padding: 0;
}
.land_bottom {
    text-align: center;
    padding-bottom: 24px;
    background-color: #FEE6DE;
}
.land_bottom_p {
    color: #5F5F5F;
    font-weight: 500;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 24px;
}
.land_bottom_botton {
    margin: 0 auto;
    width: 200px;
    padding: 12px 0;
    color: #fff;
    cursor: pointer;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0C112F;
}
.land_top_m_l_button {
    margin-top: 10px;
    width: 200px;
    padding: 12px 0;
    color: #fff;
    cursor: pointer;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0C112F;
}
.land_top_t_r {
    width: 200px;
    padding: 12px 0;
    color: #fff;
    cursor: pointer;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(12, 17, 47);
}

.land_mid1_b_c {
    display: none;
}
.land_mid1 {
    padding: 24px 24px;
    background-color: #fff;
}
@media screen and (max-width: 760px) {
    .land_top_t_r {
        width: 100px;
    }
    .land_top_m {
        flex-direction: column-reverse;
    }
    .land_top_m_l {
        width: 100%;
        padding-right: 0;
        margin-top: 24px;
    }
    .land_top_b_partner div {
        flex-wrap: wrap;
    }
    .land_top_b_partner div img {
        margin-bottom: 16px;
    }
    .land_mid1_b {
        display: none;
    }
    .land_mid1_b_c {
        display: block;
    }
    .land_content h2 {
        padding: 0;
    }
    .land_mid1_b_l {
        width: 100%;
        padding: 0;
    }
    .land_mid1_b_r {
        margin-top: 24px;
    }
    .land_mid2_b {
        display: block;
    }
    .land_mid2_b_item {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
    }
    .land_mid1_b_list_item_li.land_mid1_b_list_item_lis {
        background-image: linear-gradient(to right, #fff, #fff),
        linear-gradient(to right,#FAD1AB, #FAEEE5 );
    }
    .land_mid2_b_item {
        background: linear-gradient(0deg, #fff 0%, rgb(246, 248, 254) calc(100% - 0%));
    }
}
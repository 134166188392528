.payoneer_select_modal {
    min-height: 200px;
    padding:32px 24px;
    font-size: 16px;
}
.payoneer_select_icon {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    justify-content: center;
}
.payoneer_select_icon img {
    margin-right: 5px;
}
.payoneer_select_email {
    font-size: 24px;
    font-weight: 600;
    margin: 10px 0 24px;
}
.payoneer_select_amount , .payoneer_select_balances ,.payoneer_select_button{
    display: flex;
    justify-content: space-between;
}
.payoneer_select_amount , .payoneer_select_balances{
    margin: 16px 0;
}
.payoneer_select_button button {
    width: 50%;
    height: 40px;
}
.payoneer_select_button button:nth-child(1) {
    margin-right: 16px;
}
.payoneer_select_border {
    background-color: #fafafa;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 16px 10px;
}
.payoneer_select_balances {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 0 10px !important;
}
.payoneer_select_balances .Polaris-OptionList {
    width: 100%;
}
/* 缩略图大小配置 */
.image-carousel-thumbnail{
  width: 80px;
}

/* 全屏按钮大小 左右滑按钮大小配置 */
@media (min-width: 768px){
  .image-gallery-fullscreen-button .image-gallery-svg{
    height: 30px;
    width: 30px;
  }

  .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{
    height: 80px;
    width: 40px;
  }
}

/* 全屏看图层级设置 */
.image-gallery.fullscreen-modal{
  z-index: 999;
}

/*  全屏看图设置最大高度 默认 是 max-height: calc(100vh - 80px); */
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 160px);
}
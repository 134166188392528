.history_table .Polaris-IndexTable__TableHeading{
    text-align: center;
}
.history_table_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}
.history_table_title {
    font-size: 16px;
    font-weight: 700;
}
.history_top {
    display: flex;
    justify-content: space-between;
    margin: 16px;
}
.history_top_sku {
    display: flex;
    align-items: center;
}
.history_top_sku_inventory {
    text-align: center;
}
.history_table_item {
    padding: 16px 0;
}
.history_top_sku_img {
    margin-right: 16px;
    width: 60px;
    height: 60px;
    line-height: 1;
    box-shadow: 0rem 0.125rem 0.25rem rgba(31,33,36,.1), 0rem 0.0625rem 0.375rem rgba(31,33,36,.05);
    border-radius: 5px;
}
.history_top_sku_img img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
}
.history_top_sku_inventory_availabel {
    font-size: 30px;
    margin-top: 16px;
}
.history_table_export span {
    display: flex;
    align-items: center;
    border: 1px solid #999;
    padding: 3px 6px;
    border-radius: 5px;
    font-size: 12px;
    color: #999;
    cursor: pointer;
}
.history_table_export span img{
    width: 100%;
    height: 100%;
    margin-right: 5px;
}
.history_top_sku_box {
    display: flex;
    justify-content: space-between;
}
.history_top_sku_inventory {
    margin-right: 24px;
}
.history_top_sku_title p {
    max-width: 300px;
}
@media screen and (max-width: 500px) {
    .history_top {
       flex-wrap: wrap;
    }
    .history_top_sku_title {
        width: 100%;
    }
    .history_top_sku {
        flex: 1;
        width: auto;
    }
    .history_top_sku_box {
        margin-top: 16px;
        width: 100%;
    }
}
.ai_modal_optimized_box {
    background-color: rgba(243, 243, 243, 1);
    padding: 12px 8px;
    border-radius: 12px;
}
.ai_modal_optimized_icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}
.ai_modal_optimized_icon svg {
    fill: rgba(128, 81, 255, 1);
}
.ai_modal_optimized_text {
    flex:1;
    font-size: 20px;
    font-weight: 650;
    line-height: 24px;
    color: rgba(48, 48, 48, 1);
}

.list_as_product {
    flex:1;
    background-color: rgba(243, 243, 243, 1);
    padding: 8px 24px;
    border-radius: 12px;
    height: max-content;
}

.list_as_product_left_title {
    font-size: 13px;
    font-weight: 550;
    line-height: 20px;
    color: rgba(48, 48, 48, 1);
    margin-bottom: 4px;
}
.list_as_product_left_content p {
    font-size: 12px;
    font-weight: 450;
    line-height: 16px;
    color: rgba(118, 118, 118, 1);
}
.list_as_product_remind {
    height: 28px;
    margin: 8px 0;
}
.list_as_product_stock {
    height: 28px;
    margin-bottom: 8px;
}
.list_as_product_remind p ,.list_as_product_stock p{
    font-size: 13px;
    font-weight: 550;
    line-height: 20px;
    line-height: 1;
    color: rgba(48, 48, 48, 1);
}
.list_as_product_stock_link {
    font-size: 13px;
    font-weight: 450;
    line-height: 16px;
    color: rgba(118, 118, 118, 1);
}
.list_as_product_stock_link span {
    color: rgba(0, 91, 211, 1);
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
}
.organization_box {
    max-width: 120px;
    overflow: hidden;
}
.organization_box_text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.organization_box span {
    width: 100%;
    display: inline-block;
}
.product_cell_05_org_disabled button {
    color: rgba(118, 118, 118, 0.3);
    background-color: rgba(253, 253, 253, 1);
}
.product_cell_05_org_disabled button svg {
    fill: rgba(118, 118, 118, 0.3) !important;
}
.import_products_ali_express_country {
    position: relative;
  }
.import_products_ali_express_country_show {
    position: absolute;
    left: 12px;
    top: 6px;
    z-index: 999;
}
.product_selection_cate_content_item .item_text_add {
    white-space: wrap;
    word-wrap: break-word;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.product_selection_cate_content_item .item_text_adds {
    white-space: wrap;
    word-wrap: break-word;
    max-width: 250px;
}
.import_products_ali_express_country_input .Polaris-TextField__Input {
    font-size: 0;
}
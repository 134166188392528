/* start: Hover Button */
.hoverButton_Round{
  border-radius: 50%;
  cursor: pointer;
}

.total_Quantity_Circle_Round{
  position: absolute;
  top: -5px;
  right: -7px;
  text-align: center;
}
.total_Quantity_Round, .total_Quantity_Square{
  border-radius: 50%;
  padding: 3px;
  width: 23px;
  height: 23px;
  line-height:1.2;
}

.total_Quantity_Round.more, .total_Quantity_Square.more{
  padding: 4px;
  width: 30px;
  height: 25px;
}

.hoverButton_Square{
  cursor: pointer;
  box-shadow: -3px 1px 25px 3px #C0C0C0;
}

.total_Quantity_Circle_Square{
  position: absolute;
  top: -10px;
  left: -10px;
  text-align: center;
}
/* end: Hover Button */

/* start: Icon Svg settings */
svg:not(:root) {
  overflow: hidden;
}

.giraffly_Icon{
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  fill: currentColor;
}

.giraffly_Icon_website{
  display: inline-block;
  width: 26px;
  height: 26px;
  vertical-align: middle;
  fill: currentColor;
}
/* end: Icon Svg settings */
.hoverButton_Round_cart svg {
  width: 30px !important;
  height: 30px !important;
  max-width: 30px;
  max-height: 30px;
}
.hoverButton_Round_cart .Polaris-Icon {
  width: 30px !important;
  height: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cartbar_position .hoverButton_Round svg {
  fill:rgba(74, 74, 74, 1);
}
.cartbar_position .hoverButton_Round {
  border: 1px solid rgba(227, 227, 227, 1);
  box-shadow: none !important;
}
@media screen and (max-width: 500px){
  .cartbar_position{
    right: 23px !important;
    bottom: 200px !important;
  }
  .cartbar_position .hoverButton_Round {
    padding: 5px !important;
  }
  .hoverButton_Round_cart svg{
    width: 20px !important;
    height: 20px !important;
  }
}
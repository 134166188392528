.commone_content_list_item {
    height: 100%;
    border-radius: 8px;
    background-color: rgba(247, 247, 247, 1);
}
.product_tp_img {
    overflow: hidden;
    background-color: rgb(255, 255, 255);
    /* border-radius: var(--p-border-radius-200); */
    position: relative;
}
.product_img  {
    width: 100%;
    height: 0px;
    padding-bottom: 100%;
    position: relative;
}
.product_img img{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* border-radius: 6px; */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    opacity: 1;
    transform: scale(1);
}
.commone_content_list_item_bottom {
    padding: 16px;
    position: relative;
}
.commone_content_list_item_bottom .is_add_product {
    bottom: -2px;
    height: 32px;
    position: absolute;
    right: 0;
    width: 32px;
}
.commone_content_list_item_bottom .is_add_product img {
  width: 100%;
  height: 100%;
}
.commone_content_list_item_bottom .product_title {
    overflow: hidden;
   /* white-space: nowrap; */
   height: 40px;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2;
   text-overflow: ellipsis;
   word-break: keep-all;
  font-size: 13px;
  font-weight: 550;
  line-height: 20px;
  color: rgba(48, 48, 48, 1);
}
.product_invent {
  color: rgba(0, 91, 211, 1);
  margin-bottom: 4px;
}
.product_invent .item_text {
  color: rgba(0, 91, 211, 1);
}
/* .product_invent span {
  line-height: 1;
} */
.product_invent span.Polaris-Icon--toneBase {
  width: 16px;
  height: 16px;
}
.product_shopping ,.expected_profit ,.product_Lists ,.product_Review{
  font-size: 13px;
  font-weight: 550;
  line-height: 20px;
  color: rgba(48, 48, 48, 1);
}
.expected_profit {
  margin: 4px 0;
}
.product_Review {
  margin-top: 4px;
}
.product_invent svg {
  fill: rgba(181, 181, 181, 1);
}
.product_price_btn {
  margin: 8px 0;
}
.product_price_btn .btn_price{
    flex: 1;
    font-size: 16px;
    font-weight: 650;
    line-height: 24px;
}
.product_btn {
    margin-top: 8px;
    height: 32px;
    display: flex;
    align-items: center;
}
.product_btn button {
    height: 32px;
}
.commone_content_list_item_top {
    position: relative;
    cursor: pointer;
}
.product_commone_filter_button_item .Polaris-Button:active, .Polaris-Button[data-state='open'] {
    box-shadow: none;
    background-color: transparent;
}

.product_btn_mask {
    position: absolute;
    right: 8px;
    top: 8px;
    visibility: hidden;
}
.commone_content_list_item_top:hover .product_btn_mask {
    visibility: visible;
}
.product_tp_detail {
    display: flex;
    flex-direction: column;
}
.product_tp_collection {
    margin-top: 16px;
}
.product_commone_filter_button_item_collect {
    padding: 2px 8px 2px 12px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
    position: relative;
}
.product_commone_filter_button_item_collect.item_collect_active {
    background-color: rgba(254, 233, 232, 1);
} 
.item_collect_count {
    color: rgba(142, 31, 11, 1);
}
.product_commone_filter_button_item_collect span {
    font-size: 12px;
}
.product_commone_filter_button_item_collect_icon {
    margin: 0 0 0 5px;
}
.item_collect_icon_none svg{
    width: 16px;
}
.item_collect_icon_show svg{
    width: 16px;
}
.item_collect_icon_show svg path {
    fill: rgba(229, 28, 0, 1);
}
.product_tp_favorite_icon ,.product_tp_detail_icon{
    background-color: #000;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid transparent;
}
.product_tp_favorite_icon svg , .product_tp_detail_icon svg{
    fill:rgba(227, 227, 227, 1);
    width: 20px;
}
.product_tp_favorite_icon:hover {
    background-color: rgba(0, 0, 0, 0.3);
    border-color: rgba(138, 138, 138, 1);
}
.product_tp_favorite_icon:hover .product_tp_favorite_icon_show {
    display: none;
}
.product_tp_favorite_icon_hover {
    display: none;
}
.product_tp_favorite_icon:hover .product_tp_favorite_icon_hover {
    display: block;
}
.product_tp_favorite_icon:hover .product_tp_favorite_icon_hover svg path{
    fill: #fff;
}
.product_tp_favorite_icon_active svg ,.product_tp_favorite_icon_active svg path {
    fill: rgba(229, 28, 0, 1);
}
.product_tp_detail_icon:hover{
    background-color: rgba(0, 0, 0, 0.3);
    border-color: rgba(138, 138, 138, 1);
}
.product_tp_detail_icon:hover svg path{
    fill:#fff;
}
.product_commone_filter_box {
    position: relative;
}
.product_selection_cate_content {
    position: absolute;
    bottom: -300px;
    left: 8px;
    z-index: 1;
}
.product_selection_cate_content_search {
    border-bottom:1px solid rgba(235, 235, 235, 1);
    padding: 8px;
}
.product_selection_cate_content_list {
    padding: 8px;
}
.product_selection_cate_content_item {
    padding: 6px;
    margin: 2px 0;
    border-radius: 8px;
    cursor: pointer;
}
.product_selection_cate_content_item.cat_active {
    background-color: rgba(235, 235, 235, 1);
}
.product_selection_cate_content_item.current_active {
    background-color: rgba(235, 235, 235, 1);
    font-weight: 650;
}
.product_selection_cate_content_item:hover{
    background-color: rgba(235, 235, 235, 1);
}

.winning_logo {
    width: 256px;
    height: 54px;
    margin: 15px 12px;
    border-radius: 8px;
    background: linear-gradient(to right , rgba(254, 220, 205, 1),rgba(255, 255, 255, 1));
}
.winning_logo_title {
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    color: rgba(0, 0, 0, 1);
}
.winning_logo_title p {
    font-size: 12px;
    font-weight: 450;
    line-height: 16px;
}

.product_search_filter {
    flex: 1;
}

.first_time_popup_box {
    padding: 12px 22px;
    border-radius: 12px 0px 0px 0px;
    width: 214px;
    background: rgba(0, 91, 211, 1);
}
.first_time_popup_box div {
    word-break: keep-all;
    white-space: wrap;
    font-size: 13px;
    font-weight: 550;
    line-height: 20px;
}
.first_time_popup_box::after {
    content: "";
    position: absolute;
    bottom: 100%; /* 位于弹出框顶部 */
    left: 30%; /* 居中对齐 */
    transform: translateX(-50%);
    border-width: 8px; /* 箭头大小 */
    border-style: solid;
    border-color: transparent transparent rgba(0, 91, 211, 1) transparent; /* 三角形颜色 */
  }
  .first_time_popup_box_add_product::after {
    content: "";
    position: absolute;
    bottom: 64%;
    left: -3%;
    transform: translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: transparent rgba(0, 91, 211, 1) transparent transparent;
  }

  .disable-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 10px);
    height: 50%;
    background: transparent;
    z-index: 10;
    pointer-events: all;   /* 阻止鼠标事件 */
  }
  .product_Review {
    display: flex;
    align-items: center;
  }
  .disable_overlay_content {
    height: 320px;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* 渐变效果：从透明到不透明 */
  }
  .disable_overlay_content_mask {
    height: 150px;
    width: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 100%);
  }
  .disable_overlay_content_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    flex: 1;
    background-color: rgba(255, 255, 255, 1);
  }
  .disable_overlay_content_box .disable_overlay_content_price_discount{
    width: 48px;
    height: 20px;
    border-radius: 8px;
    background-color: rgba(224, 240, 255, 1);
    color: rgba(0, 82, 124, 1);
    padding: 2px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .disable_overlay_content_box .disable_overlay_content_price_discount strong{
    font-size: 12px;
    font-weight: 550;
    border-bottom: 1px dotted rgba(0, 82, 124, 1);
  }
  .disable_overlay_content_price_title {
    font-size: 13px;
    font-weight: 650;
    line-height: 20px;
    margin: 8px 0;
    color: rgba(48, 48, 48, 1);
  }
  .disable_overlay_content_price_dsc {
    text-align: center;
    font-size: 13px;
    font-weight: 450;
    line-height: 20px;
    color: rgba(97, 97, 97, 1);
    margin-bottom: 8px;
  }
  .disable_overlay_content_price_button button {
    width: 153px;
    padding: 6px 8px;
    height: 32px;
  }
  .disable_overlay_content_price_button button span {
    font-size: 13px;
    font-weight: 650;
  }
  .item_text {
    font-size: 13px;
    font-weight: 550;
    line-height: 20px;
    color: rgba(48, 48, 48, 1);
  }
  .market_empty {
    width: 624px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
.market_empty_container {
    width: 416px;
    margin: 0 auto;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(132px, 1fr));
}
  .market_empty_box {
    border-radius: 8px;
  }

  .market_empty_box_top {
    height: 132px;
    background-color: rgba(227, 227, 227, 1);
  }
  .market_empty_boxs {
    position: relative;
  }
  .market_empty_boxs_icon {
    position: absolute;
    right: -10px;
    top: 25%;
  }
  .market_empty_box_bottom {
    height: 92px;
    padding: 10px;
    background-color: rgb(247, 247, 247);
  }
  .market_empty > div {
    width: 100%;
  }
  .market_empty_box_top_desc {
    text-align: center;
    margin: 16px 0;
    font-size: 16px;
    font-weight: 650;
    word-break: break-word;
    line-height: 24px;
    color: rgba(118, 118, 118, 1);
  }
  .market_empty_box_top_desc strong {
    color: rgba(48, 48, 48, 1);
  }
  .market_empty_box_top_desc button span {
    font-size: 16px;
    font-weight: 650;
  }
  .market_empty_box_top_button {
    text-align: center;
  }
  .product_select_cate_box {
    margin-top: 10px;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
  .product_select_cate_box_item {
    padding: 8px 24px;
    border-radius: 12px;
    background-color: rgba(243, 243, 243, 1);
  }

.disable_overlay_content_price_discount .plan_none_desc {
  font-size: 13px;
  font-weight: 450;
  line-height: 20px;
  color:rgba(48, 48, 48, 1)
  }

  
.filter_button {
  min-width: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter_button button {
  height: 40px;
  min-width: 110px;
}
.price_range_find_content {
  width: 210px;
  min-height: 150px;
  padding: 16px;
}
.price_range_find_input {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.price_range_find_input div {
  width: 60px;
}
.price_range_find_input div input{
  width:100%;
  outline: 0;
  height: 30px;
  border: 1px solid #ebebeb;
  color: #686f77;
  border-radius: 5px;
}
.price_range_find_button {
 text-align: right;
}
.price_range_find_button button:nth-of-type(1) {
  margin-right: 10px;
}


/* 主盒子区域 */
.product_img_box {
  display: flex;
  position: relative;
  overflow: hidden;
  height: 0px;
  padding-bottom: 100%;
}
/* 每个区域 */
.product_img_region {
  flex: 1;
  height: 100%;
  cursor: pointer;
  padding-bottom: 100%;
  transition: background-color 0.3s ease;
}

.product_img_region.product_img_active {
  background-color: transparent;
}


/* Dot 点样式 */
.product_dots {
  gap: 10px;
  position: absolute;
  left: 50%;
  bottom: 0px;
  display: none;
  transform: translate(-50%, -20px);
}
.commone_content_list_item_top:hover .product_dots{
  display: flex;
}

.product_dots_item {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ddd;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.product_dots_item.product_dots_active {
  background-color: #6c6e71;
}
.infinite-scroll-component  {
  overflow: hidden !important;
}
.product_detail_bottom {
  display: none;
}
.product_commone_content_list {
  border-top: var(--p-border-width-025) solid var(--p-color-border-secondary);
}
.product_commone_content_list_item .Polaris-ShadowBevel {
  box-shadow: none;
}
.product_selection_country_content .Polaris-ShadowBevel {
  box-shadow: none;
}
@media screen and (max-width: 420px) {
  .product_commone_filter_button {
    width: 100%;
    display: flex !important;
    scrollbar-width: thin;
    overflow-x: auto !important;
  }
  
  .product_commone_filter_button_item {
    width: max-content;
  }
  .product_commone_content_list {
    padding: 10px !important;
  }
  .product_commone_content_list_item > div {
    border-radius: 8px;
    box-shadow: none;
    /* background-color: rgb(247, 247, 247); */
    --pc-shadow-bevel-content: 0;
    border: 1px solid rgba(26, 26, 26, 0.07);
  }
  .product_search_filter {
    width: 100%;
  }
  .product_search_filter input ,.sourcing_request_box input{
    font-size: 13px !important;
  }
  .product_btn_mask {
    visibility: visible !important;
  }
  .product_dots ,.product_img_box{
    display: none;
  }
  .product_selection_country_content > div {
    width: 250px !important;
  }
  .sourcing_request_head {
    padding: 10px !important;
  }
  .popup {
    padding: 20px 0 !important;
  }
  .popup_close_button {
    padding-right: 20px;
  }
  .market_empty {
    width: 100%;
  }
  .market_empty_container {
    width: 100%;
    display: flex;
    padding: 0 10px;
  }
  .market_empty_box {
    width: calc(100% / 3);
  }
  .market_empty_box_top_desc {
    padding: 10px !important;
  }
  .product_selection_cate_content {
    width: 100%;
    display: flex;
    scrollbar-width: thin;
    overflow-x: auto !important;
  }
  .product_selection_cate_content_box {
    width: 250px !important;
  }
  .disable_overlay_content_box {
    padding: 10px;
  }
  .sourcing_enough_quota_item_button button {
    width: 100%;
    height: 48px;
  }
  .product_review_box .Polaris-Layout__Section--oneThird {
    position: relative !important;
    top: 0 !important;
  }
  .product_detail_variant_cart_list {
    display: none;
  }
  .product_detail_bottom {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    padding: 21px 10px;
    background-color: #fff;
    box-shadow: 0 0 1px 1px rgba(204, 204, 204, 0.5);
  }
  .product_detail_bottom div {
    width: 100%;
  }
  .product_detail_bottom button {
    height: 48px;
  }
  .product_detail_bottom button:nth-child(2) {
    flex: 1;
  }
  .disable_overlay_content {
    height: 200px !important;
  }
  .disable_overlay_content_mask {
    height: 50px !important;
  }
  .first_time_popup_box {
    display: none;
  }
  .mobile_modal_cate {
    padding-top: 36px;
  }
  .product_selection_cate_content_box.product_selection_cate_content_box_mobile {
    width: 100% !important;
  }
}
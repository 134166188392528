.payoneer_confirm_modal .payoneer_select_icon{
    align-items: center;
    font-size: 20px;
    font-weight: 600;
}
.payoneer_confirm_modal .payoneer_select_icon img{
    margin-left: 10px;
    margin-right: 5px;
    width: 24px;
}
.payoneer_confirm_o_amount {
    text-align: center;
}
.payoneer_confirm_o_amount ,.payoneer_confirm_fx_rate , .payoneer_confirm_amount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
    padding: 15px 0;
}
.payoneer_confirm_checkbox {
    margin: 10px 0 24px;
}
.payoneer_confirm_button {
    display: flex;
    justify-content: flex-end;
}
.payoneer_confirm_button button:nth-child(1) {
    margin-right: 16px;
}
.packing_logo_content {
    display: flex;
    flex-wrap: wrap;
}
.packing_logo_content_left_title {
    display: flex;
    align-items: center;
}
.packing_logo_content_left_title strong {
    margin-right: 10px;
}
.packing_logo_content_left {
    width: 40%;
}
.packing_logo_content_right {
    display: flex;
}
.packing_logo_content_left_desc {
    margin-top: 16px;
}
.packing_logo_content_right .config_logo {
    display: flex;
}
.packing_logo_content_right .Sourcing_Image_Item {
    height: 100px;
    width: 112px;
    padding: 1px;
    margin-right:16px;
    overflow: hidden;
}

.packing_logo_content_right .imagePreview {
    height: 100%;
    border-radius: 5px;
    box-shadow: 0rem 0.125rem 0.25rem rgba(31,33,36,.1), 0rem 0.0625rem 0.375rem rgba(31,33,36,.05);
}
.purchase_Config_Logo__Image .Config_Logo__Image {
    right: 0px;
    top: 0px;
}

.packing_logo_content_right .config_logo .Polaris-Thumbnail--sizeLarge {
    width: 110px;
    height: 96px;
    overflow: hidden;
}
.packing_logo_content_right .Sourcing_Image_Item .image_remove__button {
    display: none;
    transform: translateY(-50px);
}
.packing_logo_content_right .Sourcing_Image_Item:hover .image_remove__button {
    display: block;
    animation: remove 0.3s ease-in-out forwards;
    
}
/* .purchase_list .Polaris-ShadowBevel {
    overflow: initial;
    z-index: auto;
    border-radius: 5px;
  } */
.Polaris-IndexTable__BulkActionsWrapper {
    z-index: 999 !important;
}
.purchase_list .Inventory_Product__Title.shopping_cart span {
    margin-right: 24px;
}
@keyframes remove {
    form {
        transform: translateY(-50px);
    }
    to {
        transform: translateY(0px);
    }
}
@media screen and (max-width: 600px) {
    .packing_logo_content_left {
        width: 100% !important;
        margin-bottom: 10px;
    }
}
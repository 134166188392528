.Page__Content {
  margin-top: 2rem;
}
.Settings_Content {
  padding-top: 2rem;
}
.PriceAddShippingFee_level .Polaris-Checkbox__Backdrop {
  background-color: #f3f3f3 !important;
}
.PricingRules__Item:last-child{
  padding-top: 3rem;
}

.PricingRules__Item .Polaris-Stack__Item:last-child {
  margin-top: 2rem;
}

.PricingRules__Item .Polaris-TextField__Input {
  width: 120px;
}

.PricingRules__Item .Polaris-Stack{
  padding-left: 20px;
}

.PricingRules__Label .Polaris-Choice{
  cursor: pointer;
}

.PricingRules__Label .Polaris-Choice__Label{
  font-size: 15px;
  font-weight: 500;
}

.PricingRules__Label .Polaris-Icon__Svg, .Cost_Range_Connector .Polaris-Icon__Svg{
  fill:rgb(129, 141, 154);
}

.Polaris-Checkbox__Icon .Polaris-Icon__Svg{
  fill:rgb(255, 255, 255);
}

.showMark .Polaris-Icon__Svg{
  fill: #b5c6da;
}

.PricingRules__Product_Cost .Polaris-Banner--withinContentContainer{
  padding: 10px 16px;
}


.Assign_Cents_Item{
  margin-top: -10px;
  margin-bottom: 15px;
}

.Assign_Cents_Item .Polaris-Choice{
  cursor: pointer;
}

.PricingRules__Product_Cost .Polaris-Banner--withinContentContainer{
  background-color: transparent;
}


.PriceRule_Table table{
  width: 100%;
  border: none !important;
}
.price_rule_list {
  display: flex;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0 0 0px 1px #E1E3E5;
}
.price_rule_list .price_rule_item {
  width: 160px;
  padding: 8px 0;
  display: flex;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
  cursor: pointer;
}
.price_rule_item-nl{
  width: 200px !important;
}
.price_rule_items {
  background: #303030;
  color: #fff;
}
@media screen and (max-width: 1025px){
  .PriceRule_Table{
    overflow-y: hidden;
  }
}
.PricingRules__Box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.PriceRule_Table table>tbody tr td {
  font-size: 14px;
  text-align: left;
  padding: 6px 5px 6px 0;
}

.PriceRule_Table table thead th{
  padding: 5px 0;
}

.PriceRule_Table table thead th{
  text-align: left;
  width: 30%;
}

.PriceRule_Table .Polaris-Choice{
  cursor: pointer;
}

.PriceRule_Cost_Range_Item{
  display: flex;
  /* width: 190px; */
}

.PriceRule_Cost_Range_Item .Polaris-TextField{
  width: 95px;
}

.PriceRule_Cost_Range_Item.Mark_Number .Polaris-TextField{
  width: 80px;
}

.PriceRule_Cost_Range_Item .Polaris-Connected__Item.Polaris-Connected__Item--connection{
  width: 100px;
}

.PriceRule_Cost_Range_Item .Polaris-TextField__Spinner{
  width: 1.8rem;
}


.Cost_Range_Connector{
  line-height: 2.3;
  margin: 0 5px;
}

.PriceRule_Delete{
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  padding: 0 5px;
}

.PriceRule_Error{
  padding-top: 10px;
}

.Intercom_Information_fill .Polaris-Link {
  color: #2c6ecb
}
@media screen and (max-width: 600px) {
  .price_rule_list .price_rule_item {
    width: 138px !important;
    font-size: 14px;
  }
  .PricingRules__Label div{
    font-size: 13px !important;
  }
}
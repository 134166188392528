/* 抽屉 */
.top_drawer_content {
    height: 54px;
    display: flex;
    position: sticky;
    top: 56px;
    z-index: 502;
    align-items: center;
    background: #000000;
    animation: topDrawer 0.5s ease-in-out alternate;
}
.top_drawer_contents {
    animation: topDrawers 0.5s ease-in-out alternate;
}
@keyframes topDrawer {
    from {
        transform: translateY(-54px);
    }
    to{
        transform: translateY(0px);
    }
}
@keyframes topDrawers {
    from {
        transform: translateY(0px);
    }
    to{
        transform: translateY(-54px);
    }
}
.top_drawer_content_title {
    font-size: 20px;
    color: #fff;
}
.top_drawer_content_item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}
.top_drawer_content_img img {
    width: 30px;
    margin-right: 32px;
    vertical-align: bottom;
}
.top_drawer_content_button {
    margin-left: 40px;
}
.top_drawer_content_close {
    margin-right: 20px;
}
.top_drawer_content_close img{
    width: 30px;
    vertical-align: bottom;
    cursor: pointer;
}
/* 悬浮按钮 */
.black_suspension {
    position: fixed;
    right: 0;
    z-index: 100;
    top: 280px;
}
.black_suspension_item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    
}
.shake {
    animation: alarm-2 .3s ease-in-out;
}
.suspension_box {
    position: relative;
}
.suspension_box img {
    width: 68px;
}
.suspension_box .suspension_close {
    position: absolute;
    right: 5px;
    top: 2px;
}
.suspension_close img {
    width: 14px;
}
.suspension_bcmf {
    margin-top: -20px;
    z-index: 10;
}
.suspension_bcmf img {
    height: 25px;
}
@keyframes alarm-2 {
    0% {
      transform: rotate(10deg);
    }
  
    25% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-10deg);
    }
    75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(10deg);
    }
}

/* 黑色星期五 */
/* 页面 */
.black_friday_content_header img {
    width: 100%;
}
.black_friday_content {
    border-radius: 10px;
    background-color: rgb(21, 21, 21,1);
    margin-bottom: 24px;
}
.black_mobile_banner {
    display: none;
}
.black_friday_content_footer {
    /* min-height: 750px; */
    /* background:url('/public/blackFriday/black_content_bg.png') center; */
    background-size: 101% 100%;
}
.friday_tab_content {
    display: flex;
    justify-content: center;
}
.friday_tab {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255,.1);
    border-radius: 26px;
    padding: 4px;
    /* border: 1px solid #FFFFFF33; */
}
.friday_tab_item {
    width: 160px;
    color: #fff;
    font-size: 13px;
    text-align: center;
    font-weight: 600;
    margin-right: 5px;
    cursor: pointer;
    border-radius: 32px;
    padding: 4px 22px 4px 22px;
}
.friday_tab_item:hover {
    color: #202300;
    background-color: #fff;
}
.friday_tab_item:nth-last-of-type(1) {
    margin-right: 0;
}
.friday_tab_item_col {
    color: #202300;
    background-color: #fff;
}
.black_friday_content_top_banner {
    display: flex;
    align-items: center;
    justify-content: center;
}
.black_friday_content_top_banner_box {
    color: #fff;
    max-width: 375px;
    position: relative;
    padding-top: 24px;
}
.black_friday_content_top_banner_box_title {
    font-family: Futura;
    font-size: 48px;
    font-weight: 700;
    line-height: 63.79px;
    text-align: center;
}
.black_friday_content_top_banner_box_desc {
    font-family: Poppins;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
}
.black_friday_content_top_banner_box_time {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin:5px 0 16px 0;
}
.black_friday_content_top_banner_box_img {
    position: absolute;
    right: -100px;
    top: 0;
    width: 120px;
    height: 120px;
}
.black_friday_content_top_banner_box_img img {
    width: 120%;
}
.black_friday_points_top {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}
.black_friday_points_top img {
    margin-right: 5px;
}
.black_friday_points_top div {
    display: flex;
    align-items: center;
    font-family: Inter;
    font-size: 12px;
    font-weight: 550;
    line-height: 16px;
    text-align: left;
    font-variation-settings: 'slnt' 0;
    margin-right: 10px;
}
.black_friday_points_line {
    text-decoration: underline;
    cursor: pointer;
}
.black_friday_points_bottom_prize_draw {
    margin: 16px 0;
}
.prize_draw_content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
}
.prize_draw_content_box {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-color: #fff;
}
.prize_draw_content_tip {
    text-align: center;
    position: relative;
    top: -5px;
}
.prize_draw_content_tip button {
    width: 160px;
    
}
.prize_draw_content_tip button span {
    font-family: Inter;
    font-size: 12px;
    font-weight: 650;
    line-height: 16px;
    text-align: left;
    font-variation-settings: 'slnt' 0;
}
.prize_draw_content_rule {
    margin-top: 16px;
}
.prize_draw_content_rule span {
    font-family: Inter;
    font-size: 12px;
    font-weight: 550;
    line-height: 16px;
    text-align: left;
    font-variation-settings: 'slnt' 0;
    text-decoration: underline;
    cursor: pointer;
}
.prize_draw_content_rule span:nth-child(1) {
    margin-right: 10px;
}
/* 订阅 */

.black_friday_content_footer_content {
    margin-top: 16px;
    color: #fff;
    padding-bottom: 24px;
}

.black_friday_subscribe_list {
    
    display: flex;
    align-items: center;
    justify-content: center;
}
.black_friday_subscribe::-webkit-scrollbar {
    display: none; /* 隐藏滚动条 */
}
.black_friday_subscribe_item {
    background-color: rgba(255, 255, 255, 0.1);
    width: 304px;
    height: 500px;
    padding: 20px 24px 20px 24px;
    position: relative;
    border: 1px solid transparent;
    /* box-shadow: 0px -1px 1px 0px #fff; */
    /* overflow: hidden; */
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, #252222, #252222), linear-gradient(180deg, rgba(255, 255, 255, 0.6), rgba(255,255,255,0));
    border-radius: 8px;
}
.black_friday_subscribe_item::before {
   
}
.black_friday_subscribe_item:hover{
    /* border: 1px solid transparent; */
    
    /* background-image: linear-gradient(to right, #222, #222), linear-gradient(180deg, #8F41E9, #578AEF); */
    /* background-image: linear-gradient(to right, #252222, #252222), linear-gradient(180deg, rgba(255, 255, 255, 0.6), rgba(0,0,0,.5)) */
    /* box-shadow: 0px -1px 2px 0px #fff; */
}
/* .black_friday_subscribe_item:hover .friday_subscribe_type_recommend {
    background-color: #FFFFFF33;
} */
.black_friday_subscribe_item:nth-child(1) {
    margin-right: 20px;
}
.black_friday_subscribe_item:nth-child(2) .friday_subscribe_button button{
    background-color: #fff;
    color: rgba(255, 111, 40, 1);
}
.friday_subscribe_type {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.friday_subscribe_type_name {
    font-size: 24px;
    font-weight: 600;
}
.friday_subscribe_type_recommend {
    padding: 6px 16px 6px 16px;
    border-radius: 27px;
    border:1px solid #FFFFFF33;
}
.friday_subscribe_price {
    display: flex;
    align-items: center;
    margin-top: 8px;
    height: 48px;
}
.friday_subscribe_symbol {
    color: #fff;
    font-size: 24px;
    margin-right: 5px;
    font-weight: 700;
}
.friday_subscribe_new_price {
    font-size: 40px;
    font-weight: 700;
}
.friday_subscribe_old_price {
    font-size: 16px;
    margin-left: 16px;
    color: rgba(255, 255, 255, 1);
    text-decoration: line-through;
    display: flex;
    align-items: center;
}
.friday_subscribe_old_price svg {
    fill: #fff;
    color: #fff;
}
.friday_subscribe_discount {
    margin: 5px 0 20px 0;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    border-radius: 16px;
    padding: 4px 8px 4px 8px;
    border-radius: 12px;
    background-color: rgba(255, 111, 40, 1);
}
.friday_subscribe_discount_num {
    /* color: #E63123; */
}
.friday_subscribe_button button{
    width: 100%;
    background-color: transparent;
    border-radius: 31px;
    padding: 10px 16px 10px 16px;
    text-align: center;
    cursor: pointer;
    border: 0;
    outline: 0;
    height: 40px;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.3);
}
.friday_subscribe_button button span {
    font-size: 14px;
}
.friday_subscribe_button button.Polaris-Button.Polaris-Button--disabled {
    background-color: #555555;
    color: #999;
}
.friday_subscribe_button button:hover {
    background-color: #fff;
    color: rgba(255, 111, 40, 1);
}
.friday_subscribe_dsc {
    margin-top: 20px;
}
.friday_subscribe_dsc ul {
    padding-left: 0;
    min-height: 231px;
}
.friday_subscribe_dsc ul li{
    display: flex;
    align-items: center;
    line-height: 21px;
}
.friday_subscribe_dsc ul li P {
    font-size: 14px;
}
.friday_subscribe_dsc ul li img{
    width: 16px;
    margin-right: 8px;
}
.friday_subscribe_details {
    display: flex;
    justify-content: center;
    align-items: center;
}
.friday_subscribe_details span {
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
    text-decoration: underline;
}
.friday_subscribe_details span:hover {
    color: #fff;
}
.prize_draw_content {
    position: relative;
}
.prize_draw_content_arrow {
    position: absolute;
    left: 50%;
    top: -16px;
    z-index: 0;
    height: 30px;
    transform: translateX(-50%);
}
.prize_draw_content_arrow img {
    height: 100%;
}
.wheel_pointer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(0, 0, 0);
    border-radius: 50%;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.wheel {
    position: relative;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    /* border: 5px solid #333; */
    overflow: hidden;
    transition: transform 5s ease-out;
    will-change: transform;  /* 提示浏览器优化旋转 */
    backface-visibility: hidden;      /* 隐藏背面内容，增加性能 */
    perspective: 1000;                /* 提升3D效果的性能 */
  }
  
  .wheel-segment {
    position: absolute;
    width: 50%;
    height: 50%;
    top: 0;
    left: 50%;
    transform-origin: 0% 100%;
    background-color: rgb(255, 138, 70);
    clip-path: polygon(0 100%, 100% 100%, 100% 0);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .segment-0 { transform: rotate(0deg); background-color: rgb(255, 255, 255); }
  .segment-1 { transform: rotate(45deg); background-color: rgb(255, 138, 70); }
  .segment-2 { transform: rotate(90deg); background-color: rgb(255, 255, 255); }
  .segment-3 { transform: rotate(135deg); background-color: rgb(255, 138, 70);}
  .segment-4 { transform: rotate(180deg); background-color: rgb(255, 255, 255); }
  .segment-5 { transform: rotate(225deg); background-color: rgb(255, 138, 70);}
  .segment-6 { transform: rotate(270deg); background-color: rgb(255, 255, 255); }
  .segment-7 { transform: rotate(315deg); background-color: rgb(255, 138, 70);}
  
  .wheel_item_text,.wheel_item_coupon,.wheel_item_plan {
    transform:rotate(-22.5deg); /* Adjust text rotation */
    color: #202300;
    font-weight: 500;
    font-size: 14px;
    padding-top: 74px;
  }
  .wheel_item_text {
    font-weight: 600;
  }
  .wheel_item_text {
    display: flex;
    flex-direction: column;
    /* zoom: .9; */
    font-size: 12px;
    padding-left: 25px;
  }
  .wheel_item_text img {
    display: inline-block;
    max-width: 60px;
    max-height: 60px;
  }
  .wheel_item_text_text {
    padding-left: 10px;
  }
  .wheel_item_text_text span{
    zoom: 1;
    font-size: 16px;
  }
  .wheel_item_coupon {
    position: relative;
    padding-top: 85px;
  }
  .wheel_item_coupon span {
    position: absolute;
    left: 33%;
    top:72%;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
  }
  .wheel_item_plan span{
    font-size: 12px;
    display: inherit;
  }
  .wheel_item_plan img {
    width: 30px;
   display: inline-block;
  }
  
  /* button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  } */
.raffle_rules_top {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 16px;
}
.raffle_rules_top span {
    font-size: 20px;
    font-weight: 600;
}
.raffle_rules_content {
    margin: 0 24px 32px;
}
.raffle_rules_content p {
    line-height: 32px;
    font-size: 14px;
    font-weight: 400;
}

.raffle_rules_modal p {
    font-size: 14px;
    text-align: left;
}
.raffle_coupon_container {
    position: relative;
    line-height: 1;
    margin: 16px 0 32px;
}
.raffle_coupon_container span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 60px;
    color: #fff;
    font-weight: 700;
}
.raffle_modal_footer {
    display: flex;
    justify-content: center;
    margin: 16px 0;
}
.raffle_modal_footer button {
    width: 160px;
    height: 40px;
}
.raffle_modal_footer button:nth-child(1) {
    margin-right: 10px;
}
.raffle_plan_container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.raffle_plan_container span {
    font-size: 30px;
    padding-top: 30px;
    font-weight: 700;
}
.black5_top_banner {
    background-color: rgb(21, 21, 21,1);
    border-radius: 12px;
}
.black5_top_banner_item {
    /* background: url('/public/blackFriday/black_content_bg.png') center; */
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 16px;
}
.top_drawer_content_title {
    position: relative;
}
.top_drawer_content_logo {
    width: 290px; /* 根据图片尺寸调整容器大小 */
    height: 50px;
    position: relative;
    overflow: hidden;
}

.top_drawer_content_logo .box {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    transition: opacity 0.5s ease;
}

.top_drawer_content_logo img:nth-child(1) {
    animation: toggleFirst 1s infinite;
}

.top_drawer_content_logo img:nth-child(2) {
    animation: toggleSecond 1s infinite;
}

@keyframes toggleFirst {
    0%, 50% {
        opacity: 1;
        visibility: visible;
    }
    50.1%, 100% {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes toggleSecond {
    0%, 50% {
        opacity: 0;
        visibility: hidden;
    }
    50.1%, 100% {
        opacity: 1;
        visibility: visible;
    }
}
.top_drawer_content_desc_time {
    margin: 8px 0;
}
.top_drawer_content_desc p {
    font-family: Poppins;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin: 10px 0;
}
.black5_top_banner_item_button {
    order: 1;
}
.black5_top_banner_item_button button{
    width: 160px;
}
.black5_top_banner_item_button_show {
    margin-top: 5px;
}
.top_drawer_content_off {
    position: absolute;
    right:-120px;
    top: -16px;
    width: 120px;
    height: 120px;
}
.top_drawer_content_off img{
    width: 100%;
}
.black5_top_banner_item:hover .black5_top_banner_item_close {
    display: block;
}
.black5_top_banner_item_close {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    display: none;
}
.black5_top_banner_item_close img{
    width: 30px;
}
.raffle_bg_top {
    position: absolute;
    bottom: -16px;
    left: 50%;
    z-index: 0;
    transform: translateX(-50%);
}
.black5_top_banner_item_sub {
    /* background: url('/public/blackFriday/black_content_bg.png') center; */
    background-size: cover;
    display: flex;
    align-items: center;
    padding: 0 24px;
    line-height: 1;
    color: #fff;
    position: relative;
    overflow: hidden;
}
.top_drawer_content_title_sub {
    text-align: left;
    padding: 16px 0;
}
.top_drawer_content_title_sub h3 {
    font-family: Futura;
    font-size: 42px;
    font-weight: 700;
    line-height: 55.82px;
}
.top_drawer_content_title_sub p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    width: 320px;
}
.top_drawer_content_off_sub {
    position: absolute;
    right: -8px;
    top: 0;
    width: 163px;
    height: 163px;
}
.top_drawer_content_off_sub img {
    width: 120%;
}
.top_drawer_content_time_sub {
    display: flex;
    align-items: center;
    margin-left: 24px;
}
.top_drawer_content_time_sub span {
    font-family: Futura;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    text-align: left;
}
.time_sub_l,.time_sub_r {
    padding: 24px 15px;
    border-radius: 16px;
    background: linear-gradient(180deg, #6D6D6D 0%, #434343 100%);
}
.time_sub_line {
    width: 24px;
    height: 2px;
    margin: 0 16px;
    background-color: #fff;
}
.raffle_bg_top_sub {
    position: absolute;
    right: 60px;
    bottom: -10px;
}
.raffle_log_table {
    padding: 24px 16px 0;
}
.raffle_log_head {
    background-color: rgba(246, 246, 247, 1);
}
.raffle_log_content {
    max-height: 500px;
    overflow: auto;
    position: relative;
}
.pageination_container_black {
    position: sticky;
    left: 50%;
    bottom: 0;
    z-index: 10;
    align-items: center;
    height: 60px;
    margin: 0 !important;
    background: #fff;
    text-align: center;
}
.raffle_log_head ,.raffle_log_content_item{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
    padding: 10px 0;
}
.raffle_log_content_item:hover {
    background-color: rgba(246, 246, 247, 1);
}
.raffle_log_date ,.raffle_log_action {
    width: 25%;
    text-align: center;
}
.raffle_log_prize {
    width: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.raffle_log_coupon_img ,.raffle_log_plan_img,.raffle_log_mouse_img{
    position: relative;
    width: 100px;
}

.raffle_log_table {
    min-height: 300px;
}
.raffle_log_coupon_img span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-weight: 600;
    font-size: 16px;
}
.raffle_log_prize img {
    max-height: 40px;
}
.black_friday_points_bottom {
    position: relative;
}
.black_friday_points_rotating-text {
    color: #fff;
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translate(0,-50%);
}
.black_friday_points_rotating-text .rotating-text{
    margin: 0;
}
.lottery-container {
    text-align: center;
  }
  
  .marquee-window {
    width: 220px;
    height: 150px; /* 控制显示3个奖品的高度 */
    overflow: hidden;
    /* border: 2px solid black; */
    margin: 0 auto;
    position: relative;
  }
  
  .marquee-content {
    display: flex;
    flex-direction: column;
    transition: transform 0.5s ease-in-out; /* 平滑过渡 */
  }
  
  .marquee-item {
    /* padding: 10px; */
    text-align: center;
    font-size: 14px;
    /* background: #f0f0f0; */
    /* margin: 5px 0; */
    height: 50px; /* 每个奖品高度固定 */
  }
/* 优惠券 */
.black_friday_coupon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 40px;
}
.black_friday_coupon_list {
    max-width: 960px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.black_friday_coupon_item , .black_friday_coupon_items {
    width: 310px;
    height: 152px;
    margin-right: 15px;
    background: url('/public/blackFriday/subtract_coupon.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 8px;
    margin-bottom: 15px;
    background-origin: border-box;
    border: 1px solid transparent;
}

.black_friday_coupon_items .black_friday_coupon_foot_button button {
    color: rgba(255, 255, 255, 0.48);
    background-color: #afafaf33;
}
.black_friday_coupon_items:hover .black_friday_coupon_foot_button button{
    border: 1px solid rgba(255, 255, 255, 0.3);
}
.black_friday_coupon_item:hover .black_friday_coupon_head , .black_friday_coupon_items:hover .black_friday_coupon_head{
    border: 1px solid transparent;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to top, transparent, #252222), linear-gradient(180deg, #575757, transparent);
    border-radius: 8px;
}
.black_friday_coupon_item:hover .black_friday_coupon_foot_button button {
    background-color: #E63123;
    border: 1px solid rgba(255, 255, 255, 0.3);
}
.black_friday_coupon_head {
    border: 1px solid transparent;
    height: 71px;
    line-height: 44px;
    padding: 15px 19px 0 19px;
}
.black_friday_coupon_item:nth-child(3n) ,.black_friday_coupon_items:nth-child(3n) {
    margin-right: 0;
}
.black_friday_coupon_foot_bar .Polaris-ProgressBar__Animated {
    background-color: rgba(230, 49, 35, 1);
}
.black_friday_coupon_head_price {
    font-size: 24px;
    font-weight: 700;
}
.black_friday_coupon_head_dsc {
    margin-left: 5px;
    color: rgba(255, 255, 255, 0.48);
}
.black_friday_coupon_foot {
    height: 80px;
    margin: 0 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-top: 1px dashed rgba(255, 255, 255, 0.2);
    border-spacing: 5px 10px; */
}
.black_friday_dash {
    width: 100%;
    overflow: hidden;
}
.black_friday_dash .dash{
    width: 91%;
    height: 1px;
    background-image: linear-gradient( to right, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 50%, transparent 50% );
    margin: 0 16px;
    background-size: 12px 10px;
    background-repeat: repeat-x;
}
.black_friday_coupon_foot_bar .black_friday_coupon_foot_num {
    margin-top: 8px;
    display: inline-block;
}
.black_friday_coupon_foot_button button {
    padding: 6px 16px 6px 16px;
    color: #fff;
    border-radius: 31px;
    outline: none;
    border: 1px solid transparent;
    background-color: #FFFFFF33;
    cursor: pointer;
}

.black_friday_coupon_notes {
    margin-top: 16px;
    display: flex;
    width: 960px;
}
.black_friday_coupon_note_title {
    margin-bottom: 5px;
    font-size: 16px;
    color: #fff;
}
.black_friday_coupon_note .black_friday_coupon_note_tip{
    color: rgba(255, 255, 255, 0.72);
}
.confirm_head {
    font-size: 18px;
    padding: 10px 0 0px 16px;
}
.confirm_button {
    min-height: 44px;
    padding: 8px 6px;
}


/* 合作联盟 */
.black_friday_cooperate {
    /* padding-bottom: 40px; */
}
.black_friday_cooperate_content {
    display: flex;
    justify-content: center;
}
.black_friday_cooperate_list {
    max-width: 820px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.black_friday_cooperate_item {
    width: 254px;
    height: 176px;
    margin-right: 17px;
    margin-bottom: 16px;
    border-radius: 8px;
    padding: 16px 16px 0;
    color: #202300;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid transparent;
    /* box-shadow: 0 -1px 1px 1px #fff; */
}
.black_friday_cooperate_item:hover {
    border: 1px solid transparent;
    /* background-clip: padding-box, border-box;
    background-origin: padding-box, border-box; */
    /* background-image: linear-gradient(to top, #222, #222), linear-gradient(180deg, #8F41E9, #578AEF); background-image: linear-gradient(to right, #252222, #252222), linear-gradient(180deg, #fff, rgba(0,0,0,.5)) */
    /* background-image: linear-gradient(to right, #252222, #252222), linear-gradient(180deg, rgba(255, 255, 255, 0.6), rgba(0,0,0,.5)) */
    /* box-shadow: 0px -1px 2px 0px #fff; */
}
.black_friday_cooperate_item:hover .black_friday_cooperate_item_bottom span {
    /* background-color: #E63123; */
}
.black_friday_cooperate_item:nth-child(3n) {
    margin-right: 0;
}
.black_friday_cooperate_item_top_icon img{
    width: 48px;
    border-radius: 5.6px;
}
.black_friday_cooperate_item_top {
    display: flex;
    align-items: center;
    height: 60px;
}
.black_friday_cooperate_item_top_title {
    font-size: 16px;
    font-weight: 600;
    margin-left: 16px;
}
.black_friday_cooperate_item_mid {
    margin: 5px 0;
    min-height: 48px;
    color: #202300;
    line-height: 16px;
    font-size: 12px;
    word-break: keep-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.black_friday_cooperate_item_bottom span {
    cursor: pointer;
    color: #fff;
    display: inline-block;
    padding: 6px 16px 6px 16px;
    border-radius: 31px;
    background-color: #202300;
    border: 1px solid rgba(255, 255, 255, 0.3);
}
.black_friday_cooperate_list {
    max-height: 450px;
    overflow-y: auto;
}
.black_friday_cooperate_list::-webkit-scrollbar {
    display: none; /* 隐藏滚动条 */
}
/* .black_friday_content_top_sticky {
    position: sticky;
    top: 55px;
    background-color: rgb(21, 21, 21);
    z-index: 90;
    border-radius: 10px;
}
.black_friday_content_top_sticky_app {
    position: sticky;
    top: 159px;
    background-color: rgb(21, 21, 21);
    z-index: 90;
    border-radius: 10px;
} */
.black_discount_modal_desc {
    text-align: center;
    font-size: 22px;
    margin: 0 110px;
    line-height: 1.2;
    color: #fff;
}
.black_discount_modal_price {
    text-align: center;
    color: #fff;
    height: 70px;
    line-height: 70px;
    margin: 16px 0;
    position: relative;
}
.black_discount_modal_price_inline {
    position: absolute;
    right: 25%;
    top: 64px;
    font-size: 18px;
    margin-left: 16px;
    display: inline-flex;
    flex-direction: column;
    line-height: 22px;
    zoom: 0.95;
}
.black_discount_modal_price strong {
    font-size: 60px;
    font-style: oblique 10deg; 
}
.black_discount_modal_time {
    width: 50px !important;
    font-size: 20px !important;
    padding: 12px 3px !important;
    color: #000;
    font-weight: 650 !important;
}
.black_discount_modal_conetent_button button {
    width: 250px !important;
    padding: 8px 16px !important;
}

.upgrade_new_feature_black .common_modal_close {
   display: none;
}
.upgrade_new_feature_black .common_modal_content {
    max-height: 100vh;
}
.upgrade_new_feature_black  .agent_modal .common_modal_content{
    max-height: 100vh !important;
}
.upgrade_new_feature_black .feed_option_button button {
    width: 120px;
    height: 40px;
}
@media screen and (max-width:1600px) {
    .black_friday_subscribe {
        /* padding-bottom: 40px; */
        /* max-height: 450px;
        overflow-y: auto; */
    }
    .friday_tab_item {
        font-size: 12px !important;
    }
}
@media screen and (max-width:600px) {
    .black5_top_banner_item .black5_top_banner_item_close {
        display: block;
    }
    .black_discount_modal_price_inline {
        position: absolute;
        right: 50px;
        top: 54px;
        font-size: 10px;
       
    }
    .upgrade_new_feature_black  .agent_modal .common_modal_content{
        max-height: 100vh !important;
    }
    .black_discount_modal_desc {
        font-size: 20px;
        margin: 0 16px;
    }
    .black_discount_modal_price strong {
        font-size: 40px;
    }
    .plan_upgrade_conetent_button {
        width: 100% !important;
    }
    .plan_upgrade_conetent_button button {
        width: 100% !important;
    }
    .top_drawer_content_off_sub {
        right: 30%;
        width: 120px;
        height: 120px;
    }
    .top_drawer_content_title_sub h3 {
        font-size: 30px;
    }
    .top_drawer_content_title_sub p {
        font-size: 16px;
        width: auto;
    }
    .top_drawer_content_off_sub img {
        width: 100%;
    }
    .top_drawer_content_time_sub span {
        font-size: 18px;
    }
    .black5_top_banner_item_sub {
        display: block;
        padding: 0 16px;
    }
    .top_drawer_content_time_sub {
        margin-left: 0;
        justify-content: center;
        margin-bottom: 30px;
    }
    .raffle_bg_top_sub  {
        position: absolute;
        right: 50%;
        bottom: 75px;
        transform: translateX(50%);
    }
    .top_drawer_content_title_sub {
        margin-top: 70px;
        text-align: center;
    }
    .top_drawer_content_title_sub p {
        text-align: center;
    }
    
    .raffle_modal_footer button {
        width: auto;
    }
    .top_drawer_content {
        padding: 0 10px;
    }
    .top_drawer_content_title {
        font-size: 14px;
    }
    .top_drawer_content_img img {
        width: 24px;
        margin-right: 24px;
    }
    .top_drawer_content_button {
        margin-left: 0;
    }
    .top_drawer_content_close {
        margin-left: 10px;
        margin-right: 0;
    }
    .black_friday_content {
        padding: 16px;
        border-radius: 0;
        overflow: hidden;
        /* background-color: #fff; */
    }
    .black_friday_content_header .black_friday_content_banner {
        display: none;
    }
    .black_mobile_banner {
        display: block;
    }
    .black_friday_content_footer {
        /* background: #fff; */
    }
    .black_friday_points_rotating-text {
        position: relative;
        right: 0% !important;
        top: 0 !important;
        transform: translate(0,0);
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 16px;
    }
    .black_friday_content_top_banner {
        justify-content: flex-start;
    }
    
    .black_friday_content_top_banner_box_desc {
        font-size: 18px;
    }
    .friday_tab_content {
        padding-top: 16px;
        overflow-x: auto;
        overflow-y: hidden;
        justify-content: flex-start;
    }
    .friday_tab {
        /* background-color: #fff; */
        width: 100%;
    }
    .friday_tab_item {
        width: auto;
        white-space: nowrap;
        color: #fff;
        font-size: 13px;
        margin-right: 5px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        /* border-radius: 0; */
        padding: 10px 5px 10px 5px;
        overflow: hidden;
    }
    .friday_tab_item:hover {
        background-color: #fff;
    }
    .black_friday_content_top_banner_box_title {
        font-size: 36px;
        text-align: left;
    }
    .black_friday_content_top_banner_box_time {
        font-size: 16px;
        text-align: left;
    }
    .black_friday_content_top_banner_box_img {
        right: -30px;
        top: -16px;
    }
    .friday_tab_item_col {
        color: #202300;
        background-color: #fff;
        font-weight: 600;
        /* border-bottom: 2px solid; */
        border: 0;
    }
    .black_friday_subscribe_list {
        flex-direction: column;
    }
    .black_friday_subscribe_item {
        width: 100%;
        background-color: rgba(37, 34, 34, 1);
    }
    .black_friday_subscribe_item:nth-child(1) {
        margin-right: 0;
        margin-bottom: 10px;
    }
    .black_friday_coupon_item, .black_friday_coupon_items {
        margin-right: 0;
        padding: 0;
        width: 100%;
    }
    .black_friday_coupon_notes {
        width: 100%;
        color: #202300;
    }
    .black_friday_coupon_note_title {
        font-weight: 600;
    }
    .black_friday_coupon_note .black_friday_coupon_note_tip {
        color: #202300;
    }
    .black_friday_cooperate_item {
        width: 100%;
        margin-right: 0;
    }
    .top_drawer_content_logo {
        margin-top: 60px;
       
    }
    .top_drawer_content_off {
        right: 30%;
    }
    .top_drawer_content_desc p {
        font-size: 16px;
    }
    .black5_top_banner_item_button {
        width: 100%;
    }
    .black5_top_banner_item_button button {
        width: 100%;
    }
    .pageination_container_black span {
        font-size: 11px;
    }
    .friday_tab_item:nth-child(2) {
        flex: 1.5;
    }
    .black5_top_banner_show {
        border-radius: 0;
    }
    .raffle_bg_top {
        bottom: 45px;
    }
    .raffle_bg_top img {
        height: 100px;
    }
    .black_friday_subscribe {
        /* padding-bottom: 40px; */
        max-height: 100%;
        overflow-y: auto;
    }
    .black_friday_cooperate_list {
        max-height: 100%;
    }
}

@media screen and (min-width:767px) and (max-width:1023px) {
    .black_friday_points_rotating-text {
        position: relative;
        right: 0% !important;
        top: 0 !important;
        transform: translate(0,0);
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 16px;
    }
    .top_drawer_content_title_sub h3 {
        font-size: 24px;
    }
    .top_drawer_content_title_sub p {
        font-size: 14px;
        width: 220px;
    }
    .top_drawer_content_off_sub {
        width: 100px;
        height: 100px;
    }
    .top_drawer_content_time_sub {
        margin-left: 0;
    }
    .time_sub_l, .time_sub_r {
        padding: 6px 10px;
    }
    .top_drawer_content_time_sub span {
        font-size: 16px; 
    }
    .black_friday_coupon_notes {
        width: 100%;
    }
    .top_drawer_content_title {
        font-size: 16px;
    }
    .black_friday_content_footer_content {
        padding: 0 16px 16px 16px;
    }
    .black_friday_coupon_item, .black_friday_coupon_items {
        width: calc(100% / 2 - 10px);
        margin-right: 5px;
    }
    .black_friday_coupon_item:nth-child(3n), .black_friday_coupon_items:nth-child(3n) {
        margin-right: 5px;
    }
    .black_friday_coupon_item:nth-child(2n - 1) {
        margin-right: 15px;
    }
    .black_friday_cooperate_item {
        width: calc(100% / 2 - 10px);
        margin-right: 0;
    }
    .black_friday_cooperate_item:nth-child(2n - 1) {
        margin-right: 15px;
    }
    
}
@media screen and (min-width:1024px) and (max-width:1365px) {
    .black_friday_coupon_notes {
        width: 100%;
    }
    .black_friday_content_footer_content {
        padding: 0 16px 16px 16px;
    }
    .black_friday_coupon_item, .black_friday_coupon_items {
        width: calc(100% / 3 - 10px);
    }
    .black_friday_coupon_foot_bar > div{
        width: 100px !important;
    }
    .black_friday_cooperate_item {
        width: calc(100% / 3 - 20px);
    }
}
@media screen and (max-width:320px) {
    .black_discount_modal_price_inline {
        position: absolute;
        right: 16px;
        top: 54px;
        font-size: 10px;
       
    }
    .black5_top_banner {
        border-radius: 0;
    }
    .top_drawer_content_logo {
        width: 160px;
        height: 24px;
    }
    .top_drawer_content_off {
        right: 20px;
    }
    .top_drawer_content_logo .box {
        width: 100%;
    }
    .friday_tab_item:nth-child(2) {
        flex: 1.5;
    }
    .black_friday_points_top_line {
        display: none;
    }
    .black_friday_content_top_banner_box_title {
        font-size: 24px;
    }
    .prize_draw_content .wheel {
        width: 250px;
        height: 250px;
    }
    .black_friday_content_top_banner_box_desc {
        text-align: left;
        font-size: 16px;
    }
    .black_friday_content {
        padding: 5px;
    }
    .black_friday_content_top_banner_box_img {
        top: -5px;
        right: -10px;
    }
    .time_close_box .sub_paid_time_detail_list_modal {
        padding: 0 !important;
    }
    .friday_tab_item {
        font-size: 12px;
        zoom: 0.9;
    }
    .wheel-segment {
        zoom: 0.85;
    }
    .raffle_log_table {
        padding:  24px 0;
    }
    .pageination_container_black {
        margin: 0;
        padding: 10px;
    }
    .pageination_container_black span {
        font-size: 10px;
        zoom: 0.9
    }
}

.datepick_search {
  display: flex;
  flex: 0 1;
  gap: 20px;
}

.datepick_search>div:first-child {
  width: 350px;
}

.datepick_orders_search_date {
  display: flex;
  column-gap: 12px;
  row-gap: 20px;
  justify-content: flex-start;
  align-items: center;
}

.datepick_orders_search_date .datepick_orders_select_date {
  display: flex;
}

.datepick_orders_search_date .startDateInput,
.datepick_orders_search_date .endDateInput {
  width: 100px !important;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  height: 32px;
}

.datepick_orders_search_date .startDateInput {
  border-left: 1px solid #ccc;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.datepick_orders_search_date .endDateInput {
  border-right: 1px solid #ccc;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.datepick_orders_search_date .timearrow {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  height: 32px;
}
.datepick_orders_search_date .timearrow img {
  height: 32px;
}

.datepick_orders_search_date .Polaris-ButtonGroup {
  flex-wrap: nowrap;
}

.datepick_orders_search_date .searchBtn {
  margin-left: 10px;
  height: 36px;
}

@media screen and (max-width: 1200px){
  .datepick_search {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 769px){
  .datepick_orders_search_date {
    width: 100%;
  }
}

@media screen and (max-width: 640px){
  .datepick_orders_search_date {
    flex-wrap: wrap;
  }
  .datepick_search>div:first-child {
    width: 100%;
  }
  .datepick_orders_search_date .searchBtn {
    margin-left: 0;
  }
}
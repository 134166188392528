.auth_tips_content .title{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
}

.Polaris-TextField__Suffix .reset_password{
    /* 重要 */
    pointer-events: all;
    cursor: pointer;
}

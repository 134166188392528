.redeem_coupons_list {
    display: flex;
    flex-wrap: wrap;
}
.redeem_coupons_list_item {
    width: calc(100% / 3 - 16px);
    margin-right: 24px;
    margin-bottom: 24px;
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0rem 0.125rem 0.25rem rgba(31,33,36,.1), 0rem 0.0625rem 0.375rem rgba(31,33,36,.05);
}
.redeem_coupons_list_item_title {
    font-size: 30px;
    padding: 16px 0 10px 0;
}
.redeem_coupons_list_item_desc {
    font-size: 12px;
}
 .redeem_coupons_list_item_icon {
    display: flex;
    align-items: center;
    justify-content: center;
 }
 .redeem_coupons_list_item_icon span {
    opacity: 0.7;
    margin-left: 5px;
 }
.redeem_coupons_list_item_button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 16px;
}
.redeem_coupons_list_item_button button {
    width: 90%;
}
.redeem_coupons_list_item:nth-child(3n) {
    margin-right: 0;
}
.redeem_coupons_list_item_input {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px;
}
/* .redeem_coupons_list_item_input div {
    border: #e5e5e5 solid 1px;
    display: inline-block;
    overflow: hidden;
}
.redeem_coupons_list_item_input input {
    height: 30px;
    border-top: none;
    border-bottom: none;
    border-left: #e5e5e5 solid 1px;
    border-right: #e5e5e5 solid 1px;
    margin: 0;
    text-align: center;
    width: 40px;
    outline: none;
    padding: 0 5px;
    float: left;
    line-height: 30px;
} */
.redeem_coupons_list_item_input button {
    height: 30px;
    width: 30px;
    float: left;
    border: none;
    outline: none;
    background-color: #f3f3f3;
    line-height: 30px;
    cursor: pointer;
    padding: 0;
}
.redeem_coupons_list_item_input span {
    font-weight: 600;
}
.redeem_coupons_list_item_top {
    background-color: rgba(247, 98, 35, 0.15);
    height: 158px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.redeem_coupons_list_item_bg {
    width: 207px;
    height: 102px;
    background-size: contain;
    color: #fff;
}
.history_table_title {
    display: flex;
    align-items: center;
}
.history_table_title img {
    margin-right: 10px;
}
.redeem_coupons_list_item_img {
    height: 158px;
}
.redeem_coupons_list_item_img img {
    width: 100%;
    height: 100%;
}
.redeem_points_input {
    flex: 1;
}
.redeem_points_num {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 10px;
    border-radius: 8px;
    border: 1px solid rgba(138, 138, 138, 1);
    margin-left: 10px;
}

@media screen and (max-width: 1024px) {
    .redeem_coupons_list_item {
        width: calc(100% / 2 - 16px);
    }
    .redeem_coupons_list_item:nth-child(2n) {
        margin-right: 0;
    }
    .redeem_coupons_list_item:nth-child(3n) {
        margin-right: 24px;
    }
    .redeem_coupons_list_item:nth-last-of-type(1) {
        margin-right: 0;
    }
}
@media screen and (max-width: 499px) {
    .redeem_coupons_list {
        justify-content: center;
    }
    .redeem_coupons_list_item {
        width: 290px;
        margin-right: 0;
    }
    .redeem_coupons_list_item:nth-child(3n) {
        margin-right: 0;
    }
}
.setting_affiliate_step .Polaris-Heading {
  font-size: 1.5rem;
  color: #2e4257;
}

.setting_affiliate_step a, .setting_affiliate_code a{
  color: #3e36e0;
  transition: color .3s;
  text-decoration: unset;
}

.setting_affiliate_code .Polaris-TextField__Input{
  width: 250px;
}

.setting_affiliate_code .Polaris-TextField--disabled .Polaris-TextField__Input{
  cursor: not-allowed;
}
.Panel_Content_Box_clo {
  border-top: 1px solid rgba(225, 227, 229, 1);
  padding-top: 10px;
}
.Panel_Content_Box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.Panel_Content_Box_Row button {
  margin-right: 10px;
}
.Panel_Content_Box_Row button:nth-last-of-type(1) {
  margin-right: 0;
}
.redeeem_product_page {
    display: flex;
    margin: 10px 0 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.redeeem_product_page .redeeem_product_page_title {
    min-width: 160px;
    /* padding-bottom: 8px; */
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}
.redeeem_checked.redeeem_product_page_title {
    font-weight: 700;
    
}
.redeeem_checked.redeeem_product_page_title p {
    width: 100%;
    height: 3px;
    margin-top: 12px;
    background-color: #202333;
    border-top-left-radius:8px ;
    border-top-right-radius:8px ;
}
.redeeem_product_page_title:nth-child(2) {
    margin-left: 24px;
}
@media screen and (max-width: 500px) {
    .redeeem_product_page .redeeem_product_page_title {
        font-size: 12px;
        min-width: 50% !important;
    }
    .redeeem_product_page_title:nth-child(2) {
        margin-left: 0;
    }
    .redeeem_product_page {
        margin: 10px;
    }
    .redeem_products_list .Polaris-Page-Header__Row {
        flex-wrap: nowrap !important;
    }
}
.common_modal {
    position: fixed;
    background: rgba(0,0,0,.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 101;
}
.common_modal_mask {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.common_modal_box {
    background-color: #fff;
    position: relative;
    background: var(--p-color-bg-surface);
    box-shadow: var(--p-shadow-600);
    overflow: hidden;
    border-radius: 10px;
}
.common_modal_close {
    position:absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}
.common_modal_show {
    display: none;
}
.common_modal_content {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}
.fourth_content_modal_box {
    padding: 16px;
    min-height: 200px;
}
.fourth_content_modal_img {
    min-height: 100px;
}
.fourth_content {
    /* padding: 36px; */
}
.fourth_content_modal_img {
    width: 100%;
    line-height: 1;
}
.fourth_content_modal_img img {
    width: 100%;
    max-height: 215px;
}
.fourth_content_head ,.fourth_content_mid ,.fourth_content_button ,.fourth_content_p{
    display: flex;
    align-items: baseline;
    justify-content: center;
}
.fourth_content_head {
   margin-bottom: 24px;
}
.fourth_content_head strong {
    font-size: 60px;
    margin: 0 10px;
}
.fourth_content_head span {
    font-size: 16px;
}
.fourth_content_mid {
    margin-top: 24px;
}
.fourth_content_mid strong{
    font-size: 30px;
    margin-right: 10px;
}
.fourth_content_mid span {
    font-size: 30px;
    font-weight: 700;
}
.fourth_content_button button {
    width: 100%;
    height: 50px;
    padding: 8px 20px;
    margin:0 40px 24px;
}
.fourth_content_p {
    margin-bottom: 16px;
    margin-top: 10px;
    font-size: 15px;
    color: #767676;
}
@keyframes modalFadeIn {
    from {
      opacity: 0;
      transform: translateY(10%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes modalFadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-10%);
    }
  }
  .custom-modal-enter {
    animation: modalFadeIn 0.3s forwards;
  }
  
  .custom-modal-exit {
    animation: modalFadeOut 0.3s forwards;
  }
  .common_modal_close svg {
    fill: #e5e5e5;
  }
  @media screen and (max-width: 420px) {
    .fourth_content_mid span {
        font-size: 20px;
    }
    .fourth_content_mid strong {
        font-size: 20px;
    }
    .fourth_content_button button {
        margin: 0 16px 20px;
    }
    .fourth_content_button button span {
        font-size: 14px !important;
    }
    .common_modal_box {
        margin: 0 16px;
        margin-top: -155px;
    }
    .common_modal  {
        z-index: 9999;
    }
  }

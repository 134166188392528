.draggable-scroll {
  overflow: hidden; /* 隐藏容器滚动条 */
  width: 100%;
  height: 100%;
}
 
.scroll-content {
  overflow-x: auto; /* 内容区域可以水平滚动 */
  overflow-y: hidden; /* 隐藏内容区域垂直滚动条 */
  width: 100%;
  cursor: pointer;
}
.scroll-content::-webkit-scrollbar {
  width: 4px;  /* 调整为所需的宽度 */
  height: 4px; /* 调整为所需的高度 */
  background-color: #f1f1f1;
}
@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
  .Setting__Page_Content .Polaris-Page {
      padding: 0 0.5rem;
      max-width: 1050px;
  }
}

.balance_recharge_wallet_button  {
  display:flex;
  align-items:center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.balance_recharge_wallet_button .dsm_container {
  width: max-content;
  border:1px solid #d3d3d3;
  border-radius: 5px;
  height: auto !important;
  padding: 4px 6px !important;
  margin-right: 16px;
}
.balance_recharge_wallet_button .dsm_container img {
  width: 20px;
  vertical-align: sub;
}
.Page__Content {
  margin-top: 2rem;
}
.Settings_Content {
  padding-top: 2rem;
}

.FormLayout__Label .Polaris-Icon__Svg{
  fill: rgb(129, 141, 154);
}


/* 左侧选项卡 start */
/* .SettingsPage__Box{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 24px;
}

.SettingsPage__Nav{
  grid-column: 1;
} */

nav{
  display: block;
}

.Nav_Left_Menu{
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  border-radius: 4px;
  background-clip: padding-box;
}

.Nav_Left_Menu>li:first-child>a,.Nav_Left_Menu>li:first-child>span, .Nav_Left_Menu>li:first-child>form>a {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.Nav_Left_Menu>.active>a, .Nav_Left_Menu>.active>a:hover, .Nav_Left_Menu>.active>a:focus,.Nav_Left_Menu>.active>span, .Nav_Left_Menu>.active>span:hover, .Nav_Left_Menu>.active>span:focus   {
  text-decoration: none;
  outline: 0;
  background-color: rgba(92, 106, 196, 0.22);
  border-radius: 4px;
}

.Nav_Left_Menu>.active>a ,.Nav_Left_Menu>.active>span {
  color: #2e4257;
  font-weight: 600;
}

.Nav_Left_Menu>li>a,.Nav_Left_Menu>li>span, .Nav_Left_Menu>li>form>a {
  display: block;
  padding: 8px 24px;
  clear: both;
  line-height: 20px;
  color: #2e4257;
  white-space: nowrap;
  color: #818d9a;
}


.SettingsPage__Content{
  grid-column: 2 / span 3;
}

@media (max-width: 768px){
  .wallet_top_button {
    margin-top: 8px;
  }
  .SettingsPage__Nav {
    grid-column: 1 / span 4;
  }
  .Nav_Left_Menu{
    margin-bottom: 1rem;
  }

  .SettingsPage__Content{
    grid-column: 1 / span 4;
  }
  .wallet_table_content .Polaris-DataTable__Cell{
    max-width: 100px;
    padding: 16px 8px !important;
    overflow-x: auto;
  }
}

.Profile_Checkbox{
  margin-top: 30px;
}

.Profile_Checkbox .Polaris-Choice{
  cursor: pointer;
}

.Profile_Checkbox .Polaris-Choice__Label{
  font-size: 1.5rem;
  font-weight: 500;
  margin-left: 10px;
}

.Profile_Table table{
  width: 100%;
  border: none !important;
}

.Profile_Table table thead th{
  padding: 5px 0;
  border-bottom: 1px solid #ccc;
  font-weight: 500;
}

.Profile_Table table thead th:first-child{
  text-align: left;
  width: 45%;
}

.Profile_Table_Store table thead th{
  padding: 5px 0;
  border-bottom: 1px solid #ccc;
}

.SettingsPage__datatable .Polaris-DataTable__Cell{
  padding:1.6rem 0;
}
.Polaris-DataTable__Footer .Polaris-ButtonGroup {
  display: inline-flex;
}

.Polaris-Header-Title-padding {
  padding: 1.6rem 0;
}
.wallet_top_button button:nth-child(1){
  margin-right: 16px;
}

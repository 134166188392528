.Good-Reviews__Container {
    position: fixed;
    z-index: 520;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

@media (min-width: 48.0625em){
    .Good-Reviews__Container {
        justify-content: center;
    }
}

.Good-Reviews-Modal-Dialog__Modal {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: calc(100vh - 60px);
    background: #fff;
    box-shadow: 0px 26px 80px rgb(0 0 0 / 20%), 0px 0px 1px rgb(0 0 0 / 20%);
}

@media (min-width: 48.0625em) {
    .Good-Reviews-Modal-Dialog__Modal {
        position: relative;
        max-width: 620px;
        margin: 0 auto;
        border-radius: 8px;
    }
}

.Good-Reviews-Modal-Dialog-Header {
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
    padding: 16px 20px;
    justify-content: flex-end;
}

.Good-Reviews-Modal-Header__Title {
    font-size: 23px;
    font-weight: 600;
    margin: 20px;
    color: black;
    margin-top: -30px;
}

.Good-Reviews-Logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Good-Reviews-Logo img{
    margin-right: 15px;
}

.Good-Reviews-Modal-CloseButton {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    cursor: pointer;
    position: relative;
    margin-left: 20px;
    margin-right: -8px;
    padding: 8px;
    border-radius: 6px;
}

.Good-Reviews-Icon {
    display: block;
    height: 20px;
    width: 20px;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
}

.Good-Reviews-Icon svg{
    fill: #4c4c4c;
}

.Good-Reviews-Icon__Svg {
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
}

.Good-Reviews-Modal-Subtitle {
    font-size: 16px;
    color: #4c4c4c;
    line-height: 1.5;
}

.Good-Reviews-Star__Content {
    margin: 30px 0 20px 0;
}

.Good-Reviews-Star-Box {
    display: flex;
    align-content: space-around;
    justify-content: center;
}

.Good-Reviews-Star-Item {
    padding-right: 5px;
    cursor: pointer;
}

.Good-Reviews-Star-Description {
    font-size: 16px;
    min-height: 22px;
}

.Good-Reviews-Star {
    width: 52px;
    height: 52px;
}

.Good-Reviews-ClickText {
    text-align: center;
    color: #918d8d;
    margin-bottom: 17px;
    font-size: 16px;
}

.Good-Reviews-Backdrop {
    position: fixed;
    z-index: 518;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background-color: rgba(33, 43, 54, 0.4);
    animation: Polaris-Backdrop__fade--in 200ms 1 forwards;
    opacity: 1;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity;
}

.footerRating_child {
    text-align: center;
}

.StarRating{
    position: relative;
    overflow: hidden;
    cursor: pointer;
    color: rgb(255, 234, 138);
    font-size: 35px;
}
@media screen and (max-width:600px) {
    .Good-Reviews-Logo {
        font-size: 20px;
    }
    .Good-Reviews-Logo img {
        width: 40px !important;
        height: 40px !important;
    }
    .Good-Reviews-Modal-Subtitle ,.Good-Reviews-ClickText{
        font-size: 14px;
    }
    .Good-Reviews-Star {
        width: 30px !important;
    }
}
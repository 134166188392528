.product_selection_container .Polaris-Page {
    margin-top: 0;
}
.product_selection_container .Polaris-Page .Polaris-Page__Content {
    padding-top: 8px;
}
.product_commone_filter_button {
    padding:6px 8px;
    /* border-bottom: var(--p-border-width-025) solid var(--p-color-border-secondary); */
    height: auto;
    overflow: visible;
}
.product_selection_container_tab {
    margin-left: -13px;
}
.product_commone_filter_button_item {
    background: var(--p-color-bg-surface);
    border-radius: var(--p-border-radius-200);
    border: var(--p-color-border) dashed var(--p-border-width-025);
    cursor: pointer;
    color: var(--p-color-text);
    position: relative;
}
.product_commone_filter_button_item:hover {
    background: transparent;
    border-style: solid;
    border-color: var(--p-color-border);
}
.product_commone_filter_button_item button {
    background: none;
    color: inherit;
    border: none;
    box-shadow: none;
    padding: 0 var(--p-space-100) 0 var(--p-space-200);
    height: 20px;
    max-height: 20px;
    min-height: 20px;
    font: inherit;
    cursor: inherit;
    outline: inherit;
}
.product_commone_filter_button_item button:hover {
    background: transparent;
    box-shadow: none;
}

.product_commone_content_list {
    padding: 20px;
    display: grid;
    gap: 16px;
    position: relative;
    grid-template-columns: repeat(auto-fill, minmax(215px, 1fr));
}

@media screen and (max-width: 500px) {
    .product_selection_container_tab {
        margin-left: 0px;
    }
    .product_management_container .Polaris-Page{
        margin-top: 8px;
    }
}
.points_log_tab {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
}
.points_log_tab_item {
    min-width:100px;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
}
.checked.points_log_tab_item {
    font-weight: 700;
}
.points_log_table .Polaris-IndexTable__TableHeading:nth-child(1), .history_table .Polaris-IndexTable__TableHeading:nth-child(2) {
    text-align: center !important;
}
.checked.points_log_tab_item p {
    width: 100%;
    height: 3px;
    margin-top: 10px;
    background-color: #202333;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
@media screen and (max-width: 768px) {
    .points_log_tab_item {
        min-width: 100px;
    }
    .points_log_tab {
        overflow-x: auto;
    }
}
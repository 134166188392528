/* find product 列表样式 */
.cate_box_list {
    background: #fff;
    box-shadow: 0 0 1px rgba(26, 26, 26, 0.01);
    border-radius: 12px;
}
.cate_box_list .cate_list {
    padding: 16px;
}
.Product_Card_Tkabk.find_product {
    height: 435px;
}
.search_product_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}
.search_product_head_h1 h1 {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
}
.holiday_banner {
    margin-bottom: 36px;
}
.holiday_banner .holiday_item{
    /* height: 200px; */
    /* padding-top: 14%;
    background-repeat: no-repeat;
    background-size: contain; */
    cursor: pointer;
}
.holiday_banner .holiday_item img{
   height: 100%;
   width: 100%;
   border-radius: 5px;
}
.holiday_banner .carousel.carousel-slider {
    overflow: initial;
}
.holiday_banner  .carousel .control-dots {
    bottom: -34px;
}
.Product_Card_Tkabk.find_product .Place_Order_Button{
    /* margin-top: -50px; */
    position: absolute;
    bottom: 16px;
    left:50%;
    transform: translate(-50%,0);
    width: 90%;
}
.select_cate_content {
    padding: 24px;
}
.select_cate_box {
    display: flex;
    margin-top: 16px;
    flex-wrap: wrap;
}
.select_cate_box_item {
    width: calc(100% / 3 - 16px);
    margin-right: 8px;
}
.cate_title{
    color:gray;
}

.cate_list{
    margin-top:1rem;
    display: flex;
    align-items: center;
}

.cate_img{
    width: 35px;
    height: 35px;
    margin-right: 10px;
    position: relative;
}

.cate_img img{
    width:100%;
    height:100%;
    cursor:pointer;
    padding:2px
}

.input_search_div{
    flex:1;
    margin:0 20px 0 0;
    position:relative
}

.search_input{
    outline: none;
    border: none;
    padding: 0 80px 0 10px;
    margin: 0;
    height: 40px;
    width: 100%;
    color: #333;
    font-size: 16px;
    border-radius: 6px 6px 6px 6px;
    padding-left: 20px;
    border: 0.1px solid #c9c9c9;
}
.search_input_sort > div  button {
    height: 44px;
    min-width: 100px;
}
.search_input_sort > div  button  span{
    font-size: 16px;
}
.search_input_img{
    position: absolute;
    /* background:#e62d04; */
    width:40px;
    height:30px;
    z-index:499;
    line-height:40px;
    text-align:center;
    right:10px;
    border-radius:15%;
    top:6px;
    cursor:pointer;
}

.search_input_img_camera{
    position: absolute;
    width: 30px;
    height: 30px;
    z-index: 499;
    right: 70px;
    border-radius: 15%;
    top: 9px;
    cursor: pointer;
}

.search_input_img_camera img{
    width: 100%;
}

.search_input_img img{
    width: 20px;
    height: 20px;
}

.search_input_sort{
    min-width: 80px;
    max-width:120px;
}

._my_product .Polaris-Page, ._my_product .Polaris-SkeletonPage__Page{
    /* padding: 0 112px !important; */
}
/* ._my_product  .Product_Resource_Wrapper .Product_Resource_Container {
    grid-template-columns: repeat(auto-fit,225px) !important;
} */
/* ._my_product .Product_Card_Tkabk .Product_Image_Wrapper{
    width: 225px !important;
    height: 225px !important;
}

._my_product .Product_Card_Tkabk.find_product {
    height: 355px !important;
}

._my_product .Product_Card_Tkabk .Product_Title_tkabk{
    height: 20px !important;
    -webkit-line-clamp:1 !important;
}

._my_product .Polaris-TextStyle--variationStrong{
    font-size: 20px;
    color:#e62d04;
} */

/* ._my_product  .Polaris-Card__Section{
    height: 140px !important;
} */

#PolarisPortalsContainer .Polaris-Button .Polaris-Button--primary{
    background-color: #e62d04 !important;
}
/*._my_product .buttonHover > div{*/
/*    border: 2px solid transparent;*/
/*    border-radius: 3%;*/
/*}*/

/*._my_product .buttonHover:hover > div{*/
/*    border-color:  red;*/
/*    border-radius: 3%;*/
/*}*/

._box{
    margin-top:0rem;
    display:flex;
    flex-wrap:wrap;
    margin-bottom: 20px;
}
.cate{
    position: relative;
    display: flex;
    align-items: center;
    background: white;
    /*margin-left: 60px;*/
    margin-right: 80px;
    margin-top: 20px;
    /*width: calc(1600px / 6 - 30px);*/
    width: calc(1600px / 6 - 60px);
    cursor: pointer;
    border-radius: 3%;
}

.cate_item{
    background-color: #f9d1c8;
}

._img{
    padding: 5px;
    width: 50px;
    height: 50px;
    margin-right: 15px;
}

.icon-img-content-gou{
    position: absolute;
    text-align: right;
    right: 0;
    top: 0;
}
.icon-img-content-gou > span {
    background: #303030;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 4px;
}
.icon-img-content-gou > span svg {
    fill: #fff;
}
.icon-img-content-gou img{
    width: 30px !important;
    height: 30px !important;
}


._img > img{
    width: 100%;
    height: 100%;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}

.box_div{
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 999;
    width: 300px;
    border-radius: 3%;
}

.box {
    position: relative;
    left: 0;
    margin: 0 auto;
}

.box ul{
    padding: 0;
    margin: 0;
}


.box ul li a {
    width: 100%;
    color: gray;
    display: block;
    font-size: 16px;
    padding-left: 33px;
    height: 45px;
}

.buttonHover:hover .Place_Order_Button{
    display: block;
    /* margin-top: -50px;
    margin-left: 16px;
    margin-right: 16px; */
}

.buttonHover.discountRange:hover .Place_Order_Button{
    display: block;
    margin-top:-50px;
}

.Place_Order_Button{
    display: none;
}

/*.Place_Order_Button button{*/
/*    background-color: red !important;*/
/*}*/

.Place_Order_Button #btn1{
    /* background-color: #e62d04 !important; */
    color: #fff;
    height: 36px;
}

.Place_Order_Button #btn2{
    /* background-color: white !important; */
    height: 36px;
}

.Products_title{
    word-break: break-word;
    overflow-wrap: break-word;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-right: 10px;
}

.Products_title_info{
    color:gray;
}

.Page_Jump{
    /* position: relative;
    left:10px;
    color: rgba(109, 113, 117, 1);
    cursor: pointer; */

    position: relative;
    left: 22px;
    color: rgb(39 40 42);
    cursor: pointer;
    background-color: #e9e9e9;
    padding: 5px;
    border-radius: 5px;
}

.Page_input{
    left: 14px;
    position: relative;
    border: 1px solid #d2d5d8;;
    height: 28px;
    width: 64px;
    border-radius: 10%;
    text-align: center;
    outline: none;
}

.title_first_info{
    font-weight:500;
    text-indent: 6px;
    border-left: 4px solid red;
    margin-left: -25px;
    font-size: 16px;
    cursor: pointer;
}
/*.mod-menu{position:absolute;z-index:1000;left:-1px;margin-left:100px;width: 300px}*/
.menu-cont-list span:hover{color:#e62d04;background-color: #f6f6f7}
.menu-cont-list span{
    text-decoration: none;
    font-size: 16px;
    padding-left: 33px;
    display: block;
    height: 45px;
    cursor: pointer;
    color:gray
}
.mod-menu .menu-item{
    width: 278px;
    background-color: white;
    position: relative;
    z-index: 22;
    padding: 0;
    box-shadow: 0 0 1px 0px rgb(159 153 153 / 60%);
    overflow-y:scroll;
    height: 500px;
}

.mod-menu .menu-item li{line-height:45px;background:white;width: 100%;}
.mod-menu .menu-item li.mouse-bg{position:relative;z-index:22;margin-right:-4px;}
.mod-menu .menu-item span{color:#222;font-size:16px;padding-left:33px;display:block;height:45px;cursor: pointer}
.mod-menu .menu-item span:hover{ text-decoration:none; background-color: #f6f6f7;color:#e62d04}
.mod-menu .menu-cont{
    position: absolute;
    left: 285px;
    top: 0px;
    background: #fff;
    width: 278px;
    z-index: 20;
    min-height: 430px;
    box-shadow: 0 0 1px 0px rgb(159 153 153 / 60%);
    overflow-y:scroll;
    height: 500px;
}

.mod-menu .menu-cont-list li{line-height:45px;background:white;width: 100%;}
/*.mod-menu .menu-cont-list li:last-child{border-bottom:none;}*/
.mod-menu  h3{font-size:10px;font-weight:500;}



.menu-item::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #f5f5f5;
}
/*定义滚动条轨道 内阴影+圆角*/
.menu-item::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}
/*定义滑块 内阴影+圆角*/
.menu-item::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #c8c8c8;
}
.menu-cont::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #f5f5f5;
}
/*定义滚动条轨道 内阴影+圆角*/
.menu-cont::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}
/*定义滑块 内阴影+圆角*/
.menu-cont::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #c8c8c8;
}

.top_position{
    position: fixed;
    z-index: 499;
    right: 26px;
    bottom: 50%;
}

.coupon_title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #000000;
    width: 100%;
    padding: 20px;
    text-align: center;

}

.coupon_title_tips{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    text-align: center;
}

.coupon_list{
    width: 100%;
    height: 95px;
    background-size: 100% 100%;
    /* background: #fd613e; */
    margin: 19px auto auto;
}

.coupon_list:last-child{
    margin-bottom: 50px;
}

.coupon_list_pop{
    display: flex;
    width: 100%;
    height: 80px;
    align-items: center;
    justify-content: center;
    padding-top: 7px;
    position: relative;
    overflow: hidden;
}

.coupon_pop_left{
    width: 70%;
    padding-left: 24px;
    padding-right: 12px;
    border-right: 2px dashed #f1f1f1;
}

.coupon_pop_text{
    /*margin-bottom: 5px*/
    display: flex;
    flex-direction: column;
}

.coupon_pop_text_span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #FD613E;
    display: inline-block;
    width: 115px;
}

.coupon_pop_text_span_two{
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    margin-bottom: 6px;
    line-height: 10px;
    color: #FD613E;
}

.coupon_pop_text_bottom{
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
}


.coupon_pop_right{
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.coupon_pop_right #coupon_pop_button1:hover{
    color: #f6f0f0;
}

.coupon_pop_right #coupon_pop_button1{
    background: #FD613E;
    border-radius: 10px;
    color: white;
}

.coupon_pop_right #coupon_pop_button2{
    background: gray;
    border-radius: 10px;
    color: white;
}

.loading_mask{
    width: 100vw;
    height: 100%;
    background: rgb(0 0 0 / 0%);
    position: absolute;
    z-index: 99999;
}

.loading_mask .full_height {
    height: 100vh;
}

@media screen and (max-width:768px){
    .coupon_list{
        /* height: 156px; */
        margin: 16px 0 0 0 ;
        height:100%;
        width: 100% !important;
    }

    .coupon_list_pop{
        height: 120px;
    }

    .coupon_pop_left{
        width: 70%;
        padding: 12px;
        margin-left: 8px;
    }

    .coupon_pop_text_bottom{
        line-height: 15px;
    }

    .coupon_pop_right .Polaris-Button{
        width: 80%;
    }

    .coupon_pop_right {
        padding: 5px 5px 5px 0;
        margin: auto;
    }

    .coupon_pop_text_span_two{
        display: block;
        line-height: 14px;
    }

}



@media screen and (max-width:750px){
    .cate_title{
        margin-left:4%
    }

    .cate_img{
        display: none;
    }

    .search_input_img{
        margin-left: 5%;
    }
    .search_input_sort{
        width:30%;
    }

    ._my_product .Polaris-Page, ._my_product .Polaris-SkeletonPage__Page{
        padding: 0  !important;
    }
    ._my_product  .Product_Resource_Wrapper .Product_Resource_Container {
        justify-content: center !important;
        grid-template-columns: 100% !important;
    }
   
    ._my_product .Product_Card_Tkabk.find_product {
        height: 390px !important;
        margin: 0 16px;
    }

    .buttonHover:hover .Place_Order_Button{
        position: relative;
    }

    .buttonHover.discountRange:hover .Place_Order_Button{
        margin-top:0;
    }

    .Place_Order_Button{
        display: block;
        position: relative !important;
    }

    ._my_product  .Polaris-Card__Section{
        height: 170px !important;
    }

    ._box{
        margin-top:1rem;
        display:flex;
        flex-wrap:wrap;
        justify-content: center;
        margin-bottom: 3rem;
    }

    .cate{
        display: flex;
        align-items: center;
        background: white;
        margin-top: 20px;
        /*margin-right: 0px;*/
        /*width: calc(1600px / 6 - 60px);*/
        width: 160px;
        margin-right: 8px;

    }

    .top_position{
        position: fixed;
        z-index: 2147483644;
        right: 26px;
        bottom: 50% !important;;
    }

    .Products_top{
        margin-left: 12px;
    }

}

#showTopButton{
    display: none;
}

/* START: Explosive Products / Winning Products / Discount Products 模块入口 */
.recommend_category{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px 0;
    margin: 0 20px;
    height: 80px;
    border-radius: 5px;
    cursor: pointer;
}

.recommend_category.sourcing{
    background-color: #a9b7ff;
}

.recommend_category.explosive{
    background-color: #fce1ce;
}

.recommend_category.winning{
    background-color: #f6fcb6;
}

.recommend_category.discount{
    background-color: #FF8C8C;
}
.recommend_category.discount .title {
    color: #fff;
}
.recommend_category.discount .sign_right img , .recommend_category.discount .sign_left img {
    width: 90%;
    height: 100%;
}
.recommend_category.discount .sign_right{
    top: -16px;
}
.holiday_banner .carousel .control-dots .dot {
    background: #D9D9D9;
    width: 12px;
    height: 12px
}
.recommend_category .title{
    font-size: 18px;
    font-weight: 600;
    min-width: 160px;
}

.recommend_category .sign_left img{
    width: 40px;
    height: 40px;
}

.recommend_category .sign_right{
    position: relative;
    top: -20px;
}

.recommend_category .sign_right img{
    width: 25px;
    height: 25px;
}

.spinner_find{
    display: block;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
}

.ramadan img {
    width: 100%;
    margin-top: 20px;
    cursor: pointer;
}

@media screen and (max-width:1750px){
    .recommend_category{
        margin: 0 0;
    }
}

@media screen and (max-width:1550px){
    .recommend_category .title{
        font-size: 17px;
    }
}
.dropshipman_category .carousel .arrow_button_left {
    position: absolute;
    z-index: 2;
    top: 5px;
    width: 20px;
    padding: 0;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    left: -24px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    background: #e7e7e7;
}
.dropshipman_category .carousel .arrow_button_right {
    position: absolute;
    z-index: 2;
    top: 5px;
    width: 20px;
    height: 50px;
    cursor: pointer;
    right: -24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    background: #e7e7e7;
}
@media screen and (max-width:500px){
    .select_cate_box_item {
        width: calc(100% / 2 - 8px);
    }
    .cate_list {
        margin: 0 16px;
    }
    .recommend_category{
        margin: 0 20px;
    }
    .arrow_button_right_select {
        display: none;
    }
    .dropshipman_category .carousel .arrow_button_left {
        position: absolute;
        z-index: 101;
        top: 5px;
        width: 20px;
        height: 50px;
        cursor: pointer;
        left: auto;
        right: 40px;
        display: none;
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
        background: #e7e7e7;
    }
    .dropshipman_category .carousel .arrow_button_right {
        position: absolute;
        z-index: 101;
        top: 5px;
        width: 20px;
        height: 50px;
        cursor: pointer;
        right: 10px;
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
        background: #e7e7e7;
    }
}

@media screen and (max-width:440px){
    .recommend_category .title{
        font-size: 16px;
    }
}

@media screen and (max-width:375px){
    .recommend_category .sign_left img{
        width: 35px;
        height: 35px;
    }

    .recommend_category .title{
        font-size: 15px;
    }
}

/* END:   Explosive Products / Winning Products / Discount Products 模块入口 */

/* START ---> 商品分类 */
.dropshipman_category{
    margin: 10px;
}
.dropshipman_category .carousel.carousel-slider {
    overflow: initial;
}
.product_success_button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px 16px;
    border-top: 1px solid rgba(26, 26, 26, 0.07);
}
.dropshipman_category_item{
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    margin: 5px 10px;
    min-width: 180px;
    cursor: pointer;
}

.dropshipman_category_item.selected{
    background-color: #f9d1c8;
}


/* @media screen and (max-width:1490px){
    .dropshipman_category .carousel li.slide{
        min-width: 25% !important;
    }
}

@media screen and (max-width:1269px){
    .dropshipman_category .carousel li.slide{
        min-width: 30% !important;
    }
}

@media screen and (max-width:1129px){
    .dropshipman_category .carousel li.slide{
        min-width: 35% !important;
    }
}

@media screen and (max-width:1024px){
    .dropshipman_category .carousel li.slide{
        min-width: 40% !important;
    }
}

@media screen and (max-width:968px){
    .dropshipman_category .carousel li.slide{
        min-width: 45% !important;
    }
}

@media screen and (max-width: 899px){
    .dropshipman_category .carousel li.slide{
        min-width: 50% !important;
    }
}

@media screen and (max-width: 875px){
    .dropshipman_category .carousel li.slide{
        min-width: 100% !important;
    }
} */

/* END   ---> 商品分类 */

/* 黑色星期五 */
.black_friday_banner{
    display: flex;
    align-items: center;
    margin: 0 11.2rem ;
    padding-top: 16%;
    border-radius: 8px;
    cursor:pointer;
    background-size: 100%;
    background-repeat: no-repeat;
}

@media screen and (max-width:600px) {
    .black_friday_banner{
        flex-direction: column;
        height: auto;
        margin: 0 1.2rem;
    }
    .black_friday_banner img{
        height: auto;
    }
    .black_friday_banner_title{
        font-size: 24px;
    }
    .black_friday_banner_dsc{
        font-size: 18px;
        margin: 16px 0;
    }
    .black_friday_banner_right{
        width: 100%;
    }
    .black_friday_banner_button{
        padding: 6px 18px;
    }
    .search_product_head {
        padding:  0 16px;
    }
}

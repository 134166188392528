@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
  .Setting__Page_Content .Polaris-Page {
      padding: 0 0.5rem;
      max-width: 1050px;
  }
}

.Page__Content {
  margin-top: 2rem;
}
.Settings_Content {
  padding-top: 2rem;
}

.FormLayout__Label .Polaris-Icon__Svg{
  fill: rgb(129, 141, 154);
}

.Setting__Page_Content.Content_outside .Polaris-Page > .Polaris-Box {
  position: sticky !important;
  top: 56px;
  z-index: 99;
  background-color: rgb(246, 246, 247);
}
/* 左侧选项卡 start */
.SettingsPage__Box{
  /* display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 24px; */
}
.setting_clearfix {
  overflow: hidden;
}

.SettingsPage__Nav{
  /* grid-column: 1; */
  /* display: inline-block; */
  width: 240px;
  position: sticky;
  top: 132px;
  float: left;
}
.Content_within .SettingsPage__Nav {
  top: 76px;
}
.Setting__Page_Content.Content_within .Polaris-Page > .Polaris-Box {
  top: 0;
}
.SettingsPage__Content {
  display: inline-block;
  width: calc(100% - 264px);
  margin-left: 24px;
  /* float: right; */
}

nav{
  display: block;
}

.Nav_Left_Menu{
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  border-radius: 4px;
  background-clip: padding-box;
}

.Nav_Left_Menu>li:first-child>a,.Nav_Left_Menu>li:first-child>span, .Nav_Left_Menu>li:first-child>form>a {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.Nav_Left_Menu>.active>a, .Nav_Left_Menu>.active>a:hover, .Nav_Left_Menu>.active>a:focus,.Nav_Left_Menu>.active>span, .Nav_Left_Menu>.active>span:hover, .Nav_Left_Menu>.active>span:focus  {
  text-decoration: none;
  outline: 0;
  background-color: rgba(92, 106, 196, 0.22);
  border-radius: 4px;
}

.Nav_Left_Menu>.active>a ,.Nav_Left_Menu>.active>span{
  color: #2e4257;
  font-weight: 600;
}

.Nav_Left_Menu>li>a,.Nav_Left_Menu>.active>span, .Nav_Left_Menu>li>form>a {
  display: block;
  padding: 8px 24px;
  clear: both;
  line-height: 20px;
  color: #2e4257;
  white-space: nowrap;
  color: #818d9a;
}


.SettingsPage__Content{
  grid-column: 2 / span 3;
}

@media (max-width: 768px){
  .SettingsPage__Nav {
    /* grid-column: 1 / span 4; */
    width: 100%;
    float: none;
    position:  relative !important;
    top: 0 !important;
  }
  .Nav_Left_Menu{
    margin-bottom: 1rem;
  }

  .SettingsPage__Content{
    /* grid-column: 1 / span 4; */
    width: 100%;
    margin-left: 0 !important;
  }
}
@media screen and (max-width: 500px) {
  .Content_within .SettingsPage__Nav {
    top: 0 !important;
  }
}
.Profile_Checkbox{
  margin-top: 30px;
}

.Profile_Checkbox .Polaris-Choice{
  cursor: pointer;
}

.Profile_Checkbox .Polaris-Choice__Label{
  font-size: 1.5rem;
  font-weight: 500;
  margin-left: 10px;
}

.Profile_Table{
  overflow-x: auto;
}

.Profile_Table table{
  width: 100%;
  border: none !important;
}

.Profile_Table table thead th{
  padding: 5px 0;
  border-bottom: 1px solid #ccc;
  font-weight: 500;
}

.Profile_Table table thead th:first-child{
  text-align: left;
  width: 45%;
}

.Profile_Table_Store table thead th{
  padding: 5px 0;
  border-bottom: 1px solid #ccc;
}

.Nav_Left_Menu .sup_text{
  margin-left: 4px;
  padding: 0px 2px 2px;
  background-color: rgb(228, 20, 20);
  font-size: 10px;
  color: rgb(255, 255, 255);
  border-radius: 40%;
}


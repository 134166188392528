.product_variant_content_top {
    padding:16px;
}
.product_quickly_select {
    margin-top: 16px;
    border: 1px solid rgba(227, 227, 227, 1);
    padding: 16px;
    border-radius: 12px;
}
.quickly_select {
    font-size: 13px;
    font-weight: 450;
    line-height: 20px;
    color: rgba(48, 48, 48, 1);
}
.quickly_select_content {
    margin-top: 10px;
}
.quickly_select_content .quickly_select_sp {
    padding: 0 6px;
    border-radius: 8px;
    cursor: pointer;
    background-color: rgba(227, 227, 227, 1);
    border: 1px solid transparent;
}
.quickly_select_sp.act_sp {
    border-color: rgba(0, 91, 211, 1);
}
.variant_cell_01 {
    max-width:84px;
    min-width: 84px;
}
.variant_cell_attribute {
    min-width: 100px;
}
.variant_cell_attribute .Polaris-Connected__Item , .variant_cell_02 .Polaris-Connected__Item , .variant_cell_03 .Polaris-Connected__Item {
    z-index: 20 !important;
}
.wrap_head_text {
    display: inline-block;
    max-width: 120px;
    word-wrap: break-word;
    white-space: wrap;
}

.variant_cell_02 {
    min-width: 120px;
}
.variant_cell_03  {
    width: 100px;
    min-width: 100px;
}
.variant_cell_04  {
    min-width: 60px;
}
.variant_cell_05, .variant_cell_06, .variant_cell_07 {
    width: 100px;
    min-width: 100px;
}
.variant_cell_sku p {
    white-space: wrap;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
}
.variant_cell_selling {
    position: relative;
}
.suffix_calculatorIcon {
    position: absolute;
    right: 5px;
    top: 6px;
    z-index: 21;
    background-color: #fff;
}
.shipping_info_content {
    margin-top: 8px;
}
.product_variant_table {
    /* height: 500px;
    overflow-y: auto; */
}
.product_variant_table tbody tr:hover {
    cursor: default;
}
.product_info_title_label {
    width: 100%;
}
.product_info_title_label > div{
    width: 100%;
}
.product_info_media_content {
    position: relative;
    line-height: 1;
}
.product_info_media_box_bg {
    position: absolute;
    height: 100%;
    width: 100%;
}
.color_loss .Polaris-TextField__Input:disabled {
    color: rgba(142, 31, 11, 1);
    -webkit-text-fill-color: rgba(142, 31, 11, 1);
}
.color_profit .Polaris-TextField__Input:disabled {
    color: rgb(12 155 91);
    -webkit-text-fill-color: rgb(12 155 91);
}
.product_info_media_box_bg .product_info_media_box_item {
    background: var(--p-color-bg-fill-tertiary);
    border-radius: var(--p-border-radius-200);
}
.product_info_media_box {
    display: grid;
    gap: 6px;
    grid-template-columns: repeat(6, 1fr);
    -webkit-user-select: none;
    user-select: none;
}
.product_info_media_box_item_view {
    width: 92px;
    height: 92px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0,0,0,.5);
    border-radius: var(--p-border-radius-200);
    color: #fff;
    cursor: pointer;
}
.product_info_media_box_item_view:hover {
    background-color: rgb(0,0,0,.8);
}
.product_info_media_box_item:first-child {
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
}
.product_info_media_box_item {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s linear;
}
.product_info_clo {
    margin-bottom: 4px;
}
.product_info_clo .product_info_clo_ai {
    line-height: 1;
    max-height: 20px !important;
}
.product_info_clo svg {
    width: 20px !important;
}

.product_info_clo_ai {
    color: rgba(87, 0, 209, 1);
    font-size: 13px;
    font-weight: 550;
    line-height: 20px;
    cursor: pointer;
}
.product_info_clo_ai svg {
    fill: rgba(87, 0, 209, 1);
}
.product_info_clo_ai_disabled {
    color: #999;
}
.product_info_clo_ai_disabled svg {
    fill:  #999;
}
.product_info_media_box_item:hover .product_info_media_checkbox {
    background: #00000080;
    opacity: 1;
}
.product_info_media_checkbox {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
}
.product_info_media_checkbox.is_select {
    opacity: 1;
}
.product_info_media_checkbox .Polaris-Checkbox__ChoiceLabel {
    padding-left: 5px;
}
.product_info_media_checkbox .image_preview_action {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    opacity: 0;
}
.product_info_media_checkbox:hover .image_preview_action {
    opacity: 1;
}
.product_detail_variant_shipping_country {
    margin-top: 16px;
}
/* .product_variant_table .Polaris-IndexTable-ScrollContainer {
    overflow-y: auto !important;
    max-height: 500px !important;
} */
.product_variant_table .Polaris-Scrollable {
    outline: none !important;
}
.product_info_description .ck-editor__main .ck-content {
    max-height: 500px;
    overflow-y: auto;
}
.collection-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    min-width: 279px;
}
.current_span span.Polaris-Tag__Text{
    white-space: wrap;
    word-wrap: break-word;
    display: inline-block;
    max-width: 250px;
}
/* .product_management_page .Polaris-Layout__Section--oneThird{
    position: sticky;
    top: 76px;
} */
 .product_management_page {
    position: relative;
 }
.product_management_page.product_management_page_fullscreen .Polaris-Layout__Section--oneThird{
    top: 0;
}
.detail_hover_link {
    max-width: 620px;
    word-wrap: break-word
}
.detail_hover_link:hover {
    color: #005bd3;
    cursor: pointer;
    text-decoration: underline;
}
.scroll_button {
    position: fixed;
    left: 1569px;
    top: 175px;
    z-index: 20;
    width: 30px;
    height: 56px;
    background-color: rgb(227, 227, 227);
    border-radius: 8px;
    padding: 4px;
}
.scroll_button div {
    cursor: pointer;
    height: 50%;
}
.scroll_button_variant {
    position: relative;
}
.scroll_button_variant_tips {
    width: max-content;
    position: absolute;
    display: none;
    left: -63px;
    top: -32px;
    background: #fff;
    padding: 2px 4px;
    border-radius: 8px;
    /* z-index: 31; */
    height: 28px !important;
    border: 1px solid rgb(227, 227, 227);
}
.scroll_button_variant_tips::after  {
    content: "";
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: rgb(255, 255, 255) transparent transparent transparent;
}
.scroll_button_variant:hover .scroll_button_variant_tips{
    display: block;
}
.scroll_button_Information {
    position: relative;
}
.scroll_button_Information .scroll_button_variant_tips{
    left: -76px;
}
.scroll_button_Information:hover .scroll_button_variant_tips{
    display: block;
}
@media screen and (max-width: 768px) {
    .variant_cell_02 {
        min-width: 160px !important;
    }
    .product_info_media_box {
        grid-template-columns: repeat(3, 1fr);
    }
    .product_management_page .Polaris-Page{
        margin-top: 8px !important;
    }
    /* .product_management_page .Polaris-Layout__Section--oneThird{
        position: relative !important;
        top: 0 !important;
    } */
    .scroll_button {
        position: fixed;
        left: calc(100% - 46px) !important;
        top: 140px !important;
        background-color: rgb(227, 227, 227);
        border-radius: 8px;
        padding: 4px;
    }
    .detail_hover_link {
        max-width: 250px !important;
    }
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
    .scroll_button {
        position: fixed;
        right: 20px !important;
        background-color: rgb(227, 227, 227);
        border-radius: 8px;
        padding: 4px;
    }
}
@media screen and (max-width: 500px) {
    .suffix_calculatorIcon {
        top: 8px;
    }
}
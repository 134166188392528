.dropshipman-carousel-list{
  margin: 1.6rem 0 2rem;
}

.dropshipman-carousel-slide img{
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  pointer-events: unset;
}

.slider-wrapper .dropshipman-carousel-slide img {
  border-radius: 10px;
}
.partner_silde .slider-wrapper .dropshipman-carousel-slide img {
  min-height: 167px;
}
.inventer_variant_head {
    display: flex;
    padding-bottom: 5px;
    font-weight: 500;
    margin-top: 16px;
    border-bottom: 1px solid #ccc;
}

.inventer_variant_skus .inventer_variant_sku_img {
    width: 60px;
    line-height: 1;
    box-shadow: 0rem 0.125rem 0.25rem rgba(31,33,36,.1), 0rem 0.0625rem 0.375rem rgba(31,33,36,.05);
    border-radius: 5px;
}
.inventer_variant_skus .inventer_variant_sku_img img {
    max-width: 100%;
    border-radius: 5px;
}
.inventer_variant_sku {
    display: flex;
    align-items: center;
    width: 25%;
    text-align: center;
    justify-content: center;
}
.inventer_variant_skus {
   justify-content: flex-start;
}
.inventer_variant_attr {
    flex: 1;
    max-width: 20%;
    text-align: center;
}
.inventer_variant_cost {
    width: 20%;
    text-align: center;
}
.inventer_variant_price {
    width: 20%;
    text-align: center;
}
.inventer_variant_inventory {
    width: 20%;
    text-align: center;
}
.inventer_variant_body {
    max-height: 450px;
    overflow-y: auto;
}
.inventer_variant_sku_sku {
    margin-left: 24px;
}
.inventer_variant_body_list {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #ccc;
}
.inventer_variant_body_list:nth-last-of-type(1) {
    margin-bottom: 16pxs;
}
.variants_contents {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: auto;
  }
  .variants_content {
    max-height: 500px;
    overflow-y: auto;
    max-width: 1600px;
    min-width: 1200px;
    overflow-x: hidden;
  }
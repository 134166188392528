.map_search_title{
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
    font-size: 2rem;
    font-weight: 500;
    padding-left: 1rem;
}

.map_search_title_text {
    margin-left: 4rem;
}


.Search_emptyWithImg .Polaris-EmptyState__Image{
    margin-left: -10%;
    height: 183px;
}

._my_product .Product_Card_Tkabk .Search_Title_tkabk{
    height: 20px !important;
    -webkit-line-clamp:1 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.Product_Detail_Img_Map {
    flex-basis: 300px;
    margin-right: 8px;
    margin-bottom: 24px;
    max-width: 100%;
    display: inline-block;
    width: 25%;
}

.spinner{
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 100;
}

@media (max-width: 956px){
    .Product_Detail_Img_Map{
        width: 80%!important;
    }
}

@media (max-width: 705px){
    .Product_Detail_Img_Map{
        max-width: 300px;
        margin: auto;
    }
}



.product_cell_01 {
    width: 60px;
}
.product_cell_02 {
    min-width: 233px;
    max-width: 250px;
}
.product_cell_02_box {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0 var(--p-space-400);
    place-items: center start;
    white-space: normal;
    overflow-wrap: anywhere;
    word-break: normal;
}
.product_cell_02_title {
    white-space: wrap;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    text-overflow: ellipsis;
    word-break: keep-all;
    overflow: hidden;
}
.product_cell_02_title_link {
    
}
/* .product_cell_02_box_height {
  height: 120px;
} */
.product_cell_02_title_link:hover {
    color: #005bd3;
    cursor: pointer;
    text-decoration: underline;
}
.product_cell_02_ai , .product_cell_02_ai2 {
    visibility: hidden;
    cursor: pointer;
}

.product_cell_02_box:hover .product_cell_02_ai , .product_cell_02_box:hover .product_cell_02_ai2{
    visibility: visible;
}
.product_cell_02_ai svg {
    fill: rgba(128, 81, 255, 1);
}
.product_cell_03 {
    min-width: 108px;
    max-width: 135px;
}
.product_cell_04 {
    min-width: 120px;
    max-width: 135px;
}
.product_cell_05 {
    min-width: 160px;
    max-width: 160px;
}
.product_cell_06 {
  min-width: 100px;  
} 
.product_cell_07 {
    min-width: 100px;
}
.product_table_box .Polaris-IndexTable__PaginationWrapper .Polaris-InlineStack {
    justify-content: flex-start;
}
.management_no_product_text p{
    font-size: 16px;
    font-weight: 650;
    line-height: 24px;
    color: rgba(118, 118, 118, 1);
}
.management_no_product_text span {
  max-width: 500px;
}
.management_no_product_button {
    margin-top: 8px;
}
/* 默认分页点样式 */
.product_cell_01 .swiper-pagination-bullet {
    background-color: gray;
    width: 10px;
    height: 10px;
    opacity: 0.5;
  }
  
/* 激活状态 */
.product_cell_01 .swiper-pagination-bullet-active {
    background-color: #f5520c;
    opacity: 1;
}

.re_edited span{
    font-family: Inter;
    font-weight: 450;
    font-size: 12px;
    line-height: 16px;
    border-radius: 8px;
    padding: 2px 6px;
    background: rgba(228, 222, 255, 1);
    color: rgba(87, 0, 209, 1);
}
.productSwiper {
    height: 220px !important;
}
.product_cell_05_collection {
    word-wrap: break-word;
    display: inline-block;
    max-width: 150px;
    white-space: wrap;
}
/* seo ant modal */
.seo_ant_modal {
    min-height: 300px;
  }
  .seo_ant_modal_title {
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    font-weight: 600;
    margin: 16px;
  }
  .seo_ant_modal_banner {
    width: 100%;
    height: 240px;
    line-height: 1;
  }
  .seo_ant_modal_banner img {
    width: 100%;
  }
  .seo_ant_modal_button {
    margin: 16px;
    text-align: right;
  }
  .seo_ant_modal_button button:nth-child(1){
    margin-right: 16px;
  }
  /* product Tabs 样式 end */

  .list_button button {
    min-width: 96px;
  }
  .product_cell_08 {
    min-width: 300px;
  }
  .product_cell_08 button {
    max-height: 28px;
  }
  .product_cell_08 .Polaris-InlineStack {
    align-items: center;
  }
  /* .product_cell_02_box_height2 {
    height: 80px;
  } */
   .product_cell_03_price_cost {
    min-width: 140px;
   }
   .per_page_footer {
    display: inline-block;
   }
   .per_page_select {
    width: 60px;
    margin: 0 5px;
    display: inline-block;
   }
  @media screen and (max-width: 768px) {
    /* .product_cell_02_box_height,.product_cell_02_box_height2 {
      height: auto;
    } */
    .management_no_product_container .Polaris-EmptyState__Image.Polaris-EmptyState--loaded {
        width: 100%;
    }
    .seo_ant_modal_title {
      max-width: 260px;
      word-wrap: break-word;
      white-space: wrap;
    }
    .product_cell_03_price_cost {
      min-width: 160px !important;
    }
    .product_cell_03_price_cost span {
      max-width: 160px !important;
  }
  .seo_ant_modal_banner {
    height: auto !important;
  }
  .management_no_product_text span {
    max-width: 300px !important;
  }
  .per_page_footer {
    display: none;
  }
  }
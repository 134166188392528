.Dropshipman_App_Partners{
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
}

.Dropshipman_App_Partners_Item{
  border-radius: 10px;
  border: #dee3eb solid 1px;
  background-color: #fff;
  cursor: pointer;
  padding: 5px;
  width: calc(100% / 3 - 30px);
  margin-right: 45px;
  margin-bottom: 20px;
}

.Dropshipman_App_Partners_Item:nth-child(3n - 3){
  margin-right: 0;
}

.Dropshipman_App_Partners_Item a{
  color: unset;
}

.Dropshipman_App_Partners_Item .Top{
  display: flex;
  align-items: center;
  padding: 10px 10px 15px 10px;
}

.Dropshipman_App_Partners_Item .App_Box .Top img{
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

.Dropshipman_App_Partners_Item .App_Box .Top .App_Name .star_box{
  display: flex;
  align-items: center;
}

.Dropshipman_App_Partners_Item .App_Box .Top .App_Name .star_box span{
  font-size: 12px;
  font-weight: 500;
  margin-right: 5px;
}

.Dropshipman_App_Partners_Item .App_Box .Top .App_Name .star{
  width: 13px;
  height: 13px;
  margin-right: 3px;
}

.Dropshipman_App_Partners_Item .App_Box .Top .App_Name{
  text-align: left;
  margin-left: 15px;
}

.Dropshipman_App_Partners_Item .App_Box .Top .App_Name .title{
  font-weight: 600;
  font-size: 14px;
  height: 40px;
  overflow: hidden;				
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
  white-space: break-spaces;
  -webkit-box-orient: vertical;
}

.Dropshipman_App_Partners_Item .App_Box .Bottom{
  /* border-top: 1px solid #e5e5e5; */
  padding: 8px 0 5px 10px;
  text-align: left;
}

.Dropshipman_App_Partners_Item .App_Box .Bottom span{
  color: #5c5f62;
  font-weight: 500;
  font-size: 13px;
  overflow: hidden;				
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
  white-space: break-spaces;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 1121px) {
  .Dropshipman_App_Partners_Item{
    width: calc(100% / 2 - 30px);
    margin-right: 60px !important;
  }

  .Dropshipman_App_Partners_Item:nth-child(2n - 2){
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 921px) {
  .Dropshipman_App_Partners_Item{
    width: 100%;
    margin-right: 0 !important;
  }
}
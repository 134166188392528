*{
    box-sizing:border-box;
}
.Three{
    display: inline-block;
    width: 30%;
    color: red;
}
.Seven{
    width: 70%;
    display: inline-block;
}
.product-img{
    max-width: 100%;
    border:1px solid #e1e8f0;
}
.subTabOne{
    overflow: hidden;
}

.Polaris-Icon__Svg_button{
    width: 16px;
    float: left;
}

.savedData_width{
  width: 115px;
}

.headings_div__2{
  opacity:1;
  display:flex;
}


.headings_div__3{
  height: 20px;
  width: 16px;
  margin-right: 9px;
}
.headings_div__3 .Polaris-Choice{
  padding: 0;
}

.headings_div__3 .Polaris-Choice__Control{
  margin: 0;
}


.Polaris-ResourceList-BulkActions__ButtonGroup{
  width: auto; 
  justify-content: flex-start;
}

.Polaris-ResourceList-BulkActions__Popover .Polaris-Button{
  white-space: nowrap;
}

.FAQ-Box {
  padding-top: 2.5rem;
}

.OrderTopDIV .Polaris-Choice{
  padding: 0;
}


.OrderDivclassStyle .Polaris-Card{
  border-radius: unset;
}

.OrderTopContentBottomDIV .Polaris-Card{
  border-radius: 3px !important;
}

.OrderTopContentDIV{
  padding:16px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
}


.OrderTopContentBottomDIV{
  padding: 0;
}

.Polaris-Badge{
  border:0;
}


/* .classCard--Polaris-Card .Polaris-Card{
  margin: 0;
} */

.ButtonViewIcon{
  padding: 20px 0;
}
.ButtonViewIconOrderProduct{
  padding: 10px;
}
.ButtonViewIconOrderProductPartially{
  padding:0 10px;
}
.ButtonViewIconFulfilled{
  padding: 20px 40px;
}
.input-group {
  display: flex;
}
.input-group input {
  line-height: 2.4rem;
  -moz-appearance: none;
  padding: .5rem 1rem;
  border: .1rem solid #c4cdd5;
  border-radius: .3rem;
  font-size: 1.4rem;
  font-family: -apple-system,BlinkMacSystemFont,San Francisco,Roboto,Segoe UI,Helvetica Neue,sans-serif;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  max-width: 7.5rem;
}
.input-group .append {
  line-height: 2.4rem;
  padding: .5rem 1rem;
  border-radius: .3rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: .1rem solid #c4cdd5;
  border-left-width: 0;
  background: #f8fafb;
  color: #818d9a;
}
.ButtonViewIconWidthSuccess{
  padding:10px 40px;
}
.ButtonViewIconWidth{
  padding:10px 20px;
}
.widthRight{
  width: 145px;
}
.max-width{
  width: 400px;
}
.max-width-image{
  width: 60px;
}

.OrderTopContentBottomDIV .Polaris-Card__Section{
  padding: 1.5rem 1rem 1rem 1rem;
}

.cordMarginDiv .Polaris-Card{
  background-color: rgba(63, 63, 68, 0.05);
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  margin:16px;
}
.cordMarginDiv{
  overflow: auto;
  /* margin-bottom: 16px; */
}

.ButtonNoStyle{
  margin-top: -4px;
}
.ButtonNoStyle .Polaris-Button{
  /* padding: 2px 6px; */
  min-height: 0;
}

.ButtonNoStyle4px .Polaris-Button{
  /* padding: 4px 6px; */
  min-height: 0;
}

.OrderDivclassStyle .Polaris-Card + .Polaris-Card{
  margin-top:0;
}
.OrderDivclassStyle{
  border:1px solid rgba(63, 63, 68, 0.31);    
  border-radius: 5px;
  background-color: #fff;
}
.classpOpacity50{
  opacity: 0.5;
}

.Polaris-Badge--statusCritical{
  background-color: var(--p-surface-critical,#fead9a);
  color: #583c35;
}

.provinceCity--loading{
  position: absolute;
  background-color: #fff;
  opacity: 0.5;
  top: 0;
  height: 100%;
  width: 100%;
  text-align: center;
}
.provinceCity--loading--image{
  position: absolute;
    left: 50%;
    top: 30%;
}
.TextFieldPhonePrefix .Polaris-Connected__Item--connection{
  width: 20%;
}

.dropshipman-item{
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  grid-gap: 24px;
  padding: 0 6px;
}

.ui-stack {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: -1.6rem;
  margin-left: -1.6rem;
}
.ui-stack>* {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-top: 1.6rem;
  margin-left: 1.6rem;
}

.ui-stack-item {
  min-width: 0;
  max-width: 100%;
}

.ui-stack-item--fill {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.ui-stack--alignment-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;    
}

.ui-stack--alignment-center-grid{
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 90px 120px;
}

.ui-stack--alignment-center-grid-mapping{
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 90px;
}

.line-item-image{
  width: 60px;
}

.Button__Content_display .Polaris-Button{
  max-width: 200px;
  width: 100%;
}
.Button__Content_display .Polaris-Button__Content{
  white-space: nowrap;
}
.Button__Content_display .Polaris-Button__Content .Polaris-Button__Text{
  width: calc(100%);
  text-overflow: ellipsis;
  overflow: hidden;
}

.Polaris-Stack-left .Polaris-Stack{
  margin-left: 0;
}

.Banner-Polaris-Banner--withinContentContainer .Polaris-Banner--withinContentContainer{
  padding: 0;
}


.order_removed_heading{
  margin: 16px;
}

.OrderTopContentBottomDIVRefunds{
  opacity: .6;
  background: #f9fafb;
}

.Shipping_Table_Header{
  margin-top: -2rem;
}

.Shipping_Table_Header .Polaris-Card__Header{
  padding-left: 0;
}

.ui-stack-item .Polaris-Banner.Polaris-Banner--statusSuccess.Polaris-Banner--withinContentContainer{
  box-shadow: unset !important;
}

.ui-stack-item.Banner-Polaris-Banner--withinContentContainer .Polaris-Banner--withinContentContainer{
  box-shadow: unset !important;
}

.no_source_orders {
  padding-top: 1rem;
}

.no_source_orders .datepick_filters {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.bulk_sourcing_order_content {
  font-size: 16px;
  background-color: rgb(235, 249, 252);
  border: 1px solid rgb(173, 211, 217);
}

.no_source_orders .datepick_filters .search_input_style {
  width: 350px !important;
}

@media screen and (max-width: 769px){
  .no_source_orders .datepick_filters .search_input_style {
    width: 100% !important;
  }

  .no_source_orders .datepick_filters>div:first-child {
    width: 100%;
  }
}
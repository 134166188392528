.product_variant_content {
    width: 100%;
}
.product_variant_right {
    flex: 1;
}

.product_info_image_box {
    margin-right: 8px;
    margin-bottom: 24px;
    width: 600px;
    max-width: 100%;
    position: relative;
    display: flex;
}
.product_info_image_box .product_info_swiper_detail {
    max-height:  470px;
    min-height:  470px;
    width:  470px;
    position: relative;
    z-index: 99;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #ccc;
    overflow: hidden;
    cursor: move;
    transition: transform .3s ease-in-out;
}

.product_info_swiper_detail > .swiper {
    box-sizing: border-box;
    height: 468px;
}
.product_info_swiper_detail > .swiper .lazyload-wrapper  {
    height: 100%;
}
.product_info_image_box .product_info_swiper_detail img {
    width: 100%;
    /* height: auto !important; */
    /* height: 498px; */
    height: 100%;
    /* max-height: 500px; */
    border-radius: 5px;
    object-fit: contain; 
    /* 保持图片比例，不裁剪 */
}
.product_info_image_box  .detail_mask {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 180px;
    height: 180px;
    background: rgba(247, 157, 45, 0.3);
    opacity: .6;
    display: none;
    pointer-events: none;
}
.product_info_image_box  .picture_big {
    overflow: hidden;
    display: none;
    position: absolute;
    background-color: #fff;
    left: 580px;
    top: 0px;
    width: 470px;
    height: 470px;
    border: 1px solid #ccc;
    z-index: 99999;
    border-radius: 5px;
    pointer-events: none;
}
.product_info_image_box .details_product {
    margin: 0 17px 0 0;
    max-height: 470px;
    position: relative;
}
.product_info_image_box .details_product .swiper {
    max-height: 470px;
    padding: 0;
}
.product_info_image_box .details_product .prev {
    position: absolute;
    left: 28px;
    z-index: 10;
    top: -7%;
    transform: rotate(90deg);
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F3F4F6;
    border-radius: 0px 2px 2px 0px;
    cursor: pointer;
}
.product_info_image_box .details_product .next {
    position: absolute;
    left: 28px;
    z-index: 10;
    top: 91%;
    transform: rotate(90deg);
    height: 80px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F3F4F6;
    border-radius: 0px 2px 2px 0px;
    /* transform: translateY(-50%); */
    cursor: pointer;
}
.product_info_image_box .product_swiper_thumbs, .product_info_image_box .product_swiper_thumbs_hover {
    margin: 5px 5px 5px 0;
    width: 76px !important;
    height: 76px !important;
    cursor: pointer;
    border-radius: 8px;
    opacity: 1 !important;
    border: 1px solid #ccc;
}
.product_info_image_box .swiper-button-disabled {
    display: none !important;
}
.product_info_image_box .product_swiper_thumbs > img, .product_info_image_box .product_swiper_thumbs_hover > img {
    border-radius: 8px;
    height: 100% !important;
    width: 100% !important;
    max-height: 100% !important;
}
.product_swiper_thumbs.swiper-slide-thumb-active {
    border-color: rgba(0, 91, 211, 1);
}
.product_info_image_box .mySwiper .product_swiper_thumbs:hover {
    border-color: rgba(0, 91, 211, 1);
}
.product_detail_variant_title {
    border-bottom: 2px solid rgba(26, 26, 26, 1);
    padding-bottom: 10px;
}
.product_detail_variant_title h1{
    font-size: 20px;
    font-weight: 650;
    line-height: 24px;
}
.product_detail_variant_invent .product_detail_variant_invent_span{
    padding: 4px 8px;
    border-radius: 8px;
    background-color: rgba(224, 240, 255, 1);
    font-size: 13px;
    font-weight: 550;
    line-height: 20px;
    color: rgba(0, 33, 51, 1);
    display: flex;
    align-items: center;
    width: max-content;
}
.product_detail_variant_invent_span svg {
    fill:rgba(0, 148, 213, 1);
}
.product_detail_variant_invent_span_l,.product_detail_variant_invent_span_r {
    background-color: rgba(0, 0, 0, 0.06) !important;
    color: rgba(97, 97, 97, 1) !important;
}
.product_detail_variant_invent {
    padding: 8px 0;
    border-bottom: 1px solid rgba(235, 235, 235, 1);
}
.product_detail_variant_price {
    padding: 8px 0;
    border-bottom: 1px solid rgba(235, 235, 235, 1);
}
.product_detail_variant_price_fav {
    cursor: pointer;
}
.product_detail_variant_price strong {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}
.product_detail_variant_sku {
    padding: 8px 0;
}
.product_detail_variant_sku .product_sku_attr_item {
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    margin: 0;
    border-bottom: 1px solid rgba(235, 235, 235, 1);
}
.product_detail_variant_sku .product_sku_attr_title {
    font-weight: 650;
}
.product_detail_variant_cart_list {
    padding: 8px 0;
    border-bottom: 1px solid rgba(235, 235, 235, 1);
}
.product_detail_variant_cart_list button {
    height: 48px;
}
.product_detail_variant_price_fav {
    margin-right: 16px;
}
.product_detail_variant_price_fav:hover .product_detail_variant_price_fav_show {
    display: none
}
.product_detail_variant_price_fav_hover {
    display: none
}
.product_detail_variant_price_fav_btn {
    cursor: pointer;
    height: 20px;
}
.product_detail_variant_price_fav:hover .product_detail_variant_price_fav_hover {
    display: block
}
.product_detail_variant_price_fav_active svg path {
    fill: rgba(229, 28, 0, 1);
}
.product_detail_variant_price_fav_show svg {
    fill: rgba(227, 227, 227, 1);
}
.product_detail_variant_cart_list button span {
    font-weight: 700;
}
.product_detail_variant_shipping {
    padding: 8px 0;
    /* border-bottom: 1px solid rgba(235, 235, 235, 1); */
}
.product_detail_variant_credit {
    margin-top: 8px;
    border-radius: 8px;
    padding: 6px;
    width: max-content;
    min-height: 40px;
    background-color: rgba(241, 241, 241, 1);
    border: 1px solid rgba(227, 227, 227, 1);
}
.product_detail_variant_credit p {
    font-size: 14px;
    font-weight: 650;
    line-height: 20px;
    font-weight: 650;
    margin: 0 8px;
}
.product_detail_variant_credit button {
    min-width: 82px;
}
.product_detail_variant_credit button span {
    font-weight: 650;
}
.product_detail_variant_shipping_ware {
    margin-bottom: 10px;
}
.product_detail_variant_shipping_ware span {
    font-size: 13px;
    font-weight: 650;
    line-height: 20px;
    color: rgba(118, 118, 118, 1);
}
.product_detail_variant_shipping_day {
    margin: 10px 0 0;
}
.shipping_none {
    margin-top: 8px;
}
.product_detail_variant_shipping_day p {
    flex: 1;
    margin-left: 5px;
    font-size: 12px;
    font-weight: 450;
    line-height: 20px;
    color: rgba(48, 48, 48, 1);
}
.product_detail_variant_sku .item_value {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    max-width: 200px;
    text-align: center;
    word-break: break-word;
    padding: 0 8px;
    line-height: 30px;
    border: 1px solid #eee;
    border-radius: 8px;
    cursor: pointer;
    margin: 5px 8px 0 0 ;
    overflow: hidden;
    background-color: rgb(241, 241, 241);
}
.product_detail_variant_sku .item_value:hover {
    border-color: rgba(0, 91, 211, 1);
}
.product_detail_variant_sku .item_value_select {
    border-color: rgba(0, 91, 211, 1);
}
.descript_p {
    padding: 10px 16px;
}
.product_review{
    padding: 0 16px;
}
.descript_p img {
    max-width: 100%;
}
.product_review_item {
    padding: 8px 12px;
    border-bottom: 1px solid rgba(235, 235, 235, 1);
}
.product_review_item_avt {
    width: 40px;
    height: 40px;
    font-weight: 650;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin-right: 12px;
    color: rgba(121, 26, 121, 1);
    background-color: rgba(251, 207, 251, 1);
}
.product_review_item_name strong {
    font-size: 13px;
    font-weight: 650;
    line-height: 20px;
    color: rgba(48, 48, 48, 1);
}
.product_review_item_day {
    font-size: 13px;
    font-weight: 450;
    line-height: 20px;
    color: rgba(181, 181, 181, 1);
}
.product_review_item_desc {
    margin-top: 16px;
    font-size: 13px;
    font-weight: 450;
    line-height: 20px;
    color: rgba(48, 48, 48, 1);
}
.product_similar_content {
    width: 100%;
    margin-bottom: 24px;
}
.product_similar_content .product_commone_content_list {
    border: 0 !important;
}
.product_similar_content_head {
    padding: 16px 16px 0 16px;
}
#scrollableDiv.Polaris-Scrollable:focus {
    outline:0;
}

.swiper-button-prev,
.swiper-button-next {
  width: 40px;
  height: 40px;
  border-radius: 50%; /* 圆形按钮 */
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; /* 图标颜色 */
  font-size: 20px; /* 图标大小 */
  cursor: pointer;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: rgba(0, 0, 0, 0.8); /* 鼠标悬停效果 */
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: ""; /* 移除默认箭头 */
}

.swiper-button-prev {
  left: 10px; /* 调整位置 */
}

.swiper-button-next {
  right: 10px; /* 调整位置 */
}
.swiper-button-white svg {
    fill: white;
}
.product_review_box .Polaris-Layout__Section--oneThird{
    position: sticky;
    top: 56px;
    left: 0;
}
.product_review_box_iframe .Polaris-Layout__Section--oneThird{
    position: sticky;
    top: 0;
    left: 0;
}
.product_variant_description_review {
    width: 100%;
}
@media screen and (max-width: 420px) {
    .product_info_image_box .details_product {
        display: none;
    }
    .product_info_image_box {
        width: 100% !important;
    }
    .details_product {
        width: 100% !important;
    }
    .product_variant_left {
        width: 100%;
    }
    .product_info_image_box .product_info_swiper_detail , .product_info_image_box .product_info_swiper_detail .swiper-slide {
        width: 100% !important;
        max-height: 300px;
        min-height: 300px;
    }
    .product_info_swiper_big {
        width: 100% !important;
    }
    .product_info_image_box .product_info_swiper img {
        height: auto !important;
    }
    .product_info_swiper_detail > .swiper {
        width: 100% !important;
        height: 100% !important;
        min-height:100% !important;
    }
    .detail_top_left_img_list {
        display: none;
    }
    .detail_top_left {
        width: 100% !important;
        height: 100%;
    }
    .detail_top_left .detail_top_left_img_big div{
        height: 270px;
    }
    .detail_top_right_desc {
        width: 100% !important;
    }
    .detail_top_right_review {
        width: 100% !important;
    }
    .product_similar_content {
        margin-bottom: 100px;
    }
}
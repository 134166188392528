.ReviewsItem{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
}

.ReviewsItem .left{
    margin-right: 10px;
    min-width: 65px;
}

.ReviewsItem .left .name{
    font-weight: 500;
    padding-bottom: 5px;
}

.ReviewsItem .left .country{
    display: flex;
    align-items: center;
}

.ReviewsItem .left .country .code{
    font-weight: 500;
    color: #acaeb1;
    margin-left: 6px;
}

.ReviewsItem .right{
    margin-left: 20px;
}

.ReviewsItem .right .star{
    padding: 10px 0;
}

.ReviewsItem .right .feedback{
    margin-bottom: 5px;
}

.ReviewsItem .right .img_list {
    padding: 5px 0;
}

.ReviewsItem .right .img_list img{
    height: 60px;
    border-radius: 5px;
    margin-right: 10px;
}

.spinner_center{
    text-align: center;
    padding: 2rem;
}

.ReviewsItem_Empty{
    font-weight: 500;
    text-align: center;
    padding: 2rem;
}
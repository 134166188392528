.dropshipman_auth_main{
    height: 100%;
    padding: 2rem;
    display: grid;
    align-items: center;
    justify-items: center;
    place-items: center;
    /* background-color: #2db39e; */
    background: url('/public/auth_bg.png') no-repeat 50%;
    background-size: cover;
}

.auth_log{
    position: absolute;
    left: 30px;
    top: 20px;
}

.auth_log img{
    width: 150px;
}

.auth_box{
    /* display: flex; */
    width: 568px;
    max-height: 600px;
    box-sizing: border-box;
    padding: 45px;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 8%), 0 1px 10px 0 rgb(0 0 0 / 4%), 0 4px 5px 0 rgb(0 0 0 / 6%);
    border-radius: 12px;
    background-color: #fff;
    margin: 0 auto;
}

.auth_title{
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 700;
    color: rgba(0,0,0,.88);
}

.auth_password, .reset_password{
    cursor: pointer;
}

.auth_button{
    margin-top: 3rem;
}

@media (max-width: 700px){
    .auth_box{
        width: 100%;
    }
}

@media (max-width: 450px){
    .auth_box{
        padding: 35px 20px;
    }
}

.auth_help{
    font-size: 14px;
}

.auth_help a{
    color: #3131f3;
}

.auth_text{
    margin-bottom: 6px;
}

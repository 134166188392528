.top_drawer_content_desc_time {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.top_drawer_content_desc_time p {
    margin: 0 !important;
}
.time_close_box .sub_paid_time_detail_list_modal{
    padding: 0 0 0 8px !important;
}
.time_close_box .black_discount_modal_time {
    width: 30px !important;
    height: 30px !important;
    font-size: 16px !important;
    padding: 0 !important;
    color: #303030;
    font-weight: 650 !important;
    background-color: #F0F7FF !important;
    text-align: center;
    line-height: 30px !important;
}
.time_close_box  .sub_paid_time_detail_list_modal {
    background-color: transparent !important;
}
.top_drawer_content_desc_time_sub {
    flex-direction: column;
}
.top_drawer_content_desc_time_sub .time_close_box {
    margin-top: 8px;
}
@media screen and (max-width: 600px) {
    .top_drawer_content_desc_time {
        justify-content: center;
    }
    .black_friday_content_top_banner_box_desc {
        display: none;
    }
    .black_friday_content_top_banner_box .top_drawer_content_desc_time {
        justify-content: flex-start;
        margin: 0 !important;
    }
    .top_drawer_content_desc_time_sub .top_drawer_content_time_sub{
        margin-bottom: 0 !important;
    }
    .top_drawer_content_desc_time_sub .time_close_box {
        margin-bottom: 16px;
    }
}

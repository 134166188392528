.Search_Loading {
    position: absolute;
    /* background-color: #fff; */
    opacity: 0.5;
    top: 0;
    height: 100%;
    width: 100%;
    min-height: 100vh;
    text-align: center;
    z-index: 500;
  }
  
  .Search_Loading_Image {
    position: absolute;
    left: 50%;
    top: 20%;
  }
  
  .Search_Loading_Image img {
    width: 32px !important;
    height: 32px !important;
    vertical-align: middle;
  }

/* 站内信 */
.message-title {
    font-size: 15px;
    font-weight: 600;
    color: #637381;
    line-height: 20px; 
    margin: 5px 0;
}
.massage_page_list {
    min-height: 600px;
    max-height: 600px;
    padding: 0 16px 0 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.message-time {    
    font-size: 12px;
    font-weight: 400;
    color: #637381;
    line-height: 16px; 
    margin: 5px 0;
    margin-bottom: 15px;
}

.message-no-read {
    color: #2c6ecb;
}

.message-icon {
    width: 10px;
    height: 10px;
    margin: 4px 2px;
    top: 3px;
    position: relative;
    border-radius: 50%;
    border: 2px solid #c4cdd5;
    /* border: 2px solid #4609f0; */
}

.message-icon-no-read {
    width: 10px;
    height: 10px;
    margin: 4px 2px;
    top: 3px;
    position: relative;
    border-radius: 50%;
    background: #006fbb;
    border: 2px solid #006fbb;
}

.message_list_item_parent {
    display: flex;
    cursor: pointer;
}

.message_list_item_left {
    width: 5px;
    margin-top: 3px;
}

.message_list_item_right {
    width: 25px;
    margin-top: 3px;
}

.message_list_item_center {
    flex: 1; 
    cursor: pointer;
    margin: 0 25px;
    margin-right: 10px;  
    margin-bottom: -10px;  
}

/* .message_content {
    margin-bottom: 10px;
} */

.message_empty .Polaris-EmptyState__Image{
    height: 150px;
}
.message_empty img {
    max-width: 200px;
}


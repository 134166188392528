.col_box {
  display: flex;
  justify-content: space-between;
}
.col_box_left_top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.col_box_right {
  width: 136px;
}
.col_box_right img {
  width: 100%;
}
.col_box_left_top_icon {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #303030;
  border-radius: 50%;
  margin-right: 8px;
}
.col_box_left_top_icon svg {
  fill: #fff;
}
.col_box_left_top_levels {
  margin-left: 8px;
  font-size: 12px;
  display: flex;
  color: #fff;
  align-items: center;
  font-weight: 600;
  background-color: #202333;
  border-radius: 30px;
  padding: 1px 8px;
}
.col_box_left_top_levels img {
  width: 20px;
  margin-right: 4px;
}
.col_box_left h6 {
  margin: 8px 0;
}
@media screen and (max-width: 768px) {
  .col_content {
    margin: 0 16px;
  }
  .col_box_right {
    width: 95px;
  }
}


/* new */
.Custom_Service_Page .Polaris-Page {
  max-width: 1200px;
}

.Custom_Service_Page .Custom_Service_Content {
  margin-top: 40px;
}

.Custom_Service_Page .lazyload-wrapper {
  height: 100%;
}

/* custom packaging */
.custom_packaging_card {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  width: 100%;
  border-radius: 30px;
}

.custom_packaging_card .item {
  box-sizing: border-box;
}

.custom_packaging_card .left-item {
  position: relative;
  width: 52%;
  /* width: 600px !important; */
  /* height: 330px; */
}

.custom_packaging_card .right-item {
  flex: 1;
  padding: 30px;
}

.custom_packaging_card .right-item .subject {
  max-width: 374px;
  font-size: 20px;
  font-weight: 650;
  line-height: 24px;
}

.custom_packaging_card .right-item .Polaris-List__Item {
  list-style: initial;
}

.custom_packaging_card .right-item .ul-list{
  margin-left: 10px;
}

.custom_packaging_card .right-item .button {
  margin-top: 40px;
}

.custom_packaging_card .left-item .responsive-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.custom_packaging_card .left-item .overlay-image {
  position: absolute;
  top: 29%;
  left: 40%;
  transform: translate(-50%, -50%);
  width: 260px;
  height: auto;
}

/* Private Storage */
.private_storage_box {
  margin: 1.8rem 0;
  display: flex;
  flex-wrap: wrap; /* 允许换行 */
  width: 100%;
}

.private_storage_box .left-side{
  width: 70%;
}

.private_storage_box .right-side{
  flex: 1;
  margin-left: 20px;
  background-color: #fff;
  padding: 30px;
  border-radius: 30px;
}

.private_storage_box .right-side .icon {
  display: inline-block;
}

.private_storage_box .right-side .icon .Polaris-Icon {
  width: 26px;
  height: 26px;
}

.private_storage_box .right-side .title {
  font-size: 20px;
  font-weight: 650;
  line-height: 24px;
}

.private_storage_box .right-side .button {
  margin-top: 30px;
}

.private_storage_card {
  display: flex;
  flex-wrap: wrap; /* 允许换行 */
  width: 100%;
  border-radius: 30px;
  background-color: #fff;
}

.private_storage_card .side {
  box-sizing: border-box;
}

.private_storage_card ._left{
  flex: 1;
  padding: 30px;
}

.private_storage_card ._left .subject {
  font-size: 20px;
  font-weight: 650;
  line-height: 24px;
}

.private_storage_card ._left .button {
  margin-top: 30px;
}

.private_storage_card ._right {
  width: 47%;
}

.private_storage_card ._right .private-storage-image {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 100%;
  width: 100%;
}


/* 小屏幕下的样式 */
@media (max-width: 1024px) {
  .custom_packaging_card .item {
    flex: 1 1 100%;
  }

  .custom_packaging_card .left-item .responsive-image {
    border-top-left-radius: 10px;
    border-bottom-left-radius: initial;
    border-top-right-radius: 30px;
  }

  .private_storage_box .left-side {
    width: 100%;
  }

  .private_storage_box .right-side {
    margin-top: 20px;
    margin-left: unset;
    max-width: 325px;
  }
}
@media screen and (min-width: 1024px)  and (max-width: 1366px) {
  .custom_packaging_card .right-item .subject {
    font-size: 16px;
  }
  .custom_packaging_card .right-item .button {
    margin-top: 10px;
  }
  .custom_packaging_card .right-item {
    padding: 16px;
  }
  .private_storage_card ._left {
    padding: 16px;
  }
  .private_storage_box .right-side {
    padding: 16px;
  }
  .private_storage_box .right-side .title {
    font-size: 16px;
  }
  .private_storage_box .right-side .button {
    margin-top: 8px;
  }
  .private_storage_card ._left .subject {
    font-size: 16px;
  }
  .custom_packaging_card .left-item .overlay-image {
    position: absolute;
    top: 20%;
    left: 45%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: auto;
  }
}
@media (max-width: 715px) {
  .private_storage_card .side {
    flex: 1 1 100%;
  }

  .private_storage_box .right-side {
    max-width: unset;
    height: auto;
  }

  .private_storage_card ._left .subject, .custom_packaging_card .right-item .subject, .private_storage_box .right-side .title {
    font-size: 17px;
  }
  .private_storage_card ._right {
    display: none;
  }
  
}
@media screen and (max-width: 480px) {
  .custom_packaging_card .left-item .overlay-image {
    width: 120px !important;
  }
}
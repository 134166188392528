/* order */
.order_search_content .order_search_content_box {
    display: flex;
    width: 100%;
}
.order_search_button {
    display: flex;
}
.order_tracking_no {
    display: flex;
    align-items: center;
    min-width: 140px;
  }
  .order_tracking_no_button button {
    padding: 8px 16px !important;
  }

.order_search_filter {
    min-width: 152px;
}
.order_search_input {
    margin: 0 16px;
    min-width: 360px;
}
.order_search_filter_time {
    max-width: 250px;
}
.order_search_filter_time input{
    border: 0;
    outline: 0;
    height: 30px;
    width: 100%;
    border-radius: 5px;
}
.time_modal_content_box {
    display: flex;
    align-items: center;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
}
.order_search_button button {
    min-width: 80px;
}

.order_item_collapsible {
    border-top: 1px solid #e8e8e8;
}
.order_item_list_box {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}

.collapsible_box_item_img .image_box_overlay {
    width: 58px;
    overflow: hidden;
    background-color: rgba(46, 66, 87, 0);
    transition: background-color 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
    position: relative;
    cursor: pointer;
}
.collapsible_box_item_img .image_box_overlay:hover::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(46, 66, 87, 0.5);
    transition:background-color 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.collapsible_box_item_img .image_box_overlay .image_preview_action{
    position: relative;
}
.collapsible_box_item_img .image_box_overlay .image_preview_action .image_preview__svg {
    position: absolute;
    right: 4px;
    top: 2px;
    z-index: 11;
    transform: translateY(-30px);
    transition: transform 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
}
.collapsible_box_item_img .image_box_overlay:hover .image_preview_action .image_preview__svg {
    transform: translateY(0px);
    transition: all 0.5s;
}
.collapsible_box_item_img .list_item_image {
    width: 58px;
    height: 58px;
    display: inline-block;
}

.order_item_brand {
    width: 40%;
}
.order_item_brand_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.order_item_collapsible_box_item {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    background-color: #f5f5f5;
    padding: 12px;
    margin-bottom: 15px;
    margin-top: 15px;
}
.order_item_brand_id {
    display: flex;
    align-items: center;
    min-width: 150px;
}
.order_item_brand_id img {
    margin-right: 4px;
}
.order_item_checkbox {
    margin-right: 10px;
}
.order_item_customer {
    width: 10%;
    text-align: center;
    margin:0 16px;
}
.order_item_shipping {
    width: 10%;
    text-align: center;
    margin:0 16px;
}
.order_item_shipping .Polaris-Button__Text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: keep-all;
}

.order_item_button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.order_item_button div {
    margin-right: 10px;
}
.order_item_button div:nth-last-of-type(1) {
    margin: 0;
}
.collapsible_box_item_button {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}
.collapsible_box_item_title {
    margin: 0 24px;
    width: 30%;
}
.collapsible_box_item_title > div{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    width: 240px;
}
.collapsible_box_item_cost , .collapsible_box_item_total{
    width: 15%;
}
.collapsible_box_item_bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.order_item_brand_status {
    min-width: 210px;
    display: flex;
}
.order_item_brand_status span {
    margin-right: 8px;
}
.collapsible_box_item_bottom_clo {
    margin-right: 10px;
}
.collapsible_box_item_refund span{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
@media screen and (max-width: 768px) {
    .page_container {
        margin: 0 10px;
    }
    .order_item_list_box {
        flex-wrap: wrap;
      }
      .order_item_brand {
        width: 80% !important;
      }
      .order_item_brand_box {
        flex-wrap: wrap;
        margin-bottom: 10px;
      }
      .order_item_brand_status {
        margin-top: 10px;
      }
      .order_item_collapsible_box_item {
        flex-wrap: wrap !important;
      }
      .order_item_customer ,.order_item_shipping{
        width: 25% !important;
      }
      .collapsible_box_item_title {
        width: 55% !important;
        margin: 0 20px;
      }
      .collapsible_box_item_title > div {
        width: 100%;
      }
      .collapsible_box_item_cost, .collapsible_box_item_total {
        width: 50% !important;
        margin-top: 16px;
      }
      .collapsible_box_item_bottom {
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .collapsible_box_item_bottom_clo {
        width: 50% !important;
        margin-right: 0 !important;
      }
      .order_tracking_no {
        margin: 10px 00;
      }
    .order_tracking_no {
        width: 100% !important;
    }
}
@media screen and (min-width:769px) and (max-width: 1024px) {
    .order_item_list_box {
        flex-wrap: wrap;
    }
    .order_item_brand {
        width: 90%;
    }
    .order_item_customer,.order_item_shipping {
        width: 20%;
        margin-top: 16px;
    }
    .order_item_collapsible_box_item {
        flex-wrap: wrap;
    }
    .collapsible_box_item_title {
        width: 70%;
    }
    .order_item_brand_box {
        flex-wrap: wrap;
    }
    .order_item_brand_status {
        margin-top: 10px;
    }
    .collapsible_box_item_cost, .collapsible_box_item_total {
        width: 25% !important;
        margin-top: 10px;
    }
    .collapsible_box_item_bottom {
        flex-wrap: wrap;
    }
    .collapsible_box_item_bottom_clo {
        width: 50% !important;
        margin-right: 0 !important;
    }
    .order_tracking_no {
        flex: 1;
    }
}

/* order end */


/* quantily */
.my_packing_list .Polaris-IndexTable__TableHeading {
    text-align: center;
}
.packing_quantily_item {
    padding: 16px 0;
    text-align: center;
}
/* quantily end */


/* all packing */
.all_packing_list {
    display: flex;
    flex-wrap: wrap;
}
.all_packing_list_item {
    width: calc(100% / 7 - 18px);
    /* width: 210px; */
    margin-right: 21px;
    margin-bottom: 24px;
    border-radius: 10px;
    background-color: #fff;
    cursor: pointer;
    position: relative;
    box-shadow: 0rem 0.125rem 0.25rem rgba(31,33,36,.1), 0rem 0.0625rem 0.375rem rgba(31,33,36,.05);
}

.all_packing_list_item:hover .all_packing_list_item_button{
    display: flex;
}
.all_packing_list_item_img {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    /* box-shadow: 0rem 0.125rem 0.25rem rgba(31,33,36,.1), 0rem 0.0625rem 0.375rem rgba(31,33,36,.05); */
}
.my_packing_list .Polaris-IndexTable__StickyTableHeader--isSticky ,.purchase_list .Polaris-IndexTable__StickyTableHeader--isSticky  ,.history_table .Polaris-IndexTable__StickyTableHeader--isSticky{
    visibility: hidden !important;
}
@keyframes zoomIn {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.1);
    }
  }
  
  @keyframes zoomOut {
    from {
      transform: scale(1.1);
    }
    to {
      transform: scale(1);
    }
  }
  
  .zoom-in {
    animation: zoomIn 0.5s forwards;
  }
  
  .zoom-out {
    animation: zoomOut 0.5s forwards;
  }
  
.all_packing_list_item_img:hover img{
    animation: zoomIn 0.5s forwards;
}
.all_packing_list_item_img {
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.all_packing_list_item_img img{
    max-width: 100%;
    max-height: 100%;
}
.all_packing_list_item_text {
    height: 40px;
    overflow: hidden;
    /* white-space: nowrap; */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: keep-all;
}
.all_packing_list_item_box {
    padding: 16px;
}
.all_packing_list_item_button {
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -40px;
    left: 0;
    z-index: 99;
    width: 100%;
    display: none;
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0rem 0.125rem 0.25rem rgba(31,33,36,.1), 0rem 0.0625rem 0rem rgba(31,33,36,.05);
}
.all_packing_list_item_button button {
    width: 100%;
    margin: 10px 24px;
    height: 36px;
}

.all_packing_list_item_price {
    font-size: 20px;
    font-weight: 600;
    margin-top: 8px;
}
.all_packing_list_item_price span {
    font-size: 12px;
    font-weight: 700;
}

/* end all packing */

@media screen and (min-width: 1801px) {
    .all_packing_list_item:nth-child(7n) {
        margin-right: 0;
    }
}
@media screen and (min-width: 1600px) and (max-width: 1800px) {
    .all_packing_list_item {
        width: calc(100% / 6 - 18px);
    }
    .all_packing_list_item:nth-child(6n) {
        margin-right: 0;
    }
    .order_item_list .order_item_brand {
        width: 40%;
    }
}
@media screen and (min-width: 1367px) and (max-width: 1599px) { 
    .all_packing_list_item {
        width: calc(100% / 5 - 18px);
    }
    .all_packing_list_item:nth-child(5n) {
        margin-right: 0;
    }
    .order_item_list .order_item_brand {
        width: 48%;
    }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
    .all_packing_list_item {
        width: calc(100% / 4 - 16px);
    }
    .all_packing_list_item:nth-child(4n) {
        margin-right: 0;
    }
    .order_item_list .order_item_brand {
        width: 60%;
    }
}
@media screen and (min-width: 960px) and (max-width: 1023px) {
    .all_packing_list_item {
        width: calc(100% / 3 - 14px);
    }
    .all_packing_list_item:nth-child(3n) {
        margin-right: 0;
    }
}
@media screen and (min-width: 768px) and (max-width: 960px) {
    .all_packing_list_item {
        width: calc(100% / 2 - 11px);
    }
    .all_packing_list_item:nth-child(2n) {
        margin-right: 0;
    }
}
@media screen and (min-width: 500px) and (max-width: 767px) {
    .my_packing_list {
        margin: 0 10px;
    }
    .all_packing_list_item {
        width: calc(100% / 2 - 11px);
    }
    .all_packing_list_item:nth-child(2n) {
        margin-right: 0;
    }
    .brand_search_container .search_container_filter {
        flex-direction: column;
    }
    .search_container_filter .search_container_input {
        width: 100%;
        margin-top: 16px;
    }
    .search_container_order_btn {
        margin-top: 10px;
    }
    .my_packing_list .Polaris-IndexTable__Table--sticky .Polaris-IndexTable__TableHeading--second.Polaris-IndexTable__TableHeading--unselectable ,.my_packing_list .Polaris-IndexTable__Table--unselectable.Polaris-IndexTable__Table--sticky .Polaris-IndexTable__TableCell:first-child {
        position: relative !important;
    }
    .packing_quantily_item.packing_quantily_item_product {
        width: 200px;
    }
}
@media screen and (max-width: 499px) {
    .pageination_container  nav {
        margin-bottom: 16px;
    }
    .all_packing_list_item_price {
        font-size: 16px;
    }
    .list_item_top_cost {
        width: 30% !important;
    }
    .list_item_top_status_imoprt {
        width: 120px !important;
    }
    .my_packing_list .Polaris-IndexTable__Table--sticky .Polaris-IndexTable__TableHeading--second.Polaris-IndexTable__TableHeading--unselectable ,.my_packing_list .Polaris-IndexTable__Table--unselectable.Polaris-IndexTable__Table--sticky .Polaris-IndexTable__TableCell:first-child {
        position: relative !important;
    }
    .packing_quantily_item.packing_quantily_item_product {
        width: 200px;
    }
    .brand_search_container .search_container_filter {
        flex-direction: column;
    }
    .search_container_filter .search_container_input {
        margin-top: 16px;
    }
    .search_container_filter .search_container_input,.search_container_filter .search_type {
        width: 100% !important;
    }
    .search_container_order_btn {
        margin-top: 10px;
    }
    .three_search_btn {
        margin: 16px 0 !important;
    }
    .pageination_container {
        flex-direction: column;
        align-items: center;
        margin: 0 10px;
    }
    .pageination_container .Page_Jump_input {
        margin-top: 16px;
        text-align: center;
    }
    .tab_container {
        margin: 0 10px;
    }
    .brand_search_container {
        flex-direction: column;
    }
    .search_container_btn {
        margin-top: 16px;
    }
    .upload_branding_button {
        justify-content: flex-start;
        margin-left: 10px;
    }
    .all_packing_list_item {
        width: calc(100% / 2 - 8px);
        margin-right: 16px;
        margin-bottom: 16px;
    }
    .all_packing_list_item:nth-child(2n) {
        margin-right: 0;
    }
    .all_packing_list_item_button {
        display: flex;
        position: relative !important;
        bottom: 0;
    }
    .all_packing_list_item_img {
        height: 100px;
    }
    .all_packing_list_item_button button {
        margin: 0 10px 10px;
        height: auto;
    }
}
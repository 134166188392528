.order_customer_info{
    position: relative;
}

.order_customer_info .customer_info_error{
    position: absolute;
    top: -10px;
    right: -6px;
}

.order_customer_info .customer_info_error img{
    width: 22px;
    height: 22px;
}
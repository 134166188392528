.Notifications_Table table{
    overflow: auto;
}
.Notifications_Table>.Polaris-Card{
    overflow: auto;
}

.Notifications_Table table>tbody td{
    font-size: 14px;
    padding: 5px 8px;
}

.Notifications_Table table>tbody td:first-child{
    padding-left: 25px;
}

.Notifications_Table table>tbody tr{
    padding-bottom: 10px;
    border-top: 1px solid #e6e6e6;
} 

.Notifications_Table{
    margin: 2rem 0;
}
.Notifications_Table table>thead th{
    text-align: left;
    font-size: 14px;
    padding: 20px 0 20px 10px;
    font-weight: 600;
}

.Notifications_Table table>tbody tr:hover{
    background-color: #f9fafb;
} 

.Notifications_Table .Product_Title{
    min-width: 350px;
    font-weight: 500;
}
.Notifications_Table .Update_Time{
    min-width: 150px;
}
.Notifications_Table .Change_Status{
    min-width: 170px;
}
.Notifications_Table .Action{
    min-width: 160px;
}

.Notifications_Table .Product_Title .Polaris-Button__Text{
    color: #2c6ecb !important;
}

.Change_Status.Product_Unavailable .Polaris-Badge{
    background-color: #fead9a;
}

.Select_More{
    margin: auto 10px;
}

.headings_div__1.notifications{
    width: 100%;
    display: flex;
}

@media (max-width: 980px){
    .headings_div__1.notifications{
        display: unset;
        width: 0px;
    }
    .Select_More{
        margin-top: 10px;
    }
}

.Original_Product_Box{
    display: flex;
    padding: 10px 0;
    margin: 15px 0;
    background-color: #f7f4f4;
    border-radius: 3px;
}

.Original_Product_Box .Original_Product_Image{
    margin: auto 15px;
}

.Original_Product_Box .Original_Product_Title{
    margin: auto 15px;
    width: 50%;
}

.Original_Product_Box .Original_Product_Inventory{
    margin: auto;
}

.Variant_Modal_Table{
    margin-top: 2rem;
}

.Variant_Modal_Table table{
    overflow: auto;
}

.Variant_Modal_Table table>tbody tr{
    padding-bottom: 10px;
    border-top: 1px solid #e6e6e6;
} 

.Variant_Modal_Table table>tbody tr:hover{
    background-color: #f9fafb;
} 

.Variant_Modal_Table table>thead th{
    text-align: left;
    font-size: 14px;
    padding: 20px 0 20px 10px;
    font-weight: 600;
}

.Variant_Modal_Table table>tbody td{
    font-size: 14px;
    padding: 8px;
    min-width: 100px;
}

.Price_Increase .Conversion_Price,.Inventory_Quantity_Reduction{
    color: rgb(247, 35, 35);
}

.Price_Reduction .Conversion_Price,.Inventory_Quantity_Increase{
    color: rgb(70, 179, 119);
}
.Variant_Modal_Table .Variant_Sku{
    min-width: 180px;
}

.Variant_Modal_Table .Variant_Old_Cost,.Variant_Modal_Table .Variant_New_Cost, .Variant_Modal_Table .Price_Reduction{
    min-width: 100px;
}

.Variant_Modal_Table .Variant_Price,.Variant_Modal_Table .Variant_Compare_Price{
    min-width: 150px;
    max-width: 200px;
}

.Variant_Modal_Table .Variant_Old_Inventory_Quantity,.Variant_Modal_Table .Variant_New_Inventory_Quantity, .Variant_Modal_Table .Inventory_Quantity_Reduction{
    min-width: 150px;
}

.Notifications__Loading{
    position: absolute;
    background-color: #fff;
    opacity: 0.5;
    top: 0;
    height: 100%;
    width: 100%;
    text-align: center;
}

.Notifications__Loading_Image{
    position: absolute;
    left: 50%;
    top: 50%;
}

.Polaris-New-Pagination{
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: auto;
    padding-top: 2.4rem;
}
.Dropshipman_App_Recommend{
  padding-bottom: 16px;
  margin-top: 10px;
}
.App_Recommend_content {
  display: flex;
  flex-wrap: wrap;
}
.App_Recommend_content .App_Recommend_Link {
  width: calc(100% / 3 - 16px);
  margin-right: 16px;
  margin-bottom: 16px;
  margin-top: 0 !important;
}
/* .App_Recommend_content .App_Recommend_Link:nth-child(3n-3) {
  margin-right: 0;
} */
.App_Recommend_content .App_Recommend_Item .Top .App_Name .title {
  height: 40px;
}
.Dropshipman_App_Recommend .carousel.carousel-slider{
  overflow: inherit;
}

.Dropshipman_App_Recommend .control-dots{
  bottom: -16px;
}

.Dropshipman_App_Recommend .control-dots .dot{
  background: #dfdfdf;
}

.Dropshipman_App_Recommend .control-arrow{
  display: none;
}

.App_Recommend_Item{
  background-color: rgba(247, 247, 247, 1);
  padding: 5px;
  cursor: pointer;
  border-radius: 12px;
}

.App_Recommend_Link a{
  color: unset;
}
.Dropshipman_App_Recommend .dot {
  width: 20px !important;
  height: 2px !important;
  border-radius: 0 !important;
}
.Dropshipman_App_Recommend .carousel .control-dots .dot.selected {
  width: 30px !important;
  background-color: #000;
}
.App_Recommend_Link + .App_Recommend_Link{
  margin-top: 1.6rem;
}

.App_Recommend_Item .Top{
  display: flex;
  align-items: center;
  padding: 10px 10px 15px 10px;
}
.App_Recommend_Item .Top .lazyload-wrapper {
  width: 50px;
}
.elite_partner_span:hover {
  color: #4949e3;
}
.elite_partner_span {
  white-space: nowrap;
}

.App_Recommend_Item .Top img{
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

.App_Recommend_Item .Top .App_Name .star_box{
  display: flex;
  align-items: center;
}

.App_Recommend_Item .Top .App_Name .star_box span{
  font-size: 12px;
  font-weight: 500;
  margin-right: 5px;
}

.App_Recommend_Item .Top .App_Name .star{
  width: 13px;
  height: 13px;
  margin-right: 3px;
}

.App_Recommend_Item .Top .App_Name{
  text-align: left;
  margin-left: 15px;
  flex: 1;
}

.App_Recommend_Item .Top .App_Name .title{
  font-weight: 600;
  font-size: 14px;
}

.App_Recommend_Item .Bottom{
  /* border-top: 1px solid #e5e5e5; */
  padding: 8px 0 5px 10px;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  min-height: 53px;
}
.App_Recommend_Item .Bottom span{
  color: #5c5f62;
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
}
.elite_partner {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 10px; */
}
.elite_partner div {
  font-size: 16px;
  font-weight: 600;
}
@media screen and (max-width:768px) {
  .App_Recommend_content .App_Recommend_Link {
    width: 100% !important;
    margin-right: 0 !important;
    margin-top: 16px !important;
  }
  .elite_partner div {
    font-size: 14px;
    font-weight: 600;
  }
  .Dropshipman_App_Recommend {
    padding: 0 20px;
  }
}
@media screen and (min-width:768px) and (max-width:1024px) {
  .App_Recommend_content .App_Recommend_Link {
    width: calc(100% / 2 - 16px);
    margin-right: 16px;
    margin-top: 16px !important;
  }
}
@media screen and (min-width:1025px) and (max-width:1730px) {
  .App_Recommend_content .App_Recommend_Link {
    width: calc(100% / 3 - 16px);
    margin-right: 16px;
    margin-top: 16px !important;
  }
  
}
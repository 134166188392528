.Product_Info{
    display: inline-block;
    width: 100%;
    min-height: 350px;
}

.Product_Info_Img img{
    max-width: 95%;
    border-radius: 3px;
    border: 1px solid #ccc;
}

.Product_Info_Img{
    display: inline-block;
    width: 20%;
    margin-right: 15px;
    margin-top: 2rem;
    vertical-align: top;
}

.Product_Info_Detail{
    display: inline-block;
    width: 75%;
    margin-left: 15px;
}

@media screen and (max-width: 945px){
    .Product_Info_Img{
        width: 30% !important;
    }
    
    .Product_Info_Detail{
        width: 100% !important;
        margin-left: unset !important;
    }
}

.Product_Info_Tag{
    margin-top: .5rem;
}

.Product_Info_Tag > .Polaris-Tag:first-child{
    margin-right: 8px;
}
  
span.Polaris-Tag + span.Polaris-Tag {
    margin-right: 8px;
    margin-bottom: 5px;
}

.Product__Add_tag{
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.Product__Add_tag Svg{
    fill: #5c6ac4;
    width: 18px;
    height: 18px;
    font-weight: 500;
}

/* li.Polaris-OptionList-Option .Polaris-OptionList-Option--select{
    background-color: #5c6ac4;
    color: #fff;
} */




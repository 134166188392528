.points_top {
    display: flex;
    justify-content: space-between;
}

.points_top_left {
    display: flex;
    width:67%;
}
.points_top_left div {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
}
.points_top_left div span {
    font-weight: 550;
   /* border-bottom: 1px dashed rgba(227, 227, 227, 1); */
}
.points_top_left div strong {
    font-size: 20px;
    display: flex;
    margin-top: 10px;
}
.points_top_left div strong  span {
    border: 0;
}
.points_top_left div:nth-child(1):hover  strong , .points_top_left div:nth-child(1):hover  strong svg{
    cursor: pointer;
    color: rgba(0, 122, 255, 1);
    fill: rgba(0, 122, 255, 1);
}
.points_top_left div:nth-of-type(2) {
    padding-left: 24px;
    border-left: 1px solid rgba(227, 227, 227, 1);
}

.points_top_right {
    display: inline-flex;
    flex: 1;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 24px;
    border-left: 1px solid rgba(227, 227, 227, 1);
}
.points_top_right div {
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
}
.points_top_right div:nth-of-type(2) {
    margin-top: 16px;
}
.points_top_right div span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 550;
}
.points_top_right div:hover span svg path{
    fill: rgba(0, 122, 255, 1);
}
.points_top_right div:hover span{
    color: rgba(0, 122, 255, 1);
}
.points_page {
    margin: 0 10px;
}
.points_tab{
    display: flex;
    margin-left: -12px;
}
.points_tab_item {
    width: 50%;
    padding-bottom: 8px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}
.cheked.points_tab_item {
    font-weight: 700;
    border-bottom: 3px solid #202333;
}
.points_list {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}
.follow_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 0;
}
.follow_icon_item {
    width: 40px;
    cursor: pointer;
}
.points_item_1 {
    display: flex;
    margin-bottom: 16px;
    padding: 0 12px;
    border-radius: 10px;
    background-color: rgba(247, 247, 247, 1);
}
.points_item_1_left {
    padding: 16px 0;
    flex: 1;
}

.points_icon_box {
    display: inline-flex;
    align-items: center;
    background-color: #fff;
    border-radius: 20px;
    padding: 2px 5px;
}
.points_item_title_1 h2 {
    font-weight: 700;
    margin: 16px 0;
}
.points_item_desc {
    max-width: 450px;
}
.points_item_1_box .points_item_desc {
    max-width: 450px;
}
.points_item_refer strong {
    font-size: 30px;
    margin-right: 8px;
}
.points_item_refer span {
    font-weight: 700;
}
.points_item_refer button span {
    font-weight: 400;
}
.points_item_button button:nth-child(1) {
    margin-right: 10px;
}
.points_item_icon span {
    display: flex;
    align-items: center;
    justify-content: center;
}
.points_list_item_1 {
    display: flex;
}
.points_list_item_1 .points_list_item_1_item {
    width: calc(100% / 3 - 12px);
    margin-right: 24px;
    margin-bottom: 24px;
    border-radius: 10px;
    background-color: rgba(247, 247, 247, 1);
    cursor: pointer;
    position: relative;
    padding: 16px;
    /* box-shadow: 0rem 0.125rem 0.25rem rgba(31,33,36,.1), 0rem 0.0625rem 0.375rem rgba(31,33,36,.05); */
}
.points_list_item_1_item .points_item_desc{
    height: 40px;
}
.points_item_1_box {
    align-items: center;
}
.points_list_item_1_item:nth-last-of-type(1) {
    margin-right: 0;
}
.points_item {
    width: calc(100% / 3 - 11px);
    margin-right: 16px;
    margin-bottom: 16px;
    border-radius: 10px;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    box-shadow: 0rem 0.125rem 0.25rem rgba(31,33,36,.1), 0rem 0.0625rem 0.375rem rgba(31,33,36,.05);
}
.points_item_title h2 {
    font-weight: 700;
}
.points_item_desc {
    margin: 10px 0 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break:keep-all;
}
.points_item .points_item_desc {
    height: 60px;
}
.points_item_link {
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
    color: rgba(0, 122, 255, 1);
}
.points_item:nth-child(3n) {
    margin-right: 0;
}
.points_item_img {
    height: 158px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.points_item_img img {
    width: 100%;
    height: 100%;
}
.points_item_content {
    padding: 16px;
}
@media  screen and (max-width: 1024px) {
    .points_num {
        font-size: 12px;
    }
    .points_item_right {
        max-width: 300px;
    }
    .points_item {
        width: calc(100% / 2 - 11px);
    }
    .points_item:nth-child(2n) {
        margin-right: 0;
    }
    .points_item:nth-child(3n) {
        margin-right: 16px;
    }
    .points_item:nth-last-of-type(1) {
        margin-right: 0;
    }
}
@media screen and (max-width: 499px) {
    .points_list_item_1 {
        flex-wrap: wrap;
    }
    .points_top {
        flex-direction: column;
    }
    .points_top_left {
        width: 100%;
    }
    .points_top_right {
        border: 0;
        flex-direction: row;
        align-items: center;
        padding-left: 0;
        margin-top: 20px;
    }
    .points_top_right div:nth-of-type(2) {
        margin-top: 0;
    }
    .points_item_1_left {
        width: 100%;
    }
    .points_item_right {
        display: none;
    }
    .points_list_item_1 .points_list_item_1_item {
        width: 100%;
        margin-right: 0;
    }
    .points_list {
        justify-content: center;
    }
    .points_item {
        width: 295px;
        margin-right: 0;
    }
    .points_item:nth-child(3n) {
        margin-right: 0;
    }
}
.shipping_button .Polaris-Button{
    text-align: left;
    margin-bottom: 5px;
}

.Product_Detail_Shipping .Select_Shipping_Country{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.Product_Detail_Shipping .Select_Shipping_Country .location{
    margin-right: 15px;
}

.Product_Detail_Shipping .Select_Shipping_Country .location img{
    width: 25px;
    height: 25px;
}

.Product_Detail_Shipping .Show_Shipping_Result, .Product_Detail_Shipping .Show_Shipping_Result_Empty{
    padding: 15px 20px;
    margin: 10px auto;
    border: 1px solid #eff3fb;
    border-radius: 5px;
    background: #fff;
}

.Product_Detail_Shipping .Show_Shipping_Result_Empty{
    padding: 15px 20px 15px 15px;
}

.Product_Detail_Shipping .Show_Shipping_Result_Empty img{
    width: 20px;
    height: 20px;
}

.Product_Detail_Shipping .Show_Shipping_Result_Empty .box{
    display: flex;
    align-items: center;
}

.Product_Detail_Shipping .Show_Shipping_Result_Empty .box .img{
    padding-right: 10px;
}

.Product_Detail_Shipping .Show_Shipping_Result{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Product_Detail_Shipping .Show_Shipping_Result{
    cursor: pointer;
}

.Product_Detail_Content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 250px;
    padding-bottom: 2rem;
}

.Product_Detail_Img {
    flex-basis: 300px;
    margin-right: 8px;
    margin-bottom: 24px;
    width: 300px;
    max-width: 100%;
}
.redeem_details_btn button {
    min-width: 160px;
    height: 40px;
}
.Product_Detail_Box {
    flex-grow: 1;
    flex-basis: 280px;
}
.detail_product_cj img {
    max-width: 100%;
}
@media (max-width: 956px){
    .Product_Detail_Img{
        width: 80%;
        max-width: 300px;
        margin: auto;
    }
}

.Product_Order_Count{
    display: flex;
    margin-left: 16px;
}

.Product_Order_Count svg{
    height: 18px;
    fill: #6b6666;
}

.Product__Rating{
    font-size: 14px;
    margin-top: 3px;
    margin-left: 12px;
}

.Product__Reviews{
    font-size: 14px;
    margin-top: 3px;
}

.Product__Attributes .Polaris-Badge{
    margin-right: 6px;
    margin-bottom: 6px;
}

.Product_Detail_Box.detail{
    margin-left: 16px;
}

.Product__Attributes{
    width: 100%;
    display: table;
    table-layout: fixed;
    padding-bottom: 16px;
    color: #454f5b;
    word-break: break-word;
}

.Product__Attributes.price{
    font-size: 20px;
    color:#e62d04;
}

.Product__Attributes_Content.Label{
    display: flex;
    align-items: center;
}

.Product__Attributes_Content.Label .location{
    height: 25px;
}

.Product__Attributes_Content.Label .location img{
    width: 25px;
    height: 25px;
}

.Product__Attributes_Content.Label .title{
    font-size: 18px;
    font-weight: 500;
    padding: 0 15px;
}

.Product__Attributes_Content.Label .country{
    height: 27px;
}

.Product__Attributes_Content.Label .country img{
    width: 41px;
    height: 27px;
}

.Product__Attributes_Name{
    font-weight: 500;
    display: table-cell;
    width: 20%;
    vertical-align: middle;
}

.Product__Attributes_Name.shipping{
    vertical-align: top;
}

.Product__Attributes_Content{
    display: table-cell;
    width: 80%;
    vertical-align: top;
}

.Product__Attributes_Name.options{
    vertical-align: middle;
    font-weight: 500;
}

.Product__Discount_Price{
    display: flex;
    align-items: center;
    background-color: #F5520C;
    color: #fff;
    border-radius: 5px;
    padding: 8px;
}

.Product__Discount_Price .price{
    font-size: 20px;
    font-weight: 500;
    padding: 5px 10px;
}

.Product__Discount_Price .compared_price{
    padding: 10px 5px 5px;
    text-decoration: line-through;
}

.Product__Discount_Price .discount_rate{
    padding: 5px 10px;
    font-weight: 500;
}

.Product__Discount_Price .discount_date{
    position: absolute;
    right: 40px;
}

.Product__Discount_Price .discount_date .title{
    font-weight: 500;
}

.Product__Discount_Price .discount_date .date{
    text-align: center;
}

@media (max-width: 1089px) {
    .Product__Discount_Price{
        display: block;
        text-align: center;
    }

    .Product__Discount_Price .discount_date{
        position: unset;
        text-align: center;
    }
}

@media (max-width: 1009px) {
    .Product__Discount_Price{
        display: flex;
        text-align: inherit;
    }

    .Product__Discount_Price .discount_date{
        position: absolute;
        text-align: inherit;
    }
}

/**/
.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: initial;
  }
  
  /* body {
    background: #000;
    color: #000;
  } */
  .details_product {
    position: relative;
    margin: 0 28px;
  }
  .details_product .prev {
    position: absolute;
    left: -30px;
    z-index: 10;
    top: 50%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F3F4F6;
    border-radius: 0px 2px 2px 0px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .details_product .next {
    position: absolute;
    right: -30px;
    z-index: 10;
    top: 50%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F3F4F6;
    border-radius: 0px 2px 2px 0px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .swiper-slide {
    background-size: cover;
    background-position: center;
  }
  
  .mySwiper2 {
    height: 80%;
    width: 100%;
  }
  
  .mySwiper {
    height: 7%;
    box-sizing: border-box;
    padding: 10px 0;
  }
  
  .mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }
  
  .mySwiper .swiper-slide-thumb-active {
    opacity: 1;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: initial;
  }
  /* product detail */
.product_info_swiper {
    max-height: 300px;
    min-height: 300px;
    width: 300px;
    position: relative;
    z-index: 99;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 #ccc;
    overflow: hidden;
    cursor: move;
}
.product_swiper_thumbs , .product_swiper_thumbs_hover {
  margin: 10px 10px 10px 0;
  width: 60px !important;
  height: 60px !important;
  cursor: pointer;
  border-radius: 0;
  opacity: 1 !important;
  border: 1px solid transparent;
}
.product_swiper_thumbs > img , .product_swiper_thumbs_hover > img {
    border-radius: 0;
    height: auto;
    max-height: 58px;
}
.mySwiper .product_swiper_thumbs_hover{
  border-color: #f5520c;
  opacity: 1;
}
.mySwiper .product_swiper_thumbs:hover {
  border-color: #f5520c;
  opacity: 1;
}

/* .product_swiper_thumbs */
.product_info_swiper > .swiper {
  /* max-height: 300px;
  min-height: 300px; */
}
.product_info_swiper img {
  width: 100%;
  height: 300px;
  border-radius: 5px;
}
.detail_mask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  background: rgba(247, 157, 45, 0.3);
  opacity: .6;
  display: none;
  pointer-events: none
}
.picture_big img{
    width: 900px;
    height: 900px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
}
.picture_big {
    overflow: hidden;
    display: none;
    position: absolute;
    background-color: #fff;
    left: 325px;
    top: 16px;
    width: 300px;
    height: 300px; 
    border: 1px solid #ccc;
    z-index: 99999;
    border-radius: 5px;
    pointer-events: none
}
.branding_purchase_btn button{
    min-width: 200px;
}
.product_sku_attr_item {
    display: flex;
    margin-bottom: 16px;
}
.product_sku_attr_title {
    min-width: 120px;
}
.product_sku_attr_item_list {
    max-width: 420px;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
}
.item_value {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    max-width: 200px;
    text-align: center;
    word-break: break-word;
    margin-right: 10px;
    padding: 0 8px;
    line-height: 30px;
    border: 1px solid #eee;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 5px;
    overflow: hidden;
}
.item_value.item_value_img {
    padding: 0 !important;
    max-width: 42px;
    max-height: 42px;
}
.item_value_img img {
    max-width: 100%;
    max-height: 100%;
}
.item_value:hover {
    border-color: #f5520c;
}
.item_value_select{
    border-color: #f5520c;
}
.Product_Detail_Box.detail.branding_detail {
    margin-left: 24px;
}
.branding_sku_attr_title {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin-top: 16px;
    margin-bottom: 8px;
}

.Product__Attributes.price.brading_detail_price {
    color: #202333;
    font-weight: 500;
}
.Product__Attributes.price.brading_detail_price span {
    font-size: 10px;
    margin-right: 4px;
    font-weight: 700;
}
.branding_purchase_btn {
    margin-right: 20%;
}
.three_search_btn {
    margin: 0 16px 0 10px;
}
.three_search_btn button {
    height: 32px;
}
@media screen and (max-width: 768px){
    .product_info_swiper {
        width: 100%;
    }
    .product_sku_attr_title {
        min-width: 80px;
    }
}

.auth_tips_content .auth_step{
    padding: 0.5rem 0 1.5rem 0;
}

.auth_tips_content img{
    max-width: 100%;
}
.make_sure {
    margin-bottom: 10px;
}
.make_sure .Polaris-Choice__Label{
    font-weight: 600;
}

.make_sure .Polaris-Choice{
    cursor: pointer;
}
.Coupon_list .Polaris-Card{
    margin-top: 1.6rem;
    margin-left: 3rem;
    width: 85%;
    background-color: #f1f1f1;
}

/*.Coupon_list .Polaris-Card__Section{*/
/*    display: flex;*/
/*}*/

.coupon{
    display: flex;
    width: 100%;
    margin: 5px 10px;
}

.coupon_title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #000000;
    width: 100%;
    padding: 20px;
    text-align: center;

}

.coupon_title_tips{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    text-align: center;
}

.coupon_info{
    width: 50%;
    height: 135px;
    background-size: 100% 85%;
    background-repeat: no-repeat;
    /*background: #fd613e;*/
}

.coupon_info:last-child{
    margin-bottom: 50px;
}

.coupon_info_content{
    display: flex;
    width: 95%;
    height: 99px;
    /* background: white; */
    margin: 0 auto;
    position: relative;
    top: 10px;
    overflow: hidden;
    /* -webkit-mask: radial-gradient(circle at 10px, transparent 10px, red 0) -10px / 100% 17px; */
}

.coupon_left{
    width: 70%;
    padding-left: 10px;
    padding-right: 10px;
    border-right: 2px dashed #f1f1f1;
}

.coupon_pop_text{
    /*margin-bottom: 5px*/
}
.coupon_text{
    display: flex;
    flex-direction: column;
}
.coupon_text_money{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #FD613E;
    display: inline-block;
    width: 115px;
}

.coupon_text_explain{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
    color: #FD613E;
}

.coupon_text_time{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    zoom: 0.9;
}


.coupon_right{
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.coupon_right .Polaris-Button{
    padding: 0.5rem 1.2rem;
}
.coupon_right #coupon_pop_button1:hover{
    color: #f6f0f0;
}

.coupon_right #coupon_pop_button1{
    background: #303030;
    border-radius: 10px;
    color: white;
    margin-left: 10px;
}

.coupon_right #coupon_pop_button2{
    background: gray;
    border-radius: 10px;
    color: white;
}

.coupon_right_tips{
    /*margin: auto 20px;*/
    width: 49%;
    font-size: 10px;
    height: 180px;
    line-height: 13px;
}

.coupon_right_tips .Polaris-List__Item{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;/*显示几行*/
    overflow: hidden;
}

.coupon_right_tips_title{
    padding-left: 32px;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 12px;
}
.uncoupon_img{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width:1800px){
    .coupon_info{
        width: 50%;
        background-size: 100% 85%;
    }
    .coupon_left{
        padding-left: 10px;
    }
}

@media screen and (max-width:1575px){
    .coupon_text_explain{
        display: block;
        line-height: 13px;
    }

    .coupon_text_time{
        line-height: 14px;
    }
    .coupon_info_content{
        height: 90px;
        top: 10px;
    }
    .coupon_right #coupon_pop_button1 .Polaris-Button__Content{
        font-size: 10px;
    }
}

@media screen and (max-width:1150px) {
    .coupon{
        display: block;
    }
    .coupon_info{
        width: 95%;
        background-size: 100% 85%;
    }
    .uncoupon_img{
        width: 69%;
    }
    .coupon_info_content{
        height: 90px;
    }

    .coupon_left{
        width: 70%;
    }

    .coupon_text_time{
        line-height: 16px;
    }

    .coupon_right {
        /*padding: 5px 0;*/
        margin: auto;
    }

    .coupon_text_explain{
        display: block;
        line-height: 13px;
    }

    .coupon_right_tips{
        width: 100%;
        line-height: 18px;
        font-size: 10px;
        margin: auto -29px 15px;
    }

    .coupon_right #coupon_pop_button1 .Polaris-Button__Content{
        font-size: 10px;
    }

}

@media screen and (max-width:1000px){
    .coupon_info{
        width: 90%;
    }

    .coupon_info_content{
        height: 90px;
    }
    .coupon_text_money{
        font-size: 14px;
    }
    .coupon_text_explain{
        font-size: 12px;
        margin-bottom: 6px;
    }
}
@media screen and (max-width:768px) {
    .coupon_info{
        background-size: 100% 90%;
    }
    .coupon_right_tips {
        display: none;
    }
}

.min_space {
    padding: 5px 0;
}

.prepaid_order_breakdown_item {
    font-weight: 500;
}

.prepaid_order_shipping{
    font-weight: 400;
}

.prepaid_order_total {
    font-size: 15px;
    font-weight: 500;
}

.Orders_Detail_Item_Content.prepaid_order{
    padding: 10px 0 0;
}

.Orders_Detail_Item_Content.prepaid_order .Orders_Detail_Item_Box{
    grid-template-columns: 2fr 1fr 1fr;
}
.retention_plan .sourcing_enough_quota_left div{
    line-height:1;
    width:100%;
}
.retention_plan .sourcing_enough_quota_left {
    width:100%;
}
.retention_plan_price {
    padding: 12px;
    border-radius: 12px;
    background: linear-gradient(
    to right,
    rgba(255, 106, 0, .9),
    rgba(255, 0, 183, 1)
  );
}
.retention_plan_desc {
    margin-bottom: 12px;
}
.retention_plan_price_off {
    font-size: 20px;
    font-weight: 650;
    line-height: 24px;
    color: #fff;
    margin-bottom: 4px;
}
.retention_plan_name {
    background-color: rgba(224, 240, 255, 1);
    padding: 2px 8px 2px 8px;
    border-radius: 8px;
    color: rgba(0, 82, 124, 1);
    font-size: 12px;
    font-weight: 550;
    margin-bottom: 4px;
}
.retention_plan_price_text {
    font-family: Inter;
    font-size: 13px;
    font-weight: 650;
    line-height: 20px;
    color: rgba(255, 255, 255, 1);
}
.retention_plan_price_text_off {
    font-family: Inter;
    font-size: 13px;
    font-weight: 450;
    line-height: 20px;
    text-decoration-line: line-through;
    color: rgba(255, 255, 255, 1);
}
.retention_plan_timeout_box {
    display: flex;
    align-items: center;
}
.retention_plan_timeout_box_time_dot {
    color: rgba(181, 181, 181, 1);
    font-size: 19.2px;
    font-weight: 700;
    line-height: 22.5px;
    width: 20px;
    text-align: center;
}
.retention_plan_timeout_box_time span {
    background-color: rgba(227, 227, 227, 1);
    color: rgba(48, 48, 48, 1);
    border-radius: 8px;
    font-size: 22.4px;
    font-weight: 700;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
}
.retention_plan_timeout {
    margin:20px 0 10px 0;
}
.retention_plan .sourcing_enough_quota_right {
    padding: 24px 16px 11px 16px;
}
.retention_plan .sourcing_enough_quota_subtitle_li ul li {
    color: rgba(0, 0, 0, 1);
}
.retention_plan .sourcing_enough_quota_item_button {
    margin: 14px 0 0 0 !important;
}
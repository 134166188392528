.dropshipman_price{
    padding: 20px 0;
    position: relative;
}

.dropshipman_price .border_mask.left {
    left: -3px;
}

.dropshipman_price .border_mask.right {
    right: -3px;
}

.dropshipman_price .border_mask.bottom {
    top: initial;
    bottom: -3px;
    width: 100%;
    height: 3px;
}

.dropshipman_price .plan_resource_container{
    position: relative;
    display: grid;
    grid-column-gap: 0;
    column-gap: 0;
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-template-columns: repeat(auto-fit,460px);
    justify-content: center;
    z-index: 2;
}

.plan_item{
    padding: 20px 10px;
    background-color: #ffffff;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
    outline: 0.1rem solid transparent;
}

/* .plan_item.free_plan li{
    text-decoration: line-through;
} */


@media screen and (max-width: 500px){
    .dropshipman_price .plan_resource_container{
        display: block;
        padding: 20px;
    }
    .plan_item{
        margin-bottom: 20px;
        border-radius: 5px;
    }
}

.upgrade_contact_us .Polaris-Button__Content {
    text-align: left !important;
}
.pricing-button{
    color: rgb(52, 52, 52);
    font-size: 12px;
    text-align: center;
    height: 36px;
    margin: 30px 10px;
}
.bigbox{
    padding:10px 0 40px 0;
}
.bigspace2{
    padding:51px 0 40px 0;
}

.bigtd{
    height:61px;
}

.pricingContent .Polaris-Card__Section{
    padding: 20px 0;
}

.pricingCard{
    margin-left: 0 !important;
    background-color: rgba(255,255,255,1);
    box-shadow: var(--p-card-shadow, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
    /* box-shadow: 0px 0px 5px rgba(23, 24, 24, 0.05),0px 1px 2px rgba(0, 0, 0, 0.15),0 0 0 1px rgba(64, 64, 68, 0.05),0 1px 3px 0 rgba(63, 63, 68, 0.15); 与上一句一样*/ 
    outline: 1px solid transparent;
}
.Polaris-Card-2{
    background-color: rgb(255, 255, 255,1);
    box-shadow: var(--p-card-shadow, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
    outline: 1px solid transparent;
}

@media (max-width:500px) {
    .pricingCard{
        margin-left: 20px !important;
    }
}

.pricingList{
    min-height: 430px;
    overflow: auto;
}
.pricingList tr{
    border: 1px solid #e5e5e5;
}
.pricingList tr{
    border-left: unset;
    border-right: unset;
    height:42.5px;
}
.pricingList tr td{
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
}

.pricingList::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
.pricingList::-webkit-scrollbar-thumb {
    border-radius: 100px;
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background:#ccc;
}
.pricingList::-webkit-scrollbar-track {
    -webkit-box-shadow:inset 0 0 5px rgba(0,0,0,0.2);
    box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 0;
    background:rgba(0,0,0,0.1);
}

.pricingList.pro tr td{
    padding: 9px;
}
.pricingList .td__Padding{
    padding: 9px;
}
.pricingList tr td img{
   vertical-align: middle;
   width: 20px;
   height: 20px;
}

.LoadingNew{
    height:20px;
    width: 20px;
}

.SkeletonThumbnailCenter .Polaris-SkeletonThumbnail--sizeMedium{
    margin: 0 auto;
}
.SkeletonThumbnailCenter .Polaris-SkeletonDisplayText--sizeMedium{
    margin: 0 auto;
}

.upgrade_contact{
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
}

.plan_name {
    font-size: 20px;
    text-align: center;
    padding-bottom: 5px;
}

.plan_name.weight {
    font-size: 24px;
}

.carousel_text_box {
    position: relative;
    margin-top: -35px;
    background-color: red;
    height: 35px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
    font-size: 13px;
    color: #ffffff;
    line-height: 2.4;
    font-weight: 600;
    overflow: hidden;
}

@media (max-width: 1041px) {
    .carousel_text_box {
        margin-top: unset;
    }
}

.animate_text {
    animation: 3s move ease infinite normal;
    overflow: hidden;
}

@keyframes move {
    0% {
        transform: translateY(10px);
        -webkit-transform: translateY(10px);
    }
   10%, 50% {
        transform: translateY(-10px);
        -webkit-transform: translateY(-1px);
    }
    100% {
        transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
    }
}

@-webkit-keyframes move {
    0% {
        transform: translateY(10px);
        -webkit-transform: translateY(10px);
    }
   10%, 50% {
        transform: translateY(-10px);
        -webkit-transform: translateY(-1px);
    }
    100% {
        transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
    }
}
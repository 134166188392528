.SettingsPage__Content{
  grid-column: 2 / span 3;
}

@media (max-width: 768px){
  .SettingsPage__Nav {
    grid-column: 1 / span 4;
  }
  .Nav_Left_Menu{
    margin-bottom: 1rem;
  }

  .SettingsPage__Content{
    grid-column: 1 / span 4;
  }
}

.Adding_Product_Item{
  margin-left: 25px;
  margin-top: 5px;
}

.Adding_Product_Item .Polaris-Choice{
  cursor: pointer;
}

.Import_Checkbox{
  margin-top: 20px;
}

.Import_Checkbox:first-child{
  margin-top: 0;
}

.Import_Checkbox .Polaris-Choice{
  cursor: pointer;
}

.Import_Checkbox .Polaris-Choice__Label{
  font-size: 15px;
  font-weight: 500;
  margin-left: 10px;
}

.Import_Checkbox_Note{
  margin-left: 40px;
  margin-top: 5px;
  color: rgb(128, 128, 128);
}

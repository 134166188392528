.auto_Fulfillment{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* margin:0 10px 20px 0; */
}
.order_button_group_tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.shopify_price span{
  font-weight: 450 !important;
}
.order_button_group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.order_button_group_item {
  margin-right: 16px;
}
.order_button_group_item button ,.auto_Fulfillment .item button{
  height: 35px;
}
.auto_Fulfillment .item{
  margin-left:10px;
}
.order_button_group_tab .order_button_group_tab_item .Polaris-ButtonGroup__Item button {
  padding: 10px 16px;
  min-width: 120px;
}
.order_button_group_tab_item .Polaris-Button.Polaris-Button--pressed {
  background-color: #303030;
  color: #fff;
}
.auto_Fulfillment .item .Polaris-Icon{
  width:24px;
  height:24px;
}

.auto_Fulfillment .icon{
  margin-bottom:4px;
  margin-right: 10px;
}

.ImportListSearchLoading {
  position: relative;
  margin-top: 15px;
}
.content_list_tab {
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 16px;
}
.content_list_tab_filters {
  padding: 16px;
}
.batchClick_container .Polaris-Button {
  display: flex; bottom: none;
  align-items: center;
}
.search_container .search_keyword{
  display:flex;
}

.search_container .search_keyword .search_item{
  margin-right:20px;
}
.search_container .search_keyword .search_item .Polaris-ButtonGroup__Item button , .searchBtn .Polaris-ButtonGroup__Item button{
  height: 32px;
}

.search_container .search_keyword .filter_input{
  width:360px;
}

.search_container .search_status{
  position: relative;
}

.search_container .search_status, .batchClick_container{
  margin:8px 0px 0px 0px
}

.search_container .search_select{
  position: absolute;
  width: 22%;
  left: 326px;
  top: 0;
}

.search_container .react-datepicker-popper{
  z-index:999;
  top: 0 !important;
}
.react-datepicker-popper{
  top: 0 !important;
}
.batchClick_container{
  display:flex;
  min-height: 30px;
}

.remarkcontainer {
  position: relative;
}

.remarkarea {
  position: absolute;
  top: -10px;
  right: -16px;
}

.remarkarea .Polaris-Icon__Svg {
  fill: rgb(129, 141, 154);
}

.dateSearchArea {
  display: flex;
  justify-content: flex-start;
}

.dateSearchArea .startDateInput,
.dateSearchArea .endDateInput {
  width: 120px !important;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  height: 32px;
}

.dateSearchArea .startDateInput {
  border-left: 1px solid #ccc;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.dateSearchArea .endDateInput {
  border-right: 1px solid #ccc;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dateSearchArea .timearrow{
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  height: 32px;
}

.dateSearchArea .timearrow img{
  height: 32px;
}

.dateSearchArea .orderNumInput {
  width: 220px !important;
}

.dateSearchArea .orderNumInput,
.dateSearchArea .searchBtn {
  margin:0 15px;
}

.orderlist_container,
.orderlist_checkbox {
  display:flex;
}

.orderlist_checkbox{
  align-items: center;
}

.orderlist_container .iconBtn_container{
  display:flex;
  align-items:center;
  /* margin-left:100px; */
  /* width:280px; */
  justify-content: space-between;
  /* margin-right: 35px; */
}

.orderlist_content{
  display:flex;
  flex-direction: column;
  justify-content: center;
  min-width: 600px;
}

.orderlist_container .iconBtn_container div{
  margin-left:15px;
}

.orderlist_content .logo_img img{
  width:14px;
  height:14px;
}

/* .orderlist_content div:nth-child(2){
  margin-top:8px;
} */

.orderlist_content .logo_img{
  margin-left:10px;
  margin-right: 5px;
}

/* .orderlist_content .link,
.orderlist_content .time,
.orderlist_content .status{
  margin-right:22px;
} */

.button_container{
  display:flex;
  align-items:center;
  flex:1;
  /* justify-content: center; */
}

.button_container .customer_btn{
  position: relative;
}

.button_container .customer_warring{
  position: absolute;
  right: 0;
  bottom: 23px;
}

.button_container > div{
  margin-left:25px;
}

.button_container div:nth-child(5) img{
  width:48px;
  height:48px;
}

.goods_container{
  align-items:center;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding:12px;
  margin-bottom:15px;
}

.goods_container,
.goods_content{
  display:flex;
}

.goods_content img{
  width:56px;
  height:56px;
}

.goods_container .goods_content div:nth-child(2){
  margin-left:12px;
  color:#6a6666;
  font-weight:500;
}

.goods_footer{
  display:flex;
  justify-content: flex-end;
  align-items:center;
}

.goods_footer div:not(:last-child){
  margin-right:20px;
}

.collapsibleContainer{
  border-top: 1px solid #efefef;
}

.pageination_container {
  display: flex;
  justify-content: center;
  margin-top:35px;
  margin-bottom: 24px;
}

.switchBtn{
  display:flex;
  justify-content: center;
  align-items:center;
  height:100%;
  font-size:13px;
  color:#666666;
  padding-right:2px;
}

.radio_container{
  display:flex;
  align-items:center;
  /* justify-content: space-between; */
  margin-top:16px;
  flex-wrap: wrap;
}

.radio_container .Polaris-Choice{
  align-items:center !important;
}

.radio_container .dsm_container{
  padding:5px 5px;
  height: 48px;
  border-radius:6px;
}
.Order_Detail_Sku_Refund {
  width: 170px;
}
.order_banner {
  display:flex;
  align-items:center;
  flex-wrap: wrap;
}
.order_banner .order_banner_span{
  margin-right: 5px;
  margin-bottom: 5px;
}
.radio_container .pal_container{
  border:1px solid transparent;
  padding: 8px 5px;
  border-radius:6px;
}
.dsm_container_cpf input {
  border:1px solid #d3d3d3;
  height: 32px;
  border-radius:6px;
  outline: none;
  text-indent: 8px;
}
.dsm_container_cpf {
  position: relative;
}
.dsm_container_cpf p {
  position: absolute;
  bottom: -22px;
  left: 0;
  color: #f00;
}
.pal_container_border , .dsm_container_border{
  border-color:#005BD3 !important;
}

.dsm_radioLabel{
  display:flex;
  align-items:center;
  margin-right: 20px;
}

.dsm_radioLabel div{
  margin-left:6px;
}

.pay_radioLabel{
  display:flex;
  align-items:center;
  margin-right: 20px;

}

.pay_radioLabel div{
  margin-left:6px;
}

.skuPartNum{
  display:flex;
  align-items:center;
}

.skuRadio{
  position: relative;
}

.skuRadio .sku_textField{
  position: absolute;
  left: 236px;
  top: -5px;
  width: 96px;
}

.skuRadio .Polaris-Choice{
  align-items: center !important;
}

.skuPartNum .Polaris-TextField{
  width:112px !important;
}


.refund_btn .Polaris-Button{
  border:2px solid #009966;
}

.refund_btn .Polaris-Button .Polaris-Button__Text{
  color:#009966
}

.bulk_order_id {
  width: 560px;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  background-color: rgb(235, 249, 252);
  padding: 16px 26px;
  border: 1px solid rgb(0, 160, 172);
  border-radius: 5px;
}
.sync_store_order_content {
  height: 335px;
}
.sync_store_order_item {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.sync_store_order_range {
  font-weight: 500;
  margin: 0px 20px 0px 88px;
}
/* .Polaris-Modal-Dialog__Container {
  z-index: 5190000000 !important;
} */
.refund_tips_content {
  padding-left: 100px;
}
.shipping_methods_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shipping_methods_content_tips {
  flex: 1;
  margin-left: 16px;
  font-size: 16px;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 5px;
}
.shipping_methods_content_tips p {
  margin-left: 16px;
}
.order_tracking_no {
  display: flex;
  align-items: center;
  min-width: 140px;
}
.order_tracking_no_button button {
  padding: 8px 16px !important;
}
.order_sku_title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  width: 240px;
}
.order_sku_sku {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  width: 240px;
}
.order_note_tip {
  left: -55px !important;
}
.list_order_name_item {
  display: flex;
  align-items: center;
}
.list_order_name_item:nth-child(2){
  margin-top: 10px;
}
.list_order_name_item > div {
  width: calc(100% / 4);
  margin-right: 22px;
  display: flex;
}
.list_order_name_item > div:nth-last-of-type(1){
  margin-right: 0;
  /* flex: 1; */
}
.list_order_name_date {
  min-width: 150px !important;
}
.list_order_name_title {
  display: inline-block;
  min-width: 150px;
  justify-content: flex-start !important;
}
.list_order_name_title .link {
  display: flex;
}
.list_order_name_title .link button{
  display: flex;
  overflow: hidden;				
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
  white-space: break-spaces;
  -webkit-box-orient: vertical;
}
.list_order_name_title .link svg{
  fill: rgba(44, 110, 203, 1);
}
.list_order_name_title img {
  vertical-align: sub;
  width: 16px !important;
  height: 16px !important;
}
.order_invices {
  margin-right: 0 !important;
}
.order_invices img {
  width: 36px;
  margin-top: 4px;
}
.order_customer {
  margin: 0 0;
}
.type_link:hover {
  text-decoration: underline;
  color: #2c6ecb;
  cursor: pointer;
}
.shipp_methods_text .Polaris-Button__Text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  width: 140px;
}
.order_customer_info .Polaris-Button__Text {
  width: 85px;
}
.shipp_methods_text {
  display: flex;
  align-items: center;
}


.Dropshipman_Orders{
  /* background-color: #fff; */
  box-sizing: content-box;
  contain: layout;
  overflow: auto;
}

.Dropshipman_Orders_Box{
  /* min-width: 1000px; */
  margin-bottom: 2rem;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
}

.Dropshipman_Orders_Item{
  display: flex;
  justify-content: space-between;
  min-height: 75px;
  padding: 10px;
}

div.Dropshipman_Orders_Item{
  padding: 5px;
}

.order_check_name{
  display: flex;
  padding: 10px 10px 10px 12px;
}

.order_check{
  margin: auto 0;
  padding-left: 10px;
}
.order_name{
  margin: auto 0;
  cursor: pointer;
  color: #006fbb;
  padding-left: 20px;
}

.order_date{
  margin: auto 20px;
  flex-flow: nowrap;
  padding: 10px 0;
}
.order_customer{
  margin: auto 20px;
  padding: 10px 0;
}
.order_payment{
  margin: auto 20px;
  padding: 10px 0;
}
.order_fulfillment{
  margin: auto 20px;
  padding: 10px 0;
}

.order_items{
  margin: auto 20px;
  padding: 10px 0;
}

.order_note{
  margin: auto 20px;
  display: flex;
  position: relative;
  align-items: center;
}
.order_invices{
  width: 34px;
  cursor: pointer;
  position: relative;
  margin-right:16px;
  /* position: absolute;
  left: -70px;
  top: 0px; */
}
.order_invices img{
  width: 100%;
}

.Orders_Detail_Item_Content{
  padding: 0 2rem 1rem 2rem;
}

.Orders_Detail_Item_Box{
  /* display: flex; */
  /* justify-content: space-between; */
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  grid-column-gap: 50px;
  border-radius: 2px;
  background-color: rgb(224 224 224 / 32%);
  padding: 10px;
}

.Orders_Variant_Box{
  display: flex;
}

.Orders_Detail_SKU {
  text-align: left;
  padding: 0 15px;
  max-width: 300px;
}

.order_variant_title span{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.Orders_Detail_Cost, .Orders_Detail_Total, .Orders_Detail_Tracking{
  margin: auto 0;
}

.Orders_Detail_Cost span, .Orders_Detail_Total span, .Orders_Detail_Tracking span{
  color: #212b36;
  font-weight: 500;
}

.Orders_Total_Pay{
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-end;
  padding: 1rem 2rem;
}

.Orders_Total{
  margin: auto 15px;
}

.Orders_Pay_Button{
  margin-left: 10px;
}

.Order_Record_Data{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2rem 6% 2rem 2rem;
  border-top: 1px solid rgb(0 0 0 / 9%);
}

.Order_Record_Data {
  font-weight: 500;
}

.Order_Record_Shipping{
  font-weight: 400;
}

.Order_Record_Tracking .tracking_number{
  display: flex;
  align-content: center;
  align-items: center;
}



.pay_coupon{
  display: flex;
  /* max-width: 570px;
  overflow-y: hidden;
  overflow-x: auto; */
  /* flex-wrap: wrap;    */
}
.pay_coupon_list{
  display: flex;
  width: 170px;
  height: 70px;
  background:rgba(235, 235, 235, 1);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 10px;	  
  border: 1px solid transparent;
  margin:4px 15px 10px 0px;   
}
.pay_coupon_lists .pay_coupon_text_money {
  margin-left: 0;
  margin-right: 8px;
}
.pay_coupon_lists .pay_coupon_text_explain {
  flex: 1;
}
.pay_coupon_info{
  width: 80%;	
  /* background: white; */
  height: 50px; 
  /*padding: 20px;*/	  
  margin-top: 10px;
 
  margin-left: 10px;	
  /* -webkit-mask: radial-gradient(circle at 10px, transparent 5px, red 0) -10px / 100% 17px; */
}
.pay_coupon_text{
  padding: 0px;
  width: 126px;
  height: 100%;
  display: flex;
  margin-right: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-repeat: no-repeat;
  position: relative;
}
.pay_coupon_text .line {
  border: 1px dashed rgba(227, 227, 227, 1);
  height: 100%;
  position: absolute;
  left: 10px;
}
.pay_coupon_text_money{	
  font-family: 'Inter'; 
  font-style: normal;	
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #303030;	
  margin-left: 12px;
  /*display: inline-block;*/	 
  /*width: 115px;*/
}
.pay_coupon_text_explain{
  font-style: normal;	
  font-weight: 400;
  font-size: 12px !important;	 
  line-height: 10px;  
  color: gray; 
}
.pay_coupon_list .Polaris-Choice{
  width: 17px;  
}
.pay_modal_coupn ul{
  display: flex;
  align-items: center;
}
.pay_modal_coupn ul li {
  display: flex;
}
.pay_modal_coupn ul li div {
  padding-left: 0;
}
.order_empt .Polaris-   {
  margin-left: 0% !important;
}
.order_empt .Polaris-EmptyState__Image {
  width: 100%;
  margin-left: 0;
}
.a_target_link svg {
  fill: #2c6ecb;
}
@media screen and (max-width: 1199px) {
  .Dropshipman_Orders_Item{
      flex-flow: wrap;
  }
  .order_note{
      padding-bottom: 10px;
  }
}

.pay_sp span{
  font-size: 13px;
}
.pay_sp .sp_clo {
  font-weight: 700;
}
@media screen and (max-width: 980px) {
  .Orders_Detail_Item_Box{
      display: inherit;
  }

  .Orders_Detail_SKU {
      padding-bottom: 15px;
      max-width: 500px;
  }

  .Orders_Detail_Cost, .Orders_Detail_Total, .Orders_Detail_Tracking {
      display: inline-block;
      margin-right: 70px;
  }
  .Orders_Detail_Tracking{
      margin: 10px auto;
  }
}
@media screen and (min-width: 501px) and  (max-width: 1200px) {
  .order_button_group_tab {
    flex-direction: column;
    align-items: flex-start;
  }
  .order_button_group {
    width: 100%;
    margin-top: 16px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .order_button_group_item {
    margin: 10px 0;
  }
  .order_button_group_tab .order_button_group_tab_item .Polaris-ButtonGroup__Item button {
    min-width: auto;
    padding: 6px 12px;
    height: 40px;
  }
}

@media screen and (max-width: 500px) {
  .pay_sp p {
    font-size: 12px !important;
  }
  .pay_sp .points_item_icon {
    margin-right: 2px !important;
  }
  .search_selects {
    width: 100%;
  }
  .order_button_group_tab {
    flex-direction: column;
  }
  .order_button_group {
    width: 100%;
    padding: 0 16px;
    margin-top: 16px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .order_button_group_tab .order_button_group_tab_item .Polaris-ButtonGroup__Item button {
    min-width: auto;
    padding: 6px 12px;
    height: 50px;
  }
  .order_button_group_tab .order_button_group_tab_item .Polaris-ButtonGroup__Item button  span {
    line-height: 1;
  }
  .order_button_group_tab_item .Polaris-InlineStack{
    justify-content: center;
  }
  .auto_Fulfillments > div {
    margin-bottom: 10px;
  }
  .auto_Fulfillments .mb_item {
    margin-bottom: 3px;
  }
  /* .auto_Fulfillments .item.btn {
    margin-left: 10px !important;
  } */
  .auto_Fulfillments .sync_button {
    margin-bottom: 0;
    margin-top: 10px;
  }
  .Polaris-ButtonGroup--segmented {
      flex-wrap: wrap
  }
  .order_button_group_item {
    margin: 24px 0 16px 0;
  }
  .order_button_group_tab_item {
    padding: 0 10px;
  }

  .Dropshipman_Orders_Float_Left{
  float: unset !important;
  margin-right: unset !important;
  margin-bottom: 10px;
  }
}

.No_Customer{
  width: 122px;
}

@media screen and (max-width: 350px) {
  .No_Customer{
      width: 0;
  }
}

.Order_Paid_Items{
  padding: 1rem 2rem;
  font-weight: 600;
}

.Dropshipman_Orders_Float_Left{
  float: left;
  margin-right: 10px;
}

.Dropshipman_Orders_Float_Clear{
  clear: both;
}
.invoices_modal_title{
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-top: 24px;
  position: relative;
}
.invoices_modal_title img{
  display: inline-block;
  vertical-align: bottom;
  margin-right: 24px;
  width: 36px;
}
.invoices_modal_box{
  margin: 24px;
  border: 1px solid rgb(190, 187, 187);
}
.invoices_modal_top{
  display: flex;
  align-items: center;
  margin: 10px 16px;
  justify-content: space-between;
  font-weight: 500;
}
.invoices_modal_number{
  margin-right: 8px;
  margin-left: 56px;
}
.invoices_modal_date{
  margin-right: 56px;
}
.order_tracking{
  width: 34px;
  cursor: pointer;
  position: relative;
  margin-right: 16px;
  /* position: absolute;
  left: -130px;
  top: 5px; */
}
.order_track_new{
  position: absolute;
  right: -15px;
  top: -10px;
  font-size: 12px;
  transform: scale(0.6);
  padding: 0 2px;
  color: #fff;
  background: #f5520c;
  border-radius: 3px;
}
.order_tracking img{
  width: 100%;
}
.order_invices:hover .order_note_tip{
  display: block;
}
.order_tracking:hover .order_track_tip{
  display: block;
}
.order_note_tip {
  background-color: #000;
  position: absolute;
  left: -40px;
  bottom: -36px;
  color: #fff;
  width: 120px;
  display: none;
  z-index: 999;
  text-align: center;
  padding: 6px 0px;
  border-radius: 5px;
}
.order_track_tip{
  background-color: #000;
  position: absolute;
  left: -40px;
  bottom: -36px;
  color: #fff;
  width: 120px;
  display: none;
  z-index: 999;
  text-align: center;
  padding: 6px 0px;
  border-radius: 5px;
}
.order_note_tip_down{
  position: absolute;
  left: 50%;
  top: -18px;
  transform: translateX(-50%);
  border: 10px solid #000;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
.invoices_modal_mid{
  display: flex;
  flex-direction: column;
  margin: 10px 16px;
  font-weight: 500;
}
.invoices_modal_mid div{
  display: flex;
  align-items: center;
  height: 60px;
}
.invoices_modal_mid div span{
  display: block;
  width: 20%;
  text-align: right;
  padding-right: 10px;
}
.invoices_modal_mid div input{
  line-height: 24px;
  flex: 1;
  border: 1px solid rgb(190, 187, 187);
  outline: none;
}
.invoices_modal_bottom{
  display: flex;
  flex-direction: column;
}
.invoices_modal_bottom > div{
  display: flex;
  align-items: center;
}
.invoices_modal_bottom > div {
  border-top: 1px solid rgb(190, 187, 187);
}
.invoices_modal_bottom div div{
  text-align: center;
  padding: 8px;
  height: 80px;
  font-size: 12px;
  zoom: 0.9;
  border-right: 1px solid rgb(190, 187, 187); 
}
.invoices_modal_bottom > div:nth-child(1) div {
  height: 52px;
  font-weight: 500;
}
.invoices_modal_bottom div div:nth-child(1){
  width: 35%;
}
.invoices_title_table{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
}
.invoices_modal_bottom div div:nth-child(2){
  width: 10%;
}
.invoices_modal_bottom div div:nth-child(3){
  width: 15%;
}
.invoices_modal_bottom div div:nth-child(4){
  width: 15%;
}
.invoices_modal_bottom div div:nth-child(5){
  width: 25%;
  border-right: 0; 
}
.invoices_total_price{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 24px;
  margin-bottom: 24px;
}
.invoices_total_price > div{
  margin-right: 30px;
}
.invoices_total_price div span{
  font-size: 16px;
  font-weight: 600;
  margin-right: 6px;
}

.order_refund{
  margin: auto 20px;
  padding: 10px 0;
}
.order_refund span , .Order_Detail_Sku_Refund span{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: center;
}
.Order_Detail_Sku_Del{
  color: #439C86;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Order_Record_Name{
  display: flex;
}
.Order_Record_Name_Package{
  margin-left: 24px;
  margin-bottom: 8px;
}
.Order_Record_Name_Package button{
  min-height: 28px;
  padding: 0 1.6rem;
}
.packaging_checkbox_item_checkbox{
  width: 40%;
}
.packaging_checkbox_item_checkbox_title{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.packaging_checkbox_item_checkbox_quantity,.packaging_checkbox_item_checkbox_available {
  width: 20%;
  text-align: center;
}
.packaging_checkbox_item{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.packaging_checkbox_item_footer{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 24px;
}
.packaging_checkbox_item_footer >button{
  margin-right: 16px;
}
.packagingImage img{
  width: 100% !important;
  margin-left: 0 !important;
}
.packagingImage .Polaris-  {
  margin-left: 0 !important;
}
.packagingImage span:hover{
  text-decoration: underline;
}
.Order_Detail_Sku_Remove{
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}
.reomve_sku_content {
  padding-left: 60px;
  margin-top: 16px;
}
.reomve_sku_content_item_r {
  display: flex;
  align-items: center;
}
.reomve_sku_content_item_r input{
  margin-left: 10px;
  outline: none;
  border-radius: 2px;
  height: 26px;
  border: 1px solid #A9A9AD;
}
.refund_copy_item {
  display: flex;
}
.refund_tip_copy {
  position: absolute;
  left: -100%;
  top: 0;
}

.dsm_order_search {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.dsm_order_search .dsm_search_type,.dsm_order_search .orderNumInput, .dsm_order_search .search_date_input, .dsm_order_search .searchBtn{
  padding-bottom: 2rem;
}

.dsm_order_search .search_date_input{
  display: flex;
}

.dsm_order_search .startDateInput,
.dsm_order_search .endDateInput {
  width: 130px !important;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  height: 36px;
}

.dsm_order_search .startDateInput {
  border-left: 1px solid #ccc;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.dsm_order_search .endDateInput {
  border-right: 1px solid #ccc;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dsm_order_search .timearrow{
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  height: 36px;
}
.dsm_order_search .timearrow img{
  height: 35px;
}

.dsm_order_search .orderNumInput {
  width: 350px !important;
}

.dsm_order_search .orderNumInput,
.dsm_order_search .searchBtn {
  margin:0 15px;
}
.radio_container  .Polaris-Choice__Control {
  margin-right: 0 !important;
}
.dsm_container , .pal_container{
  /* width: 260px; */
  display: flex;
  margin-right: 12px;
  height: 48px;
  align-items: center;
  /* justify-content: space-between; */
  margin-bottom: 10px;
  background: rgba(235, 235, 235, 1);
  border: 1px solid transparent;
}
.orderlist_container .iconBtn_container div img {
  height: 28px !important;
}
.order_invices {
  width: 30px;
}

.order_list {
  position: relative;
}

.Insurance_installation {
  position: absolute;
  left: 250px;
  top: 30px;
}
.merchant_banner {
  padding: 0 32px;
  margin-top: 32px;
}
.order_list .Polaris-Page__Content {
  position: relative;
}

.Insurance_installation img {
  position: absolute;
  height: auto;
  width: 410px;
}

.cost_order_list {
  min-width: 240px;
}

@media screen and (max-width: 600px){
  .dsm_order_search {
      display: block;
  }

  .dsm_order_search .orderNumInput {
      width: 100% !important;
  }

  .dsm_order_search .startDateInput, .dsm_order_search .endDateInput {
      width: 190px !important;
  }

  .dsm_order_search .orderNumInput, .dsm_order_search .searchBtn {
      margin:unset;
  }
  .search_container .search_select {
    position: relative;
    width: 100%;
    left: 0;
    top: 0;
    margin-top: 16px;
  }
}

@media screen and (max-width: 600px) {
  .list_order_name_item>div {
    width: auto;
  }
  .textField_container .textField_left,
  .textField_container .autoRight_container{
    /* width:250px; */
    width: 40%;
  }
  .auto_Fulfillment {
    margin: 5px 10px 16px 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .auto_Fulfillment-fr ,.auto_Fulfillment-es,.auto_Fulfillment-nl,.auto_Fulfillment-pt-pt,.auto_Fulfillment-pt-br,.auto_Fulfillment-it,.auto_Fulfillment-de {
    flex-direction: column !important;
    align-items: flex-start!important;
  }
  .auto_Fulfillment-fr .Polaris-InlineStack .item,.auto_Fulfillment-es .Polaris-InlineStack .item,.auto_Fulfillment-de .Polaris-InlineStack .item,.auto_Fulfillment-nl .Polaris-InlineStack .item,.auto_Fulfillment-pt-pt .Polaris-InlineStack .item,.auto_Fulfillment-pt-br .Polaris-InlineStack .item,.auto_Fulfillment-it .Polaris-InlineStack .item {
    margin-right: 8px;
  }
  .auto_Fulfillment-fr .item.btn,.auto_Fulfillment-es .item.btn,.auto_Fulfillment-nl .item.btn,.auto_Fulfillment-de .item.btn,.auto_Fulfillment-pt-pt .item.btn,.auto_Fulfillment-pt-br .item.btn,.auto_Fulfillment-it .item.btn {
    margin-left: 0 !important;
    margin-top: 16px;
  }
  .auto_Fulfillments > div {
    margin-bottom: 10px;
  }
  .auto_Fulfillments .mb_item {
    margin-bottom: 3px;
  }
  /* .auto_Fulfillments .item.btn {
    margin-left: 10px !important;
  } */
  .auto_Fulfillments .sync_button {
    margin-bottom: 0;
    margin-top: 10px;
  }
  .auto_Fulfillments .item , .auto_Fulfillments .item  button span{
    font-size: 12px !important;
  }
  .Insurance_installation {
    left: 0;
    top: 80px;
  }
  .Insurance_installation img {
    position: absolute;
    height: auto;
    width: 100vw;
    padding: 0 16px;
  }
  .orderlist_container .iconBtn_container div img {
    height: 28px !important;
  }
  .order_invices {
    width: 30px;
  }
  .search_keyword {
    flex-direction: column;
  }
  .search_item {
    margin-bottom: 10px;
    width: 100%;
  }
  .search_container .search_keyword .filter_input {
    width: 100%;
  }
  .orderlist_container {
    flex-wrap: wrap;
  }
  .orderlist_content {
    width: 92%;
    min-width: auto;
  }
  .orderlist_content div {
    /* margin-bottom: 10px; */
  }
  .button_container {
    flex-wrap: wrap;
    justify-content: flex-start;
    flex: auto;
  }
  .button_container .customer_btn {
    justify-self: start;
  }
  .button_container > div {
    margin: 10px 5px 0 0;
  }
  .button_container div {
    margin-left: 0 !important;
  }
  .iconBtn_container {
    margin-top: 10px;
  }
  .batchClick_container {
    flex-wrap: wrap;
  }
  .batchClick_container > div {
    margin-bottom: 8px;
  }
  .order_sku_title {
    max-width: 160px !important;
  }
  .orderlist_container .iconBtn_container div {
    margin-left: 0 !important;
  }
  .goods_container {
    flex-wrap: wrap;
  }
  .goods_content {
    width: 100%;
    margin-bottom: 10px;
  }
  .goods_footer {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .goods_footer > div {
    width: calc(100% / 2);
    margin-right: 0 !important;
    margin-bottom: 10px;
  }
  .bulk_order_id {
    width: 100%;
  }
  .radio_container_box {
    overflow-x: auto;
    overflow-y: hidden;
  }
  .radio_container {
    flex-wrap: nowrap;
    min-width: 570px;
  }
  .dsm_container , .pal_container{
    /* width: 260px; */
    margin-bottom: 10px;
  }
  .sync_store_order_range {
    margin: 0px 10px 0px 0;
  }
  .refund_tips_content {
    padding-left: 50px;
  }
  .shipping_methods_content {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .shipping_methods_content_tips {
    width: 100%;
    margin-top: 16px;
  }
  .country_option_div {
    width: 100%;
    flex-wrap: wrap;
  }
  .textField_container {
    width: 100%;
    flex-wrap: wrap;
  }
  .textField_container .autoRight_container {
    margin-left: 20px;
  }
  .order_tracking_no {
    width: 100% !important;
  }
  .list_order_name_item {
    /* flex-wrap: wrap; */
  }
  .list_order_name_item > div {
    margin-right: 10px;
  }
}

@media screen and (max-width: 769px) {
  .auto_Fulfillments {
    flex-direction: column;
    align-items: flex-start;
  }
  .auto_Fulfillments > div {
    margin-bottom: 10px;
  }
  .auto_Fulfillments .mb_item {
    margin-bottom: 3px;
  }
  /* .auto_Fulfillments .item.btn {
    margin-left: 10px !important;
  } */
  .auto_Fulfillments .sync_button {
    margin-bottom: 0;
    margin-top: 10px;
    margin-left: 0;
  }
}
@media screen and (min-width: 1024px) and (max-width:1850px) {
  /* .search_keyword {
    flex-direction: column;
  } */
  .search_item {
    margin-bottom: 10px;
    /* width: 100%; */
  }
  /* .search_container .search_keyword .filter_input {
    width: 100%;
  } */
  .orderlist_container .iconBtn_container{
    margin-right: 0 !important;
  }
  .orderlist_container .iconBtn_container div img {
    height: 28px !important;
  }
  .order_invices {
    width: 30px;
  }
  .orderlist_container {
    flex-wrap: wrap;
  }
  .orderlist_content {
    width: 45%;
  }
  /* .orderlist_content div {
    margin-bottom: 10px;
  } */
  .list_order_name_item > div {
    width: auto !important;
  }
  .button_container {
    flex-wrap: wrap;
    justify-content: flex-start;
    flex: auto;
  }
  .button_container .customer_btn {
    justify-self: start;
  }
  .button_container > div {
    margin: 10px 5px 0 0;
  }
  .button_container div {
    margin-left: 0;
  }
  .iconBtn_container {
    margin-top: 10px;
  }
  .batchClick_container {
    flex-wrap: wrap;
  }
  /* .batchClick_container > div {
    margin-bottom: 8px;
  } */
  .goods_container {
    flex-wrap: wrap;
  }
  .goods_content {
    margin-bottom: 10px;
  }
  .goods_footer {
    /* flex-wrap: wrap; */
    justify-content: flex-end;
  }
  .goods_footer > div {
    /* width: calc(100% / 2); */
    margin-right: 8px !important;
    margin-bottom: 10px;
  }
  .bulk_order_id {
    width: 100%;
  }
  .radio_container {
    /* flex-direction: column; */
  }
  .dsm_container , .pal_container{
    /* width: 260px; */
    margin-bottom: 10px;
  }
  .sync_store_order_range {
    margin: 0px 10px 0px 0;
  }
  .refund_tips_content {
    padding-left: 50px;
  }
  .shipping_methods_content {
    flex-wrap: wrap;
  }
  .shipping_methods_content_tips {
    width: 100%;
    margin-top: 16px;
  }
  .country_option_div {
    width: 100%;
    flex-wrap: wrap;
  }
  .textField_container {
    width: 100%;
    flex-wrap: wrap;
  }
  .textField_container .autoRight_container {
    margin-left: 20px;
  }
  .list_order_name_item {
    flex-wrap: wrap;
  }
  .list_order_name_item > div {
    margin-right: 10px;
  }
  
}
@media screen and (min-width:1024px) and (max-width: 1650px) {
  .order_button_group_tab {
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start;
  }
  .order_button_group_tab .order_button_group_tab_item {
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 600px) and (max-width:1024px) {
  .orderlist_container .iconBtn_container div img {
    height: 28px !important;
  }
  .order_invices {
    width: 30px;
  }
  .search_keyword {
    flex-direction: column;
  }
  .search_item {
    margin-bottom: 10px;
    width: 100%;
  }
  .search_container .search_keyword .filter_input {
    width: 100%;
  }
  .orderlist_container .iconBtn_container{
    margin-right: 0 !important;
  }
  .orderlist_container {
    flex-wrap: wrap;
  }
  .orderlist_content {
    width: 40%;
  }
  .orderlist_content div {
    margin-bottom: 10px;
  }
  .list_order_name_item > div {
    width: auto !important;
  }
  .button_container {
    flex-wrap: wrap;
    justify-content: flex-start;
    flex: auto;
  }
  .button_container .customer_btn {
    justify-self: start;
  }
  .button_container > div {
    margin: 10px 5px 0 0;
  }
  .button_container div {
    margin-left: 0;
  }
  .iconBtn_container {
    margin-top: 10px;
  }
  .batchClick_container {
    flex-wrap: wrap;
  }
  .batchClick_container > div {
    margin-bottom: 8px;
  }
  .goods_container {
    flex-wrap: wrap;
  }
  .goods_content {
    width: 100%;
    margin-bottom: 10px;
  }
  .goods_footer {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .goods_footer > div {
    /* width: 20%; */
    margin-right: 8px !important;
    margin-bottom: 10px;
  }
  .bulk_order_id {
    width: 100%;
  }
  .radio_container {
    flex-direction: column;
  }
  .dsm_container , .pal_container{
    /* width: 260px; */
    margin-bottom: 10px;
  }
  .sync_store_order_range {
    margin: 0px 10px 0px 0;
  }
  .refund_tips_content {
    padding-left: 50px;
  }
  .shipping_methods_content {
    flex-wrap: wrap;
  }
  .shipping_methods_content_tips {
    width: 100%;
    margin-top: 16px;
  }
  .country_option_div {
    width: 100%;
    flex-wrap: wrap;
  }
  .textField_container {
    width: 100%;
    flex-wrap: wrap;
  }
  .textField_container .autoRight_container {
    margin-left: 20px;
  }
  .list_order_name_item {
    flex-wrap: wrap;
  }
  .list_order_name_item > div {
    margin-right: 10px;
  }
}


@media screen and (min-width: 1024px) and (max-width:1200px) {
  .sourcing_page_head {
    align-items: flex-start;
  }
}

@media screen and (max-width: 1280px) {
  .search_keyword {
    flex-wrap: wrap;
  }
  .orderlist_container {
    flex-wrap: wrap;
  }
  .goods_footer {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 1200px) and (max-width:1366px) {
  .auto_Fulfillment .icon {
    margin-right: 0;
  }
}

.import_product_content {
    max-width: 1200px;
    margin: 16px auto;

}
.import_product_content_box {
    flex: 1;
    padding-left: 60px;
}
.import_product_content_box_title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: rgba(48, 48, 48, 1);
}
.import_product_content_box_desc {
    font-size: 13px;
    font-weight: 450;
    line-height: 20px;
    color: rgba(48, 48, 48, 1);
    margin: 8px 0 20px 0;
}
.import_product_content_box_image {
    height: 276px;
}
.import_product_content_box_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.import_ali_modal_content p{
    font-size: 13px;
    font-weight: 450;
    line-height: 20px;
    color: rgba(48, 48, 48, 1);
    word-break: keep-all;
}
@media screen and (max-width: 420px) {
    .import_product_content_box {
        padding: 10px !important;
    }
}
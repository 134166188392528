.dropshipman_linking_store{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ff9d0a;
    z-index: 100;
    display: flex;
}

.dropshipman_linking_store .linking_store_content{
    display: flex;
    align-items: center;
    justify-content: center;
}

.linking_form{
    min-width: 325px;
}

@media (max-width: 350px){
    .linking_form{
        min-width: 300px;
    }
}

.linking_form .linking_button{
    margin-top: 40px;
    text-align: center;
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: space-evenly;
}

.linking_button{
    justify-items: center;
    align-items: center;
    justify-content: space-between;
}

.linking_logo{
    text-align: center;
    margin-bottom: 30px;
}

.linking_logo img{
    width: 200px;
    margin-bottom: 20px;
}

.linking_sub{
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
}

.linking_store_content{
    width: 500px;
    height: 100%;
    padding: 20px 80px;
    background-color: #fff;
    flex-shrink: 0;
    flex-grow: 0;
}

.linking_input .Polaris-TextField__Suffix{
    margin-right: .2rem;
}

.linking_input .suffix{
    background-color: #f4f5f8;
    border-top: 1px solid #aeb4b9;
    border-bottom: 1px solid #c9cccf;
    line-height: 2.5;
    padding: 0 6px;
    color: #575962;
}

.linking_store_other{
    flex-grow: 1;
    flex-shrink: 1;
    flex-direction: column;
    overflow: hidden;
    padding: 30px 0;
}

.linking_store_other .text{
    font-size: 4rem;
    font-weight: 600;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 1.5;
}

.binding_error{
    margin-top: 20px;
}
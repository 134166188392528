.tab_container {
    background-color: #fff;
    border-radius: 12px;
    margin-bottom: 16px;
    padding: 16px 0;
}
.upload_branding_button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 16px;
}
.search_container_btn {
    min-width: 100px;
    display: flex;
    align-items: center;
}
.search_container_btn button {
    height: 32px;
}
.type_filter_clear span {
    font-size: 14px;
}
.brand_search_container {
    padding: 10px 12px;
    display: flex;
}
.search_container_input {
    flex: 1;
    margin-right: 10px;
}
.search_container_filter {
    display: flex;
}
.search_container_filter .search_container_input ,.brand_search_container .search_container_input{
    /* flex: 0.5; */
    /* width: 200px; */
}
.search_container_filter .search_type {
    margin-right: 16px;
    width: 182px;
}
.search_container_order_btn button {
    margin-right: 10px;
    height: 32px;
}
.list_content_tab_select {
    margin-left: 16px;
    display: flex;
}
.list_content_tab_select_button {
    margin-left: 16px;
}




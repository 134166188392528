.Search_Loading {
  position: absolute;
  background-color: #fff;
  opacity: 0.5;
  border-radius: 8px;
  top: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  z-index: 99;
}

.Search_Loading_Image {
  position: absolute;
  left: 50%;
  top: 5%;
}

.Search_Loading_Image img {
  width: 32px !important;
  height: 32px !important;
  vertical-align: middle;
}
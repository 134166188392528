.Inventory_Product .Inventory_Product__Title{
    min-width: 200px;
    max-width: 20vw;
    word-break: break-all;
    white-space: normal;
    font-weight: 500;
    color: #202223;
}

.Inventory_Product .Inventory_Variant_Title{
    font-size: 12px;
    color: #202223;
}

.Inventory_Action .Polaris-TextField{
    min-width: 100px;
    max-width: 150px;
}

.Inventory_Action .Polaris-Connected__Item--primary{
    flex: 0 1 auto;
}

.No_Available{
    color: red;
    font-weight: 500;
}
@media screen and (max-width: 768px) {
    .Inventory_Product .Inventory_Product__Title {
        min-width: 100px;
    }
    .purchase_list ,.history_log {
        margin: 0 10px;
    }
    .history_top_sku_title {
        flex: 1;
    }
    .history_top_sku_title p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
}
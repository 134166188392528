.sourcing_table_title_image {
}
.sourcing_table_title_img {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #e5e5e5;
}
.sourcing_table_title_img img {
    width: 100%;
    height: 100%;
}
.sourcing_table_title_text {
    min-width: 20rem;
    max-width: 20vw;
    word-break: break-word;
    white-space: normal;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.sourcing_table_view_more {
    font-weight: 450;
    margin-bottom: 8px;
    width: max-content;
    border-bottom: 2px dotted rgba(235, 235, 235, 1);
}


/* 弹窗的遮罩层 */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-end; /* 从底部对齐 */
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    z-index: 31;
  }
  
  .popup-overlay.show {
    opacity: 1;
    visibility: visible;
  }
  
  /* 弹窗主体 */
  .popup {
    width: 100%;
    max-width: 100%;
    height: calc(100% - 56px);
    background: rgb(241, 241, 241);
    border-radius: 10px 10px 0 0;
    padding: 20px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
  }
  
  .popup-overlay.show .popup {
    transform: translateY(0);
  }

  .sourcing_request_head {
    padding: 14px 0;
  }
  .sourcing_product_description{
    min-height: 300px;
    max-height: 600px;
    overflow: auto;
}
.sourcing_product_description .ck-editor__editable_inline {
    min-height: 320px;
}

.ck-editor__editable h1{
    margin: 10px;
    font-size: 14px;
    font-weight: 600;
}
.ck-editor__editable h2{
    margin: 8px;
    font-size: 1.2em;
    font-weight: 600;
}
.ck-editor__editable h3{
    margin: 5px;
    font-size: 11px;
    font-weight: 500;
}
.ck-editor__editable h4{
    margin: 2px;
    font-size: 10px;
    font-weight: 400;
}
.ck-editor__editable h5{
    margin: 2px;
    font-size: 10px;
    font-weight: 400;
}

.media_box{
    margin-top: 10px;
}

.Sourcing__Loading{
    position: absolute;
    opacity: 0.5;
    top: 0;
    height: 100%;
    width: 60px;
    text-align: center;
    z-index: 99;
}

.Sourcing_Request__Loading_Image {
    position: absolute;
    left: 25%;
    top: 25%;
}

.Sourcing_Image_Item:hover .Sourcing__Loading{
    background-color: #fff;
}

.Sourcing_Request__Image{
    position: absolute;
    left: 50%;
    top: 15%;
}

.image_remove__button{
    background: transparent;
    width: 18px;
    height: 20px;
    box-shadow: none;
    cursor: pointer;
    padding: 0;
    margin: 0 0 0 8px;
    border: 0;
    -webkit-appearance: none;
    outline: none;
}
.sourcing_request_body .table,.sourcing_request_body  tbody ,.sourcing_request_body  tr {
  cursor: default;
}
.agreement_accept_sourcing{
    margin-top: 10px;
    position: relative;
    left: 0;
    bottom: 0;
    z-index: 99;
    /* background: #fff; */
    padding: 20px 10px 10px;
    /* border-top: 1px solid #e1e3e5; */
    display: flex;
    justify-content: space-between;
}

.agreement_accept_button{
    display: flex;
}
/* .agreement_accept_button button:first-child{
    margin-right: 15px;
} */
.sourcing_modal_list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.sourcing_modal_item {
    width:  calc(100% / 2 - 10px);
    margin-right: 20px;
    margin-bottom: 8px;
}
.sourcing_modal_item:nth-child(2n) {
    margin-right: 0;
}
.sourcing_modal_item .btn_currency {
  width: 100%;
}
.agreement_accept_button button {
    min-width: 120px;
}
.reset_source_status {
  margin-left: 4px;
}
.reset_source_status svg {
  fill: #8e0b21 !important;
}
@media screen and (max-width:560px) {
    .agreement_accept_sourcing{
        margin-top: 10px;
        position: sticky;
        left: 0;
        bottom: 0;
        background: #fff;
        display: block;
        padding: 20px 10px 10px;
        
        /* border-top: 1px solid #e1e3e5; */
        justify-content: space-between;
    }

    .agreement_accept_button{
        height: 48px;
        justify-content: center !important;
        margin-top: 15px !important;
    }
    .agreement_accept_button button {
        width: 100%;
    }
    .sourcing_modal_item {
        width:  100%;
        margin-bottom: 8px;
        margin-right:0;
    }
}
  .sourcing_popup .agreement_accept_sourcing {
    justify-content: flex-end;
  }
  .sourcing_enough_quota_left {
    flex: 1;
    background: linear-gradient(
      to left,
      rgba(245, 82, 12, 1),
      rgb(231, 112, 61,1) 50%,
      rgb(231, 112, 61,1) 
    );
    background-size: 200% 100%;
    animation: gradient-move 3s infinite;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .sourcing_enough_quota_left div {
    width: 90%;
  }
  .sourcing_enough_quota_left div img {
    width: 100%;
  }
 
  .sourcing_enough_quota_right {
    width: 329px;
    padding: 24px 16px 16px 16px;
  }
.sourcing_enough_quota_title {
  font-size: 20px;
  font-weight: 650;
  line-height: 24px;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 12px;
}
  .sourcing_enough_quota_item {
  width: 100%;
  padding: 8px 12px;
  border-radius:12px;
  background-color: rgba(243, 243, 243, 1);
}
.sourcing_enough_quota_item_active {
  background:rgba(48, 48, 48, 1);
}
.sourcing_enough_quota_item_content span {
  font-size: 12px;
}
.sourcing_enough_quota_item_active .sourcing_enough_quota_item_content strong , .sourcing_enough_quota_item_active .sourcing_enough_quota_item_content p span , .sourcing_enough_quota_item_active .sourcing_enough_quota_item_content p{
  color: #fff;
}
.sourcing_enough_quota_subtitle {
  font-size: 13px;
  font-weight: 650;
  line-height: 20px;
  color: rgba(0, 0, 0, 1);
  margin-top: 10px;
}
.sourcing_enough_quota_subtitle_li {
  margin-bottom: 16px;
}
.sourcing_enough_quota_subtitle_li ul {
  padding-left: 16px;
  margin: 5px 0;
}
.sourcing_enough_quota_subtitle_li ul li {
  font-size: 13px;
  font-weight: 450;
  line-height: 20px;
  color: rgba(48, 48, 48, 1);
}
.sourcing_enough_quota_item_content strong {
  font-weight: 550;
}
.sourcing_enough_quota_item_content p {
  color: rgba(118, 118, 118, 1);
}
.sourcing_enough_quota_item_link {
  margin-top: 16px;
  cursor: pointer;
  color: rgba(0, 91, 211, 1);
  text-decoration: underline;
}
.sourcing_enough_quota_item_link:hover {
  text-decoration: underline;
  color: rgba(0, 91, 211, 1);
}
.sourcing_enough_quota_item_button {
  display: flex;
  justify-content: flex-end;
  margin: 32px 0 0 0;
}
.sourcing_enough_quota_subtitle_li li {
  list-style: disc;
}
.tips_title {
  font-size: 13px;
  font-weight: 650;
  line-height: 20px;
  color: rgba(48, 48, 48, 1);
}
.action_btn_go {
  width: 138px;
}
.action_btn_go button {
  width: max-content;
}
.action_btn_view button {
  width: 100%;
}
.action_btn_delete button{
  color: rgba(0, 91, 211, 1);
}
.action_btn_delete button:hover {
  color: rgba(0, 91, 211, 0.7);
}
.source_mobile_item_right_title {
  font-size: 13px;
  font-weight: 550;
  line-height: 20px;
  color: rgba(48, 48, 48, 1);
}
.source_mobile_item_right_type {
  font-size: 12px;
  font-weight: 450;
  line-height: 16px;
  color: rgba(97, 97, 97, 1);
}
.sourcing_product__add_image {
  width: 88px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sourcing_product__add_image span {
  width: 40px;
  height: 40px;
}
@media screen and (max-width: 420px) {
  .source_mobile_item_box {
    border-bottom: 1px solid rgba(235, 235, 235, 1);
    padding: 16px 10px;
  }
  .source_mobile_item_right {
    flex: 1;
  }
  .source_mobile_item_right_button .action_btn_go {
    flex: 1 !important;
  }
  .source_mobile_item_right_button_shopify  .action_btn_go {
    flex: auto !important;
  }
  .source_mobile_item_right_button_shopify  .action_btn_go button {
    max-width: 160px;
  }
  .sourcing_enough_quota_left {
    display: none;
  }
  .sourcing_enough_quota_right {
    width: 100%;
  }
  .sourcing_popup .Polaris-Page {
    margin-top: 0 !important;
  }
}
.country_option_div{
  display: flex;
  /* width:542px; */
  margin-bottom: 16px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
}

.country_option_div div{
  flex:1;
  font-weight:500;
}

.textField_container{
  display: flex;
  /* width:580px; */
}
.error_input input {
  border-color: #f00;
}
.textField_container .textField_left,
.textField_container .autoRight_container{
  /* width:250px; */
  width: 45%;
}
.error_input .textField_left:nth-child(1) input{
  border-color: #f00;
}
.error_input .textField_right:nth-child(1) input{
  border-color: #f00;
}
.textField_container .autoRight_container{
  margin-left:10px;
  position:relative;
}

.textField_right .Polaris-Connected{
  margin-bottom:5px;
}

.XSmallIcon{
  display: flex;
  flex-direction:column;
  align-content:center;
  position:absolute;
  right:-26px;
  bottom:10px;
  cursor: pointer;
}

.DeleteIcon{
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.DeleteIcon .Polaris-Icon{
  cursor: pointer;
}

.textField_right{
  position: relative;
}
.common_modal {
    position: fixed;
    background: rgba(0,0,0,.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 101;
}
.common_modal_mask {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.common_modal_box {
    background-color: #fff;
    position: relative;
    background: var(--p-color-bg-surface);
    box-shadow: var(--p-shadow-600);
    overflow: hidden;
    border-radius: 10px;
}
.common_modal_close {
    position:absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}
.common_modal_show {
    display: none;
}
.common_modal_content {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}
.fourth_content_modal_box {
    padding: 16px;
    min-height: 200px;
}
.fourth_content_modal_img {
    min-height: 100px;
}
.fourth_content {
    /* padding: 36px; */
}
.fourth_content_modal_img {
    width: 100%;
    line-height: 1;
}
.fourth_content_modal_img img {
    width: 100%;
    max-height: 215px;
}
.fourth_content_head ,.fourth_content_mid ,.fourth_content_button ,.fourth_content_p{
    display: flex;
    align-items: baseline;
    justify-content: center;
}
.fourth_content_head {
   margin-bottom: 24px;
}
.fourth_content_head strong {
    font-size: 60px;
    margin: 0 10px;
}
.fourth_content_head span {
    font-size: 16px;
}
.fourth_content_mid {
    margin-top: 24px;
}
.fourth_content_mid strong{
    font-size: 30px;
    margin-right: 10px;
}
.fourth_content_mid span {
    font-size: 30px;
    font-weight: 700;
}
.fourth_content_button button {
    width: 100%;
    height: 50px;
    padding: 8px 20px;
    margin:0 40px 24px;
}
.fourth_content_p {
    margin-bottom: 16px;
    margin-top: 10px;
    font-size: 15px;
    color: #767676;
}
.agent_modal .common_modal_close svg {
    fill: #202333;
}
.agent_modal .common_modal_title {
    background-color: rgb(243, 243, 243);
    border-block-end-width :1px;
    border-block-start-width: 0px;
    border-bottom-color: rgb(227, 227, 227);
    border-bottom-style :solid;
    border-end-end-radius :0px;
    border-end-start-radius: 0px;
    border-inline-end-width: 0px;
    border-inline-start-width :0px;
    border-left-color: rgb(227, 227, 227);
    border-left-style: solid;
    border-right-color: rgb(227, 227, 227);
    border-right-style: solid;
    border-start-end-radius: 0px;
    border-start-start-radius: 0px;
    border-top-color: rgb(227, 227, 227);
    border-top-style: solid;
    box-shadow: none;
    box-sizing: border-box;
    color: rgb(48, 48, 48);
    color-scheme: light;
    display: block;
   
    font-size :13px;
    font-weight :450;
    height: 53px;
    inset-block-end :auto;
    inset-block-start :auto;
    inset-inline-end :auto;
    inset-inline-start :auto;
    letter-spacing: normal;
    line-height :20px;
    max-width :none;
    min-height :auto;
    min-width :auto;
    outline-color :rgb(48, 48, 48);
    outline-style: none;
    outline-width: 0px;
    overflow-x: visible;
    overflow-y: visible;
    padding-block-end: 16px;
    padding-block-start: 16px;
    padding-inline-end: 16px;
    padding-inline-start: 16px;
    pointer-events: auto;
    text-rendering :optimizelegibility;
    text-size-adjust: 100%;
}
.agent_modal .common_modal_title p {
    font-weight: 650;
} 
.agent_modal .common_modal_button {
    align-items: center;
    box-sizing: border-box;
    color: rgb(48, 48, 48);
    color-scheme: light;
    column-gap: 16px;
    display :flex;
    flex-wrap :wrap;
    font-size: 13px;
    font-weight: 450;
    height :61px;
    justify-content: normal;
    letter-spacing: normal;
    line-height: 20px;
    pointer-events: auto;
    row-gap: 16px;
}
.agent_modal .common_modal_button .common_modal_button_box {
    background-color: rgba(0, 0, 0, 0);
    border-block-end-width: 0px;
    border-block-start-width: 1px;
    border-bottom-color: rgb(227, 227, 227);
    border-bottom-style: solid;
    border-end-end-radius: 0px;
    border-end-start-radius: 0px;
    border-inline-end-width: 0px;
    border-inline-start-width: 0px;
    border-left-color: rgb(227, 227, 227);
    border-left-style: solid;
    border-right-color: rgb(227, 227, 227);
    border-right-style: solid;
    border-start-end-radius: 0px;
    border-start-start-radius: 0px;
    border-top-color :rgb(227, 227, 227);
    border-top-style :solid;
    box-shadow: none;
    box-sizing: border-box;
    color :rgb(48, 48, 48);
    color-scheme :light;
    display: flex;
    justify-content: flex-end;
    font-size: 13px;
    font-weight: 450;
    width: 100%;
    height: 61px;
    inset-block-end: auto;
    inset-block-start: auto;
    inset-inline-end: auto;
    inset-inline-start: auto;
    letter-spacing: normal;
    line-height :20px;
    max-width: none;
    min-height: auto;
    min-width: auto;
    outline-color: rgb(48, 48, 48);
    outline-style: none;
    outline-width: 0px;
    overflow-x :visible;
    overflow-y :visible;
    padding-block-end: 16px;
    padding-block-start: 16px;
    padding-inline-end: 16px;
    padding-inline-start :16px;
    pointer-events: auto;
    text-rendering :optimizelegibility;
    text-size-adjust :100%;
}
.common_modal_button_box button:nth-of-type(1) {
    margin-right: 10px;
}
@keyframes modalFadeIn {
    from {
      opacity: 0;
      transform: translateY(10%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes modalFadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-10%);
    }
  }
  .custom-modal-enter {
    animation: modalFadeIn 0.3s forwards;
  }
  
  .custom-modal-exit {
    animation: modalFadeOut 0.3s forwards;
  }
  .common_modal_close svg {
    fill: #e5e5e5;
  }
  @media screen and (max-width: 420px) {
    .fourth_content_mid span {
        font-size: 20px;
    }
    .fourth_content_mid strong {
        font-size: 20px;
    }
    .fourth_content_button button {
        margin: 0 16px 20px;
    }
    .fourth_content_button button span {
        font-size: 14px !important;
    }
    .common_modal_box {
        margin: 0 16px;
        margin-top: -155px;
    }
    .common_modal  {
        z-index: 9999;
    }
  }

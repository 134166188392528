.CardContainerLeft >.Polaris-Card{
    min-height: 800px;
}
.Sourcing_Table{
    overflow: auto;
}
.Sourcing_Table>.Polaris-Card{
    overflow: auto;
    
}
.Polaris-Card {
    position: relative;
}

.Sourcing_Table table>tbody td{
    font-size: 14px;
    padding: 12px 8px;
}

.Sourcing_Table table>tbody td:first-child{
    padding-left: 16px;
}

.Sourcing_Table table>tbody tr{
    padding-bottom: 10px;
    border-top: 1px solid #e6e6e6;
} 

.Sourcing_Table{
    margin: 2rem 0;
}

.Sourcing_Table table>thead th:last-child{
    text-align: center;
}

.Sourcing_Table table>thead th{
    text-align: left;
    font-size: 14px;
    padding: 20px 0 20px 10px;
    font-weight: 600;
}
.tab-awaiting-confirmation {
    display: flex;
    align-items: center;
}
.tab-awaiting-confirmation svg path{
    fill: #303030;
}
.Sourcing_Table table>tbody tr:hover{
    background-color: #f9fafb;
} 

.Sourcing_Table .Ticket_Order{
    min-width: 75px;
}
.Sourcing_Table .Ticket_ID{
    min-width: 75px;
}

.Sourcing_Table .Ticket_Image{
    margin-right: 10px;
}

.Sourcing_Table .Ticket_Time{
    min-width: 150px;
}

.Sourcing_Table .Ticket_Product_Title span{
    min-width: 20rem;
    max-width: 20vw;
    word-break: break-word;
    white-space: normal;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.Sourcing_Table .Ticket_Purchase_Type{
    min-width: 150px;
}
.Sourcing_Table .Ticket_Type{
    min-width: 145px;
}
.Sourcing_Table .Ticket_Status{
    min-width: 160px;
}
.Sourcing_Table .Ticket_Price{
    min-width: 145px;
}

.Sourcing_Table .Ticket_Actions{
    min-width: 300px;
    text-align: center;
}

.awaiting_status {
    position: relative;
    cursor: pointer;
}

.awaiting_status .awaiting_mark {
    position: absolute;
    top: -17px;
    right:0;
    background: #FF9635;
    border-radius: 50%;
    width: 23px;
    height: 23px;

}
.awaiting_status .awaiting_mark img{
    width: 18px;
    height: 18px;
    margin: 2px 0 0 2px;
}

.Ticket_Status.Failed .Polaris-Badge{
    background-color: #fead9a;
}

.Sourcing_Action{
    display: inline-block;
}

.Sourcing_Action.Button{
    vertical-align: middle;
    margin-right: 20px;
}

.Polaris-Header-Title__SubTitle{
    color: gray;
}

@media screen and (min-width: 768px){
    .CardContainer{
        display: flex;
        justify-content: space-between;
    }
    
    .CardContainer .CardContainerRight{
        width: 250px;
        margin-left: 2em;
    }
}

@media screen and (max-width: 768px){
    .CardContainer .CardContainerRight{
        width: 100%;
        padding-top: .6em;
    }
}

.CardContainer .CardContainerLeft{
    width: 100%;
}

.CardContainer .RightCard{
    padding: 2em;
    position: relative;
}
.CardContainer .RightCard .RightCardTop{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
    
}

.CardContainer .RightCard .RightCardTop .New_tag{
    width:40px;
    height:40px;
    position: absolute;
    right: 0;
    top: 0;
}

.CardContainer .RightCard .RightCardTop span{
    margin-left: .5em;
    width: 140px;
}

.CardContainer .RightCard .HavealookBtn{
    text-align: center;
    margin-top: 2.2em;
}

/* 右侧sourcing广场入口配置 */
.Sourcing_Square_Box .RightCard{
    padding: 2em;
    position: relative;
}

.Sourcing_Square_Box .RightCard .RightCardTop .New_tag{
    width:40px;
    height:40px;
    position: absolute;
    right: 0;
    top: 0;
}

.Sourcing_Square_Box .RightCard .RightCardTop{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.Sourcing_Square_Box .RightCard .HavealookBtn{
    text-align: center;
    margin-top: 22px;
}

.Dropshipman_Sourcing .Polaris-Layout__Section--secondary{
    flex: 0 1 240px;
    min-width: 0;
}
.sourcing_page_head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}
.sourcing_page_head >div  .Polaris-Button.Polaris-Button--pressed{
    background-color: #303030;
    color: #fff;
}
.sourcing_page_head .Polaris-ButtonGroup__Item button {
    padding: 10px 16px;
    min-width: 140px;
}
.sourcing_page_head_title p{
    color: #808080;
}
.sourcing_page_head_button_switch {
    display: flex;
    align-items: center;
}
.sourcing_page_head_button_switch .icon{
    margin-top: 4px;
    width: 20px;
    height: 20px;
}

.novice_content {
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.novice_right {
    margin-left: 20px;
    cursor: pointer;
}

@media screen and (max-width: 1060px){
    .Dropshipman_Sourcing .Polaris-Layout__Section--secondary{
        flex: 1 1 24rem;
    }
}
@media screen and (max-width:1200px) {
    .sourcing_page_head {
        flex-direction: column;
        padding: 0 16px;
    }
    
    .sourcing_page_head .novice_content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-left: 0;
    }
    .sourcing_page_head .novice_content > div {
        margin-top: 24px;
    }
    .sourcing_page_head_button_switch {
        margin: 10px 0;
    }
    .auto_Fulfillment .item {
        margin-left: 0 !important;
    }
    .novice_right {
        margin-top: 10px;
    }
}
@media screen and (max-width:600px){
    .sourcing_page_head .Polaris-ButtonGroup__Item {
        width: 50%;
    }
    .sourcing_page_head .Polaris-ButtonGroup__Item button {
        width: 100%;
    }
    .novice_right img {
        height: 40px;
    }
    .sourcing_page_head {
        flex-direction: column;
        padding: 0 16px;
    }
    
    .sourcing_page_head .novice_content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-left: 0;
    }
    .sourcing_page_head .novice_content > div {
        margin-top: 24px;
    }
    .sourcing_page_head_button_switch {
        margin: 10px 0;
    }
    .auto_Fulfillment .item {
        margin-left: 0 !important;
    }
    .novice_right {
        margin-top: 10px;
    }
}
.sourcing_filter_title {
    position: relative;
    /* display: flex; */
    /* align-items: center; */
}
.sourcing_filter_select {
    max-width: 130px;
    min-width: 130px;
    position: absolute;
    left: 0;
    top: 7px;
    z-index: 101;
}
.sourcing_filter_filter {
    flex: 1;
}
.sourcing_filter_filter > div .Polaris-Filters__Container {
    padding-left: 130px;
}
.check_google_plugin_ol {
    padding-left: 25px;
    font-size: 16px;
}

.check_google_plugin_ol li {
    list-style-type: decimal;
}

.check_google_plugin_ol .Polaris-Link .Polaris-Link__IconLockup {
    display: none;
}

.Dropshipman_Sourcing .Polaris-Page .Polaris-ActionMenu .Polaris-Button {
    border: 1px solid var(--p-border-neutral-subdued) !important;
    background: var(--p-surface) !important;
}

.Dropshipman_Sourcing .Polaris-Page .Polaris-ActionMenu-SecondaryAction a:hover, 
.Dropshipman_Sourcing .Polaris-Page .Polaris-ActionMenu-SecondaryAction button:hover {
    background: var(--p-background-hovered) !important;
}

.sourcingLimitModalTitle {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.sourcingLimitModalTitle .Polaris-Icon {
    margin: 0 0;
}

.modalHasIcon {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.modalHasIcon .Polaris-Icon {
    margin: 0 0;
}
.datepick_orders_search_date.datepick_order_all {
  /* position: fixed; */
  /* z-index: 999; */
  padding-top: 16px;
}

.datepick_orders_search_date.datepick_order_all .startDateInput,
.datepick_orders_search_date.datepick_order_all .endDateInput {
  width: auto !important;
}

.sync_store_order_modal_contain .Polaris-Stack__Item:last-child {
  height: 60px;
}

.errorBorder {
  border-color: #d82c0d !important;
}

.errorBackground {
  position: relative;
  background-color: #fff4f4 !important;
}

.dateRequired {
  color: #f6450d;
}

.tipContent {
  position: absolute;
  display: flex;
  gap: 1rem;
  align-items: center;
  bottom: -32px;
}

.tipContent .Polaris-Icon {
  height: 1.6rem;
  width: 1.6rem;
  max-height: 100%;
  max-width: 100%;
  margin: 0 0;
}

@media screen and (max-width: 540px) {
  .datepick_orders_search_date.datepick_order_all {
    position: unset;
  }
  .sync_store_order_modal_contain .Polaris-Stack__Item:last-child {
    height: 100px;
  }
}

.subscription_page .Polaris-Page{
  max-width: 119.8rem;
}

.subscript_section_content {
  display: flex;
  margin-top: 48px;
}

.plan_upgrade_head {
  /* display: flex; */
  text-align: center;
  font-size: 28px;
  line-height: 1.2;
}
.plan_upgrade_head p {
  font-weight: 700;
  /* margin-left: 6px; */
  margin-top: 10px;
}
.plan_upgrade_head strong {
  color: #f5520c;
  font-size: 36px;
}
.plan_grade_content {
  padding: 20px 50px;
}
.plan_upgrade_conetent {
  display: flex;
}
.plan_upgrade_conetent ul li{
  list-style: unset;
  font-size: 15px;
  height: 30px;
}
.plan_upgrade_conetent_button {
  text-align: center;
  margin: 36px 0;
}
.plan_upgrade_conetent_button button {
  background-color: #f5520c;
  color: #fff;
  height: 50px;
  padding: 8px 40px;
  border-color: transparent;
}
.plan_upgrade_conetent_button button:hover {
  background-color: #f5520c;
}
.plan_upgrade_conetent_des {
  text-align:center;
  color: #f5520c;
  margin-top: 16px;
}
.shopify_products_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.seo_ant_top_btn {
  display: flex;
  justify-content: flex-end;
}
.seo_ant_top_btn button , .seo_ant_top_btn a{
  display: flex;
  /* width: 30%; */
  border-color: transparent;
}
.seo_ant_top_btn .btnContent {
  display: flex;
  align-items: center;
}

.seo_ant_top_btn .prefixIcon {
  display: flex;
  align-items: center;
}
.seo_ant_top_btn .itemText {
  font-weight: 500;
  font-size: 15px;
  flex: 1;
  padding: 0 16px;
}
.seo_ant_top_btn .Polaris-Button__Text {
  width: 100%;
}
.affiliate_top {
  display: flex;
  background: #fff;
  padding:20px 16px;
}
.affiliate_top_left {
  width: 27%;
  display: flex;
  justify-content: center;
}
.affiliate_top_right {
  flex: 1;
  margin-left: 16px;
}
.affiliate_top_right_detail {
  font-size: 18px;
  margin-right: 50px;
  line-height: 1.5;
}
.affiliate_top_right_button {
  margin-top: 28px;
  display: flex;
  align-items: center;
}
.affiliate_top_right_button button {
  max-width: 200px;
}
.affiliate_top_right_button div {
  margin-left: 24px;
  margin-right: 39px;
}
.affiliate_copy_link {
  /* max-width: 400px; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
}
.affiliate_top_right_button div a {
  color: #23A5EE;
  font-size: 16px;
}
.affiliate_top_right_button div span svg {
  vertical-align: bottom;
}
.affiliate_mid {
  background: #fff;
  margin-top: 20px;
  padding: 24px 0 40px;
}
.affiliate_mid h3 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  padding: 16px 0 32px;
}
.affiliate_mid_detail {
  display: flex;
  align-items: baseline;
  justify-content: space-around;
}
.affiliate_mid_item {
  width: 200px;
  text-align: center;
  position: relative;
}
.affiliate_mid_item_desc {
  margin-top: 20px;
}
.affiliate_mid_item_arrow {
  position: absolute;
  right: -90px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
}
.affiliate_mid_item_arrow img {
  width: 100%;
}
.subscript_faq.affiliate_faq h3 {
  margin-bottom: 44px;
}

@media screen and (min-width:1024px) and (max-width:1440px) {
  .select_language .language {
    white-space: initial;
  }
  .subscript_section_content {
    flex-wrap: wrap;
  }
  .subscript_section {
    width: calc(100% / 2);
  }
  .subscript_section_price_it_p {
    zoom: 0.9;
  }
}
@media screen and (min-width:768px) and (max-width:1024px) {
  .select_language .language {
    white-space: initial;
  }
  .subscript_section_content {
    flex-wrap: wrap;
    margin-top: 0;
  }
  .subscript_section {
    width: 100%;
  }
  .subscript_section_price_it_p {
    font-size: 16px;
  }
  .subscript_faq_content {
    flex-direction: column;
  }
  .subscript_faq_list {
    width: 100%;
    margin-right: 0;
    margin-top: 16px;
  }
}
@media screen and (max-width:768px) {
  .affiliate_top_right_button {
    flex-wrap: wrap;
  }
  .affiliate_top_right_button button {
    margin-bottom: 16px;
    margin-right: 8px;
  }
  .affiliate_top_right_button div {
    margin-left: 0;
  }
  .shopify_products_head {
    flex-direction: column;
  }
  .seo_ant_top_btn .itemText {
    font-size: 12px;
  }
  .seo_ant_top_btn {
    margin-top: 10px;
  }
  .subscript_section_content {
    flex-wrap: wrap;
    margin-top: 0;
  }
  .subscript_section {
    width: 100%;
    padding: 24px 52px 16px;
  }
  .subscript_section:nth-last-of-type(1) {
    margin-top: 36px;
  }
  .subscript_section_price_it_p {
    zoom: 0.9;
  }
  .subscript_faq h3 {
    padding-left: 16px;
  }
  .subscript_faq_content {
    flex-direction: column;
  }
  .subscript_faq_list {
    width: 100%;
    margin-right: 0;
    margin-top: 16px;
  }
}
.affiliate_faq {
  margin-top: 24px;
}
.affiliate_faq .Polaris-Box{
 background-color: #fff !important;
}
@media screen and (max-width:600px) {
  .affiliate_top {
    flex-direction: column;
  }
  .affiliate_top_left {
    width: 100%;
  }
  .affiliate_mid_detail {
    flex-direction: column;
  }
  .affiliate_mid_item {
    width: 100%;
    margin-top: 16px;
  }
  .affiliate_mid {
    padding: 0 16px 16px;
  }
  .affiliate_mid_item_arrow {
    display: none;
  }
  .affiliate_top_right_button a{
    word-break: break-all;
  }
  .affiliate_faq {
    margin-top: 24px;
  }
  .affiliate_faq {
    padding: 0 24px 24px 24px;
  }
  .subscript_faq.affiliate_faq h3 {
    margin-bottom: 16px;
  }
  .subscript_faq_item_title p {
    zoom: 0.85;
  }

  .plan_upgrade_head {
    font-size: 20px;
  }
  .plan_upgrade_conetent ul li {
    font-size: 12px;
    line-height: 1.5;
    height: auto;
  }
  .select_store .store, .select_language .language {
    white-space: initial;
  }
  .make_item_text .Polaris-TopBar-Menu__Activator {
    margin-right: auto;
  }
  .make_item_text .Polaris-TopBar__Contents {
    width: 85%;
  }
}


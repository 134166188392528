/* custom packaging 列表样式 */
.Product_Resource_Wrapper {
    padding: 20px 0;
    position: relative;
    /* background-color: var(--p-background,#f4f6f8); */
}

.Product_Resource_Wrapper .Border_Mask.Left {
    /* left: -3px; */
}
.Product_Resource_Wrapper .Border_Mask.Right {
    /* right: -3px; */
}
.Product_Resource_Wrapper .Border_Mask.Bottom {
    top: initial;
    bottom: -3px;
    width: 100%;
    height: 3px;
}

.Product_Resource_Wrapper .Border_Mask {
    position: absolute;
    content: "";
    height: 100%;
    display: none;
    width: 3px;
    top: 0;
    background-color: var(--p-background,#f4f6f8);
}

.Product_Resource_Wrapper .Product_Resource_Container {
    position: relative;
    display: grid;
    column-gap: 10px;
    row-gap: 20px;
    grid-template-columns: repeat(auto-fit,246px);
    justify-content: space-between;
    z-index: 2;
}

.Product_Resource_Container.small_spacing {
    justify-content: start;
}

@media (max-width: 550px){
    .Product_Resource_Wrapper .Product_Resource_Container {
        justify-content: center;
        grid-template-columns: 100%;
    }
}

.Product_Card_Tkabk {
    height: 410px;
}

.Product_Card_Tkabk.custom_packaging{
    height: 360px;
}

.Product_Card_Tkabk .Polaris-Card {
    height: 100%;
    overflow: hidden;
    /* cursor: pointer; */
    transition: background-color .3s;
}

.Product_Card_Tkabk:hover .Polaris-Card {
    background-color: #f4f5fa;
}

.Product_Image_Wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #dfe3e8;
    max-height: 100vw;
    border-radius: 5px;
}

.Product_Card_Tkabk .Product_Cover_Tkabk {
    margin: 0 auto;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.Product_Card_Tkabk .Product_Cover_Tkabk .discount{
    z-index: 5;
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    background-color: #e62d04;
    padding: 5px 6px;
}

.Product_Card_Tkabk .Product_Image_Wrapper {
    border: none;
}

.Product_Image_Wrapper .lazyload-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: inherit;
    height: inherit;
    max-width: 100%;
    max-height: 100%;
}

.Product_Image_Wrapper .lazyload-wrapper img {
    display: block;
}

.Product_Image_Wrapper .lazyload-wrapper>* {
    width: auto;
    height: inherit;
    max-width: 100%;
    max-height: 100%;
}

.Product_Card_Tkabk .Product_Title_tkabk {
    word-break: keep-all;
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 40px;
}

.Product_Title_tkabk .Polaris-Link {
    text-decoration: none;
}

.Product_Card_Tkabk .Product_Title_tkabk a, .Product_Card_Tkabk .Product_Title_tkabk a:hover {
    color: var(--p-text,#212b36);
}

.Product_Card_Tkabk .Product_Price_Value{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.Product_Card_Tkabk .Product_Discount_Row_tkabk {
    height: 26px;
}

.Product_Card_Tkabk .Product_Title_Money{
    font-size: 20px;
    font-weight: 500;
    color: #e62d04;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Product_Card_Tkabk .Product_Compare_at_price_tkabk {
    color: #6d7175;
    text-decoration: line-through;
    margin-top: 5px;
}
.Product_Resource_Wrapper .Product_Resource_Container {
    row-gap: 0;
}

.Product_Card_Tkabk .Product_Rating_tkabk {
    vertical-align: middle;
    display: flex;
}

.Product_Card_Tkabk .Product_Orders_tkabk {
    vertical-align: middle;
}

.Product_Card_Tkabk .Product_Rating_tkabk svg {
    fill: #ffca28;
    width: 16px;
    vertical-align: middle;
}

.Product_Card_Tkabk .Product_Orders_tkabk svg {
    fill: #686f77;
    width: 16px;
    vertical-align: middle;
}

.Product_Card_Tkabk .Polaris-Stack--spacingExtraTight > .Polaris-Stack__Item {
    margin-bottom: 0.8rem;
}




.hello{
    margin: 4rem auto;
    text-align: center;
}
.bigspace{
    padding: 2rem 0 4rem 0;
}

.space{
    padding: 1rem 0;
    width: 100%;
}
.buttondesc{
    padding: 0 0 1.5rem 0;
}

.re-install-banner .Polaris-Heading {
    font-size: 1.8rem;
}
.re-install-banner .banner-content{
    font-size: 1.5rem;
}

.content-p{
    /* text-indent : 2rem; */
    font-size : 1.6rem
}
.content-p1{
    /* text-indent : 2rem; */
    font-size : 1.6rem;
    padding-bottom: 1rem;
}

.new-features-title .Polaris-Subheading{
    text-transform: unset;
    font-size: 1.6rem;
}

.new-features{
    padding: 2rem 0;
}
.new-features .Polaris-List{
    padding: 1rem 1rem;
}

.new-features-title .Polaris-List__Item{
    text-transform: unset;
    font-size: 1.5rem;
}

/* Page Not Found 404 */
.page-not-found{
    padding-top: 4rem;
    margin-top: 8rem;
}
.not-found-title{
    font-size: 2.6rem;
    line-height: 3.2rem;
    font-weight: 400;
}
.not-found-msg{
    margin: 2rem 0;
    font-size: 2rem;
    line-height: 2.8rem;
    color: #637381;
}
.not-found-img{
    margin-top: 2rem;
    margin-left: -70%;
    width: 170%;
}


/* Page Error 500 */
.page-error{
    padding-top: 4rem;
    margin-top: 8rem;
}
.error-img {
    margin-top: 8rem;
    margin-left: -20%;
    width: 120%;
}

@media (max-width: 769px){
    .not-img{
        margin-top: 2rem;
    }
    .not-found-img {
        margin-top: 5rem;
        width: 150%;
    }

    .page-error{
        padding-top: 1rem;
        margin-top: 6rem;
    }

    .error-img{
        margin-top: 4rem;
        margin-left: 0;
        width: 100%;
    }
}

@media (max-width: 660px){
    .not-found-img {
        margin-top: 0;
        margin-left: -50%;
        width: 150%;
    }
}

@media (max-width: 360px){
    .not-img{
        margin-top: 0;
    }
    .not-found-img {
        margin-top: 0;
        width: 100%;
    }
    .page-error{
        padding-top: 0;
        margin-top: 0;
    }

    .error-img{
        margin-top: 0;
        margin-left: 0;
        width: 100%;
    }
}



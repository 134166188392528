/* 新UI修改样式 */
#homePage .Polaris-Layout__Section--oneThird {
    max-width: 350px;
}

.iconLeftClass {
    margin-top: 10px;
    float: left;
    width: 35px;
}

.iconLeftClass_order {
    float: left;
}

.customBanner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.customBannerContent {
    flex: 1;
}
.lh_min .Polaris-Box {
    line-height: 0.7;
}
.iconContentClass_Product {
    float: left;
    width: 80%;
    align-items: center;
    margin-left: 6px;
}

.iconContentClass_order {
    float: left;
    width: 80%;
    align-items: center;
    margin-left: 6px;
}

.iconRightClass_order {
    float: right;
}

.imageGifwidth {
    width: 100%;
}

.imageGifwidth img {
    width: 100%;
}

.Polaris-Link svg {
    width: 20px;
    height: 20px;
}

.LayoutLinkColor .Polaris-Link {
    color: #212b36 !important;
    width: 100%;
    background-color: transparent;
}

.Product_sale_div_padding .Polaris-Card__SectionHeader {
    padding-bottom: 1.4rem;
}

.Stack__m2X3r {
    display: flex;
    align-items: center;
    font-weight: 500;
}

.Tabs_wrapper__3rvS1 {
    display: flex;
    border-top: 1px solid #dfe3e8;
}

.Tabs_tab-list__1G1ct {
    min-width: 20rem;
    display: flex;
    flex-direction: column;
}

.Tabs_tab__28Mkm {
    background-color: #f9fafb;
    cursor: pointer;
}

.Tab_tab__m2X3r {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 12px;
    font-weight: 500;
}

.Tabs_tab-panels__3JRil {
    display: flex;
}

.TabPanel_wrapper__1nfYL {
    display: flex;
    padding: 20px;
}

.Polaris-Stack .Polaris-Stack--vertical {
    flex-direction: column;
    margin-left: 0;
}

.Tabs_active-tab__3kw-b {
    background-color: #fff;
    border-left: 2px solid #5c6ac4;
    border-top: 1px solid #dfe3e8;
    border-bottom: 1px solid #dfe3e8;
    border-right: 0;
}
.affiliate_banner .Polaris-Banner__Ribbon {
    display: none;
}
.affiliate_banner p {
    font-size: 16px;
    margin: 5px 0;
}
@media screen and (max-width: 1100px) {
    .iconContentClass_Product {
        float: left;
        width: 65%;
        align-items: center;
        margin-left: 6px;
    }
}

@media screen and (max-width: 730px) {
    .Tabs_wrapper__3rvS1 {
        flex-direction: column;
    }

    .Tabs_tab-list__1G1ct {
        flex-direction: row;
        justify-content: space-evenly;
    }

    .Tabs_tab__28Mkm {
        width: 100%;
    }

    .Tab_tab__m2X3r {
        margin-left: 0;
        justify-content: center;
        font-size: 10px;
        flex-direction: column;
        padding: 14px 0;
    }

    .Tabs_active-tab__3kw-b {
        background-color: #fff;
        border-top: 2px solid #5c6ac4;
        border-left: 1px solid #dfe3e8;
        border-right: 1px solid #dfe3e8;
        border-bottom: 0;
    }

    .TabPanel_wrapper__1nfYL {
        display: flex;
        padding: 20px 0;
    }

    .Tab_tab__span_dis {
        display: none;
    }

    .iconContentClass_Product {
        float: left;
        width: 65%;
        align-items: center;
        margin-left: 6px;
    }
}

.Tab_tab__m2X3r .Polaris-Banner--withinContentContainer.Polaris-Banner--statusSuccess,
.Tab_tab__m2X3r .Polaris-Banner--withinContentContainer {
    background-color: unset !important;
    padding: unset !important;
    box-shadow: unset;
}

.Dashboard_Title {
    padding-right: 2rem;
    margin-bottom: 2rem;
}

.Dashboard_Promote {
    margin-top: 2rem;
    text-align: center;
    background-color: rgb(233, 56, 77);
    border-radius: 10px;
    line-height: 0;
}

.Dashboard_Promote img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}
.personal-agent__container .personal-agent__top{
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-top: 16px;
}
.personal-agent__container .personal-agent__top .personal-agent__name{
   font-weight: 600;
   margin-left: 5px;
}
.start-there-dropshipping-card .Polaris-Card__Section{
    padding-bottom: 7px;
    border-bottom: 0px;
}

.start-there-dropshipping-card .Polaris-Card{
    box-shadow: unset;
}

.productsOrdersContainer{
    display: flex;
}
.productsOrdersContainer .productsOrdersItem{
    display: flex;
    cursor: pointer;
}
.productsOrdersContainer .productsOrdersItem .productsOrdersItemLeft .text{
    font-weight: 500;
}
.productsOrdersContainer .productsOrdersItem .productsOrdersItemLeft{
    margin-left: 10px;
}
.productsOrdersContainer .productsOrdersItem .triangle{
    position: absolute;
    bottom: -39px;
    z-index: 10;
    user-select: none;
    transform: translateX(200%)
}
.personal-agent__container .mt15{
    margin-top: 15px
}
.personal-agent__container .personal-agent__bottom .pf5{
    padding-left: 5px;
    cursor: pointer;
}
.personal-agent__bottom .pf10 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
    max-width: 125px;
}
.sale_manager_content_right_bottom h6 {
    flex: 1;
}

.personal-agent__container .personal-agent__bottom .pf10{
    padding-left: 10px
}
.help-warp{
    padding-left: 0;
}

.help-warp .help-warp__item{
  height: 40px;
  background: #F6F6F7;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-weight: 500;
  padding: 0 15px;
}

.productsOrdersContent{
    margin-top: 5px;
}
.productsOrdersContent .itemProductsOrdersContent{
    display: flex;
}

.productsOrdersContent .itemProductsOrdersContent .itemContentBox{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    width: 25%;
    padding: 0 2%;
    min-height: 150px;
    border-right: 2px solid #E9E9EE;
}

.productsOrdersContent .itemProductsOrdersContent .itemContentBox>h2,
.productsOrdersContent .itemProductsOrdersContent .itemContentBox>div{
    width: 100%;
}

.itemContentBoxButton{
    text-align: center;
    align-self: end;
}
.itemContentBoxButton button {
    width: 90%;
}
.productsOrdersContainer .productsOrdersItem{
    width: 33.333333%;
    padding: 15px;
}
.productsOrdersContainer .productsOrdersItem .productsOrdersItemLeft .productsOrdersItemContent{
    min-height: 45px;
    color:  #A9A9AD;
    font-weight: 400;
}
.start-there-dropshipping-card{
    margin-top: 15px;
}
.dropshipping-card .dropshipping-container{
   display: flex;
   gap: 2rem;
}

.dropshipping-card .Polaris-Card{
    margin-bottom: 30px;
    margin-top: 50px;
}

.dropshipping-card .dropshipping-container__right{
    display: flex;
    flex-wrap: wrap;
    padding: 0 2rem;
}

.dropshipping-card .dropshipping-container__left{
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;
    padding: 0 3rem;
    border-left: 1px solid  #EEEEEE;
}

.dropshipping-card .Polaris-Button{
    border-color: #303030;
    margin: 20px auto;
    width: 80%;
}
.loading{
    height: 300px;
    text-align: center;
    line-height: 230px
}

@media screen and (min-width: 1440px) {
    #homePage .Polaris-Layout__Section--secondary {
        max-width: 350px;
    }
}

@media screen and (max-width: 1440px){
    .productsOrdersContent .itemProductsOrdersContent {
        flex-wrap: wrap;
        row-gap: 2rem;
    }
    .productsOrdersContent .itemProductsOrdersContent .itemContentBox{
        display: flex;
        gap: 2rem;
        flex-wrap: wrap;
        width: 50%;
        padding: 0 2%;
        min-height: 150px;
    }
    .productsOrdersContent .itemProductsOrdersContent .itemContentBox:nth-child(2n){
        border-right: none;
    }
}

@media screen and (max-width: 1180px){
    .productsOrdersContainer .productsOrdersItem {
        flex-wrap: wrap;
        justify-content: center;
    }
    .productsOrdersContainer .productsOrdersItem .productsOrdersItemLeft{
        margin-right: 10px;
    }
    .productsOrdersContainer .productsOrdersItem .triangle{
        transform: translateX(50%)
    }
}

@media screen and (max-width: 1083px){
    .productsOrdersContainer .productsOrdersItem {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .productsOrdersContainer .productsOrdersItem .productsOrdersItemLeft{
        margin-right: 0;
    }
    .productsOrdersContainer .productsOrdersItem .triangle{
        transform: translateX(200%)
    }
}
.introjs-skipbutton {
    bottom: -148px !important;
    left: 70px !important;
    top: auto;
    font-weight: 400;
}
.introjs-skipbutton:hover {
    color: #f5520c;
}
.introjs-tooltiptext {
    min-height: 100px;
}
.introjs-top-right-aligned .introjs-skipbutton{
    display: none;
}
.recharts-cartesian-axis-tick{
    padding-top: 16px;
}
.novice_video_title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 24px;
}
.get_start_item {
    /* display: flex;
    min-height: 120px;
    max-height: 120px; */
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 10px;
    overflow: hidden;
}
.get_start_item:hover {
    background-color: #f6f6f6;
}
.get_start_items {
    background-color: #f6f6f6;
}
.get_start_item_text_title{
    flex: 1;
    padding: 16px;
}
.get_start_item_text_title_plan span strong {
    color: #f00;
}
.get_start_item_text_title > span {
    max-width: 450px;
    height: 60px;
    padding-left: 24px;
    display: inline-block;
}

.get_start_item_text_img {
    width: 304px;
    position: relative;
}
.get_start_item_text_img img{
    height: 172px;
    width: 100%;
    position: absolute;
    left: 0;
    top: -32px;
}
.get_start_item_text {
    display: flex;
    font-size: 14px;
    min-height: 140px;
    max-height: 140px;
}
.get_start_item_text_btn {
    margin: 16px 16px 10px;
}
.get_start_item_text_btn span {
    padding: 0;
}
.module_content_list {
    /* min-height: 130px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.module_content_item {
    width: calc(100% / 4 - 16px);
    display: flex;
    border-radius: 10px;
    padding: 16px 16px 16px;
    border: 1px solid #f6f6f7;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    background: #fff;
}
.module_content_item_img {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}
.module_content_item_img span {
    background: #f6f6f7;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    margin-right: 16px;
    justify-content: center;
    width: 45px;
    height: 45px;
    
}
.module_content_item_img span img {
    max-width: 28px;
    max-height: 28px;
}
.module_content_item_dsc {
    font-size: 25px;
    margin: 16px 0;
    display: flex;
    color: #008060;
}
.module_content_item_dsc span {
    margin-left: 16px;
}
.module_content_item_dsc span svg{
    fill: #008060;
}  
.sale_manager_content_item {
    display: flex;
    padding: 16px 0;
    align-items: center;
    border-radius: 10px;
    background-color: #fff;
}
.sale_manager_content_right {
    margin-left: 32px;
    padding: 16px;
}
.sale_manager_content_right_top {
    position: relative;
}
.sale_manager_content_right_top p {
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: pre-wrap; */
    max-width: 700px;
}
.sale_manager_content_right_top span {
    font-size: 36px;
    position: absolute;
    left: -20px;
    top: 0;
}
.sale_manager_content_left {
    width: 150px;
    min-width: 150px;
    line-height: 0;
    margin-left: 24px;
    border-radius: 50%;
    border: 1px solid rgba(227, 227, 227, 1); 
}
.sale_manager_content_left img {
    max-width: 100%;
    border-radius: 50%;
}
.sale_manager_content_right_bottom_dsc {
    display: flex;
    align-items: center;
}
.sale_manager_content_right_bottom_dsc img {
    vertical-align: bottom;
}
.sale_manager_content_right_bottom_dsc .pf5 img {
    vertical-align: initial;
    margin-left: 8px;
}
.sale_manager_content_right_bottom {
    display: flex;
    align-items: center;
    margin-top: 24px;
}
.pf5 {
    cursor: pointer;
}
.get_start_item_p p{
    font-weight: 550;
    /* padding-top: 4px; */
    font-size: 13px;
}
@media screen and (max-width: 640px){
    .Dashboard_Title {
        padding-right: 0;
    }
    .get_start_item_text_img {
        width: 100%;
    }
    .sale_manager_content_left {
        width: 120px;
        min-width: 120px;
        line-height: 0;
        border-radius: 50%;
        margin-top: 16px;
        margin-left: 0;
        border: 1px solid rgba(227, 227, 227, 1); 
    }
    
    .sale_manager_content_left img {
        border-radius: 50%;
    }
    .sale_manager_content_right_bottom {
        flex-direction: column;
        align-items: flex-start;
    }
    .personal-agent__bottom {
        margin:4px  0 !important;
    }
    .get_start_item_text {
        flex-direction: column;
        max-height: 100%;
        font-size: 14px;
    }
    .get_start_item_text_img img{
        width: 100%;
        height: 100%;
        position: relative;
        top: 0;
        vertical-align: bottom;
    }
    /* .get_start_item_text_title span {
        padding-left: 0;
    } */
    .module_content_list {
        flex-direction: column;
    }
    .module_content_item {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
        padding: 10px;
    }
    .sale_manager_content_item {
        flex-direction: column;
    }
    .sale_manager_content_right_bottom_dsc {
        flex-direction: column;
        align-items: flex-start;
    }
    .sale_manager_content_right {
        width: 100%;
        margin: 0;
        padding: 16px 24px;
    }
    .novice_video_title {
        font-size: 18px;
    }
    .novice_video_modal_content iframe {
        width: 100%;
        height: 100%;
    }
    .introjs-bottom-left-aligned {
        left: 0 !important;
    }
    .introjs-top-right-aligned {
        right: 0 !important;
    }
    .fulfilled_orders_content_line .recharts-default-legend {
        /* text-align: left !important; */
    }
    
    /* .fulfilled_orders_content_line_title {
        width: calc(100% / 2) !important ;
        align-items: center;
        margin-bottom: 10px;
        justify-content: center;
    } */
    .fulfilled_orders_content_line_export {
        margin-top: 10px;
        right: 0 !important;
    }
    .productsOrdersContent .itemProductsOrdersContent .itemContentBox{
        display: flex;
        gap: 2rem;
        flex-wrap: wrap;
        width: 100%;
        padding: 0 2%;
        min-height: 150px;
        border-right: none;
    }
    .productsOrdersContainer .productsOrdersItem .productsOrdersItemLeft{
        margin-right: 10px;
    }
    .productsOrdersContainer .productsOrdersItem {
        flex-wrap: wrap;
        justify-content: center;
        padding: 15px 0;
    }
    .productsOrdersContainer .productsOrdersItem .triangle{
        transform: translateX(50%)
    }
    .dropshipping-card .dropshipping-container {
        flex-wrap: wrap;
    }
    .dropshipping-card .dropshipping-container__right{
        display: flex;
        flex-wrap: wrap;
        padding: 0 2rem;
    }
    .dropshipping-card .dropshipping-container__left{
        display: flex;
        flex-wrap: wrap;
        margin-left: 0;
        padding: 0 2rem;
        border-left: none;
    }
    .fulfilled_orders_content_top {
        margin: 10px 0 16px 10px !important;
    }
}
@media screen and (max-width: 480px){
    .Dashboard_Title .Polaris-Header-Title span {
        margin-left: 38px;
    }
}

.fulfilled_orders_content_top {
    display: flex;
    align-items: center;
    margin: 10px 0 16px;
}
.fulfilled_orders_content_top_tips {
    margin: 0 16px;
}
.fulfilled_orders_content_line_item {
    display: flex;
    align-items: center;
    justify-content: center;
}
.fulfilled_orders_content_line_title {
    width: calc(800px / 4);
    display: flex;
    flex-direction: column;
}
.fulfilled_orders_content_select .Polaris-Select {
    width: 130px;
}
.fulfilled_orders_content_line_export span {
    cursor: pointer;
}
.fulfilled_orders_content_line_export_span {
    /* display: flex;
    align-items: center;
    border: 1px solid #999;
    padding: 3px 6px;
    border-radius: 5px;
    font-size: 12px;
    color: #999; */
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
}
.fulfilled_orders_content_line_export svg {
    fill: #8a8a8a;
}
.fulfilled_orders_content_line_export_span img{
    width: 100%;
    height: 100%;
    margin-right: 5px;
}
.fulfilled_orders_content_top_title {
    font-size: 18px;
}
.subscript_section_it_tip svg {
    fill: #999;
}
.product_tip_num {
    margin-left: 5px;
}
.product_tip_nums {
    color: rgb(75, 167, 227);
}
.fulfilled_orders_content_line_title_unful {
    display: flex;
    align-items: center;
    color:  #303030;
    cursor: pointer;
}
.fulfilled_orders_content_line_title_unful img {
    margin-left: 10px;
}
.fulfilled_orders_content_line_title span {
    font-size: 16px;
}
.product_tip_num_less {
    color: #f00;
}
.product-tooltips {
    background-color: #00000040;
    color: #000000;
    width: 120px;
}
.fulfilled_orders_content_lines {
    position: relative;
    /* padding-bottom: 20px; */
}
.fulfilled_orders_content_line_export {
    min-width: 0;
    min-height: 20px;
    position: relative;
}
.introjs-helperLayer {
    box-shadow: rgba(33, 33, 33, 0.8) 0px 0px 0px 0px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
    max-width: 245px !important;
}
.introjs-tooltip {
    min-width: 300px;
}
.introjs-tooltip-title {
    margin-top: 16px ;
}
.introjs-button {
    text-shadow: none;
}
.introjs-tooltipbuttons a:nth-child(1) {
    display: none;
}
.introjs-tooltipbuttons{
    padding: 10px 50px;
    border-top: 0;
}
.introjs-skipbutton {
    font-size: 14px;
    color: #f5520c;
}
.introjs-bottom-left-aligned {
    left: 200px !important;
}
.introjs-top-right-aligned {
    right: 16px !important;
}
@media screen and (max-width: 768px) {
    .agent_content_star_box {
     flex-wrap: wrap;
    }
}

.all_packing_list_redeem {
    margin: 0 60px;
}
.all_packing_list_redeem .all_packing_list_item_price {
    font-size: 18px;
}
.all_packing_list_redeem .all_packing_list_item_price img {
    width: 20px;
    vertical-align: bottom;
}
.all_packing_list_item.all_packing_list_item_rdeem {
    width: calc(100% / 5 - 16px);
    /* width: 210px; */
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: #fff;
    cursor: pointer;
    position: relative;
    /* box-shadow: 0rem 0.125rem 0.25rem rgba(31,33,36,.1), 0rem 0.0625rem 0.375rem rgba(31,33,36,.05); */
}
.all_packing_list_item_rdeem:nth-child(5n) {
    margin-right: 0;
}
.all_packing_list_item_rdeem .all_packing_list_item_img {
    height: 255px;
    box-shadow:none;
}
.all_packing_list_item_rdeem .all_packing_list_item_img img {
    width: 100%;
    height: 100%;
}
.collapsible_box_item_bottom_clo_button {
    display: flex;
    align-items: center;
}
.order_item_btn_redeem {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 20%;
}
@media screen and (min-width: 1600px) and (max-width: 1800px) {
   
}
@media screen and (min-width: 1367px) and (max-width: 1599px) { 
    
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
    .all_packing_list_item_rdeem .all_packing_list_item_img {
        height: 220px;
    }
    .all_packing_list_item.all_packing_list_item_rdeem {
        width: calc(100% / 4 - 16px);
    }
    .all_packing_list_item.all_packing_list_item_rdeem:nth-child(4n) {
        margin-right: 0;
    }
    .all_packing_list_item_rdeem:nth-child(5n) {
        margin-right: 20px;
    }
    .order_item_btn_redeem {
        width: 25%;
    }
    .order_item_shipping.order_item_shipping_redeem {
        width: 80% !important;
    }
    
}
@media screen and (min-width: 960px) and (max-width: 1023px) {
    .all_packing_list_item.all_packing_list_item_rdeem{
        width: calc(100% / 3 - 14px);
    }
    .all_packing_list_item.all_packing_list_item_rdeem:nth-child(3n) {
        margin-right: 0;
    }
    .all_packing_list_item_rdeem:nth-child(5n) {
        margin-right: 20px;
    }
    .all_packing_list_item_rdeem .all_packing_list_item_img {
        height: 220px;
    }
    .order_item_btn_redeem {
        width: 40% !important;
    }
}
@media screen and (min-width: 768px) and (max-width: 960px) {
    .all_packing_list_item.all_packing_list_item_rdeem {
        width: calc(100% / 2 - 11px);
    }
    .all_packing_list_item.all_packing_list_item_rdeem:nth-child(2n) {
        margin-right: 0;
    }
    .all_packing_list_item_rdeem:nth-child(5n) {
        margin-right: 20px;
    }
    .order_item_btn_redeem {
        width: 60%;
    }
}
@media screen and (min-width: 500px) and (max-width: 767px) {
    .all_packing_list_item.all_packing_list_item_rdeem {
        width: calc(100% / 2 - 11px);
    }
    .all_packing_list_item.all_packing_list_item_rdeem:nth-child(2n) {
        margin-right: 0;
    }
    .all_packing_list_item_rdeem:nth-child(5n) {
        margin-right: 20px;
    }
    .order_item_btn_redeem {
        width: 50%;
    }
    .all_packing_list_item_rdeem .all_packing_list_item_img {
        height: 220px;
    }
    .all_packing_list_redeem {
        margin: 0 ;
    }
}

@media screen and (max-width: 499px) {
    .all_packing_list_redeem {
        margin: 0 ;
    }
    .all_packing_list_item_rdeem .all_packing_list_item_img{
        height: 160px;
    }
    .all_packing_list_item_rdeem .all_packing_list_item_img img {
        width: auto !important;
    }
    .all_packing_list_item.all_packing_list_item_rdeem {
        width: 85%;
        margin-right: 0;
    }
    .all_packing_list.all_packing_list_redeem {
        justify-content: center;
    }
    .order_item_btn_redeem {
        width: 100%;
    }
    .order_item_btn_redeem .order_item_customer {
        width: 50% !important;
        margin: 0 !important;
    }
    .order_item_btn_redeem .order_item_customer .order_customer {
        margin: 0;
    }
    .order_item_shipping_redeem {
        flex: 1;
    }
    .order_item_shipping_redeem button {
        width: 100%;
    }
}